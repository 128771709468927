import i18n from "i18next";
import { initReactI18next } from "react-i18next";

export default i18n
  .use(initReactI18next)
  .init({
    lng: "sv",
    debug: false, // process.env.NODE_ENV === "development",
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
  });