import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { isFulfilled } from "@reduxjs/toolkit";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CloseButton } from "../../../Components/utils";
import { useStyles } from "../../../styles/styles";
import { updateImage, uploadImage } from "../../app";

function ImageUpload({ open, onClose }) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const userOrg = useSelector((state) => state.app.org);
  const dispatch = useDispatch();
  const [image, setImage] = useState(null);
  const [originalFileSize, setOriginalFileSize] = useState(null);
  const imageSizeMb = (imageFile) =>
    Number(((imageFile.length - 814) / 1.37) * 10 ** -6).toFixed(2);

  async function handleChange(event) {
    const files = event.target.files;
    if (files && files.length > 0) {
      let file = files[0];
      const reader = new FileReader();
      reader.onload = function (event) {
        file = event.target.result;
        setOriginalFileSize(imageSizeMb(file));
        setImage({ file, uploaded: false});
      };
      await reader.readAsDataURL(file);
    }
  }

  async function onSubmit() {
    const data = new FormData();
    const blob = await fetch(image.file).then((res) => res.blob());
    data.append("file", blob);
    dispatch(uploadImage({ data })).then((action) => {
      if (isFulfilled(action)) {
        dispatch(
          updateImage({
            id: action.payload.image.id,
            data: {
              width: image.width,
              height: image.height,
              org: userOrg?.id,
            },
          })
        ).then((action) => {
          if (isFulfilled(action)) {
            enqueueSnackbar(
              t("imageUpload.create_snack_success", {
                defaultValue: "Bilduppladdningen lyckades",
              }),
              { variant: "success" }
            );
            onClose();
          } else {
            enqueueSnackbar(
              t("imageUpload.create_snack_fail", {
                defaultValue: "Bilduppladdningen misslyckades",
              }),
              { variant: "error" }
            );
          }
        });
      }
    });
  }
  const classes = useStyles();
  return (
    open && (
      <Card id="image-upload" className={classes.card}>
        <CardHeader title="Bilduppladdning" action={CloseButton(onClose)} />
        <CardContent sx={{ maxWidth: 500 }}>
          {(!image || image.uploaded) && (
            <Typography variant="body1">För att skapa en ny hamnvy </Typography>
          )}
          {image && !image.uploaded && (
            <>
              <Typography variant="h6">Förhandsgranskning</Typography>
              <Box sx={{ width: "100%" }}>
                <img
                  className={classes.image}
                  src={image.file}
                  draggable={false}
                  alt="Förhandsgranskning"
                />
              </Box>
              <Typography variant="body1">
                Filstorlek: {originalFileSize} MB
              </Typography>
            </>
          )}
        </CardContent>

        <CardActions>
          <input
            id="contained-button-file"
            type="file"
            accept="image/*"
            hidden
            onChange={handleChange}
          />
          <label htmlFor="contained-button-file">
            <Button
              variant={image ? "text" : "contained"}
              color="primary"
              component="span"
            >
              Välj en {image && "ny"} bild
            </Button>
          </label>
          <Button
            variant={
              image && !image.uploaded ? "contained" : "text"
            }
            color={image ? "primary" : "info"}
            type="submit"
            onClick={onSubmit}
            disabled={!image || image.uploaded}
          >
            Ladda upp
          </Button>
        </CardActions>
      </Card>
    )
  );
}

export { ImageUpload };
