import { AssignmentCompact, BasRow } from "@hamnvy/shared";
import { Add, Contacts, Edit, Email, Map, Phone } from "@mui/icons-material";
import { Box, Button, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { DialogWithCloseButton } from "../../Components/Dialog";
import { AddButton, EditButton } from "../../Components/utils";
import SlimChip from "../../Components/utils/SlimChip";
import { useAppSelector } from "../../hooks";
import theme from "../../styles/theme";
import ComboSpotSelector from "../map/ComboSpotSelector";
import { AssignmentContext } from "./AssignmentProvider";
import ImageWithMarks from "./ImageWithMarks";

interface Props {
  spot: string | undefined;
  editable?: boolean;
}
const AssignmentSpotSummary: FC<Props> = (props) => {
  const { t } = useTranslation();
  const { spot, editable = false } = props;
  const { assignment, updateAssignment } = useContext(AssignmentContext);
  const allMarks: { id: string; spot: string }[] = useAppSelector(s => s.app.marks);
  const basData: BasRow[] = useAppSelector((s) => s.app.import) ?? [];
  const [mapOpen, setMapOpen] = useState<boolean>(false);
  const [addOrChange, setAddOrChange] = useState<boolean>(false);

  const row = spot ? basData.find((x) => x?.spot === spot) : null;

  const handleSpotUpdate = (spot: string | null) => {
    if (assignment) {

      const payload: Partial<AssignmentCompact> = { id: assignment.id };
      if (spot === null) {
        payload.marks = []
      } else {
        const marksWithSpot = allMarks.filter(x => x.spot === spot);
        payload.marks = marksWithSpot.map(x => x.id);
      }
      // @ts-expect-error
      updateAssignment(payload).then(() => {
        setAddOrChange(false)
      }).catch(e => console.log(e))
    }
  }

  return assignment ? (
    <>
      <DialogWithCloseButton
        open={addOrChange}
        onClose={() => setAddOrChange(false)}
        title={spot ? "Ändra plats" : "Välj plats "}
      >
        <Box sx={{ p: 1, display: "flex", flexDirection: "column", gap: 1 }}>
          <ComboSpotSelector onSpotSelect={handleSpotUpdate} />
          {spot && <Button color="error" variant="contained" onClick={() => handleSpotUpdate(null)}>Ta bort koppling till {spot}</Button>}
        </Box>
      </DialogWithCloseButton>
      <DialogWithCloseButton
        open={mapOpen}
        onClose={() => setMapOpen(false)}
        title={"Plats " + spot}
      >
        <Box sx={{ p: 1 }}>
          {spot && <ImageWithMarks
            marks={assignment.marks}
            imageId={assignment.marks[0].image}
          />}
        </Box>
      </DialogWithCloseButton>
      <Paper
        sx={{
          borderRadius: 1,
          backgroundColor: theme.palette.background.default,
          overflow: "hidden"
        }}
      >
        <Box sx={{ p: 1, display: "flex", justifyContent: "space-between", gap: 1 }}>
          <Typography>
            {t("assignment.sidebar.spot_info", {
              defaultValue: "Plats",
            })}
          </Typography>
          <Box sx={{ display: "flex", gap: 0.5 }}>
            {(editable && !spot) && <IconButton
              sx={{ height: "24px", width: "24px" }}
              onClick={() => setAddOrChange(true)}
            >
              <Add />
            </IconButton>}
            {(editable && spot) && <IconButton
              sx={{ height: "24px", width: "24px" }}
              onClick={() => setAddOrChange(true)}
            >
              <Edit fontSize="small" />
            </IconButton>}
            {spot && <SlimChip color={theme.palette.secondary.main} sx={{ fontWeight: 700 }} label={<Box sx={{ display: "flex", gap: 1, alignItems: "center" }}><span>{spot}</span><Map sx={{ fontSize: 16, color: "rgba(255, 255, 255, 0.5)" }} /></Box>} onClick={() => setMapOpen(true)} />}
          </Box>
        </Box>
        {row && <Box
          sx={{
            px: 1,
            py: 0.5,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 1,
            borderTop: "1px solid #ddd",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography variant="caption">
              {row?.name.trim() ||
                t("spot.empty_name", { defaultValue: "Saknas" })}
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Tooltip
              title={
                row?.phone ||
                t("spot.phone_missing", { defaultValue: "Telefon saknas" })
              }
            >
              <Box>
                <IconButton
                  size="small"
                  disabled={!row?.phone}
                  href={"tel:" + (row?.phone || "")}
                >
                  <Phone fontSize="small" />
                </IconButton>
              </Box>
            </Tooltip>
            <Tooltip
              title={
                row?.email ||
                t("spot.email_missing", { defaultValue: "Epost saknas" })
              }
            >
              <Box>
                <IconButton
                  size="small"
                  disabled={!row?.email}
                  href={"mailto:" + (row?.email || "")}
                >
                  <Email fontSize="small" />
                </IconButton>
              </Box>
            </Tooltip>
          </Box>
        </Box>}
      </Paper>
    </>
  ) : null;
};

export default AssignmentSpotSummary;
