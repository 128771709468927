import {
  Check,
  Close,
  Delete,
  Edit,
  SystemUpdateAlt,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  DialogContent,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DialogWithCloseButton } from "../../Components/Dialog";
import { userHasRight } from "../../services/utils/userRights";
import {
  addSpots,
  deleteSpot,
  getBoats,
  getSpots,
  migrateSpotsFromBAS,
  updateSpot,
} from "../app/appApi";
import { BasUploadsTable } from "../bas/BasUploads";
import { SelectBoat } from "../customer/manageBoats";
import { StickyHeadTable } from "../data/Tables/Tables";
import Header from "../main/Header";
import PageHeader from "../main/PageHeader";

const spotFields = [
  { field: "width", title: "Bredd" },
  { field: "length", title: "Längd" },
  { field: "depth", title: "Djup" },
  { field: "Height", title: "Höjd" },
];

function ManageSpots() {
  const dispatch = useDispatch();
  const uploads = useSelector((state) => state.app.uploads);
  const imp = useSelector((state) => state.app.import);
  const spots = useSelector((state) => state.app.spots);
  const boats = useSelector((state) => state.app.boats);
  const customers = useSelector((state) => state.app.customers);
  const [isEditing, setEditing] = useState(null);
  const [isAddingSpots, setAddingSpots] = useState(false);

  const [changes, setChanges] = useState({});

  useEffect(() => {
    dispatch(getSpots());
    dispatch(getBoats());
  }, []); // mount effect

  const editSpot = (spot) => {
    setEditing(isEditing === spot.id ? null : spot.id);
  };

  const stopEditing = () => {
    setEditing(null);
    setChanges({});
  };

  const saveChanges = async () => {
    const payload = { id: isEditing, ...changes };
    const result = await dispatch(updateSpot(payload));
    if (result.meta.requestStatus === "fulfilled") {
      stopEditing();
    }
  };

  const dropSpot = async () => {
    const result = await dispatch(deleteSpot(isEditing));
    if (result.meta.requestStatus === "fulfilled") {
      stopEditing();
    }
  };

  return (
    <>
      <Header>
        <PageHeader title="Platser" />
      </Header>
      <Box sx={{ display: "flex", gap: 1 }}>
        {userHasRight("advancedFunctions") && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setAddingSpots(true)}
          >
            Skapa platser
          </Button>
        )}
      </Box>
      <Box sx={{ px: 3 }}>
        <StickyHeadTable
          stickyHeaderRow={
            <>
              <TableCell>Platsnummer</TableCell>
              <TableCell>Båt</TableCell>
              {spotFields.map(({ field, title }) => (
                <TableCell key={field}>{title}</TableCell>
              ))}
              <TableCell>Anteckning</TableCell>
              <TableCell></TableCell>
            </>
          }
          size="small"
          maxHeight={500}
        >
          {spots
            .slice()
            .sort((a, b) =>
              a.spotNumber.localeCompare(b.spotNumber, undefined, {
                numeric: true,
              })
            )
            .map((spot) => (
              <TableRow key={spot.id}>
                <TableCell>{spot.spotNumber}</TableCell>
                <TableCell>
                  {isEditing === spot.id || !spot.boat ? (
                    SelectBoat({
                      boats,
                      value: spot?.boat,
                      title: "Tillhörande båt",
                      onSelect: (boat) =>
                        dispatch(
                          updateSpot({
                            id: spot.id,
                            boat: boat ? boat.id : null,
                          })
                        ),
                    })
                  ) : (
                    <span>
                      <b>
                        {spot.boat.brand || ""} {spot.boat.model || ""}
                      </b>{" "}
                      ({spot.boat.owner.name})
                    </span>
                  )}
                </TableCell>
                <TableCell>
                  {isEditing === spot.id ? (
                    <TextField
                      size="small"
                      variant="outlined"
                      type="number"
                      label="Bredd"
                      defaultValue={spot.width}
                      sx={{ width: 100 }}
                      onChange={(e) =>
                        setChanges({ ...changes, width: e.target.value })
                      }
                    />
                  ) : (
                    spot.width
                  )}
                </TableCell>
                <TableCell>
                  {isEditing === spot.id ? (
                    <TextField
                      size="small"
                      variant="outlined"
                      type="number"
                      label="Längd"
                      defaultValue={spot.length}
                      sx={{ width: 100 }}
                      onChange={(e) =>
                        setChanges({ ...changes, length: e.target.value })
                      }
                    />
                  ) : (
                    spot.length
                  )}
                </TableCell>
                <TableCell>
                  {isEditing === spot.id ? (
                    <TextField
                      size="small"
                      variant="outlined"
                      type="number"
                      label="Djup"
                      defaultValue={spot.depth}
                      sx={{ width: 100 }}
                      onChange={(e) =>
                        setChanges({ ...changes, depth: e.target.value })
                      }
                    />
                  ) : (
                    spot.depth
                  )}
                </TableCell>
                <TableCell>
                  {isEditing === spot.id ? (
                    <TextField
                      size="small"
                      variant="outlined"
                      type="number"
                      label="Höjd"
                      defaultValue={spot.height}
                      sx={{ width: 100 }}
                      onChange={(e) =>
                        setChanges({ ...changes, height: e.target.value })
                      }
                    />
                  ) : (
                    spot.height
                  )}
                </TableCell>
                <TableCell>
                  {isEditing === spot.id ? (
                    <TextField
                      size="small"
                      variant="outlined"
                      multiline
                      label="Anteckning"
                      defaultValue={spot.note}
                      sx={{ width: 160 }}
                      onChange={(e) =>
                        setChanges({ ...changes, note: e.target.value })
                      }
                    />
                  ) : (
                    spot.note
                  )}
                </TableCell>
                <TableCell>
                  {isEditing === spot.id ? (
                    <div style={{ display: "flex", gap: 2 }}>
                      <Tooltip title="Spara ändringar">
                        <div>
                          <IconButton
                            disabled={_.isEmpty(changes)}
                            onClick={() => saveChanges()}
                            size="large"
                          >
                            <Check />
                          </IconButton>
                        </div>
                      </Tooltip>
                      <Tooltip title="Avbryt">
                        <IconButton onClick={() => stopEditing()} size="large">
                          <Close />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Ta bort plats">
                        <IconButton onClick={() => dropSpot()} size="large">
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    </div>
                  ) : (
                    <Tooltip title="Ändra">
                      <IconButton onClick={() => editSpot(spot)} size="large">
                        <Edit />
                      </IconButton>
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            ))}
        </StickyHeadTable>
      </Box>

      <DialogWithCloseButton
        title="Skapa nya platser"
        open={isAddingSpots}
        onClose={() => setAddingSpots(false)}
      >
        <DialogContent>
          <GenerateSpots
            onSubmit={(spots) => dispatch(addSpots(spots))}
            onSuccess={() => setAddingSpots(false)}
          />
        </DialogContent>
      </DialogWithCloseButton>
    </>
  );

  function ImportSpots() {
    return (
      <Card>
        <CardHeader title="Importera platser från BAS" />
        <CardContent>
          <p>Val av fil från importhistorik att hämta in platser från</p>
          {BasUploadsTable({
            uploads,
            download: false,
            action: (
              <Tooltip title="Importera tillhörande platser">
                <IconButton
                  onClick={() => dispatch(migrateSpotsFromBAS())}
                  size="large"
                >
                  <SystemUpdateAlt />
                </IconButton>
              </Tooltip>
            ),
          })}
        </CardContent>
      </Card>
    );
  }
}

export function GenerateSpots({ onSubmit = null, onSuccess = null }) {
  const [prefix, setPrefix] = useState("");
  const [x, setX] = useState("");
  const [y, setY] = useState("");
  const inputStyle = { width: 100 };

  const resetForm = () => {
    setPrefix("");
    setX("");
    setY("");
  };

  const genSpots = async () => {
    const spots = [];
    for (let i = Number.parseInt(x); i <= Number.parseInt(y); i++)
      spots.push({ spotNumber: prefix + i.toString() });
    const result = await onSubmit(spots);
    if (result.meta.requestStatus === "fulfilled") {
      resetForm();
      if (onSuccess) onSuccess();
    }
  };

  return (
    <div style={{ display: "grid", gap: 8, alignItems: "center" }}>
      <Typography variant="body2">Generera platser från X till Y</Typography>
      <div
        style={{ display: "grid", gap: 4, gridTemplateColumns: " 2fr 1fr 1fr" }}
      >
        <TextField
          variant="outlined"
          size="small"
          label="Prefix"
          value={prefix}
          onChange={(e) => setPrefix(e.target.value)}
        />
        <TextField
          style={inputStyle}
          variant="outlined"
          size="small"
          type="number"
          label="X"
          value={x}
          onChange={(e) => setX(e.target.value)}
          slotProps={{
            input: { inputProps: { max: y } }
          }}
        />
        <TextField
          style={inputStyle}
          variant="outlined"
          size="small"
          type="number"
          label="Y"
          value={y}
          onChange={(e) => setY(e.target.value)}
          slotProps={{
            input: { inputProps: { min: x } }
          }}
        />
      </div>
      <Tooltip title="Skapa platser från X till och med Y">
        <div style={{ justifySelf: "end" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => genSpots()}
            disabled={!x || !y}
          >
            Generera
          </Button>
        </div>
      </Tooltip>
    </div>
  );
}

export default ManageSpots;
