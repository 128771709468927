import { IUser } from "@hamnvy/shared";
import { Button } from "@mui/material";
import { addHours } from "date-fns/esm";
import { useSnackbar } from "notistack";
import { FC, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import ActionDialog from "../../../Components/utils/ActionDialog";
import { api } from "../../../services/api";

interface Props {
  user: IUser;
}

const ResetUserButton: FC<Props> = (props) => {
  const { user } = props;
  const [dialog, setDialog] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleAction = async () => {
    try {
      setError(false);
      if (!user.email) {
        enqueueSnackbar(
          t("user.has_no_email", {
            defaultValue:
              "Användaren saknar epostaddress. Lägg till en för att kunna återställa lösenordet.",
          }),
          { variant: "error" }
        );
        throw new Error("Email missing");
      }
      await api.post("/reset_password", {
        user: user.id,
        expires: addHours(new Date(), 8),
      });
      setDialog(false);
      enqueueSnackbar(
        t("user.reset_mail_sent", {
          defaultValue: "Återställningsmail skickas till ",
        }) + user?.email,
        { variant: "success" }
      );
    } catch (err) {
      setError(true);
    }
  };

  return (
    <>
      <ActionDialog
        open={dialog}
        onClose={() => setDialog(false)}
        onConfirm={handleAction}
        error={
          error && <>
            {t("user.update_error", "Kunde inte genomföra ändringen")}.
            </>
        }
        title={
          <>
            {t("user.send_reset_email", "Skicka återställningsmail")}?
          </>
        }
      />
      <Button
        color="success"
        variant="outlined"
        onClick={() => setDialog(true)}
      >
        {t("user.send_reset_email_button", "Skicka")}
      </Button>
    </>
  );
};

export default ResetUserButton;
