import { createTheme } from "@mui/material/styles";
const theme = createTheme({
  palette: {
    primary: {
      main: "#004c8c",
    },
    secondary: {
      main: "#1565c0",
    },
  },
  components: {
    MuiCardHeader: {
      styleOverrides: {
        root: {
          paddingBottom: 0,
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          paddingInline: "16px !important",
        }
      }
    }
  },
});

export default theme;
