import { Assignment } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";

export default function CopyButton(callback, options = {}) {
  return (
    <Tooltip title={options?.title || "Kopiera"}>
      <div>
        <IconButton onClick={() => callback()} {...options} title={null} size="large">
          <Assignment />
        </IconButton>
      </div>
    </Tooltip>
  );
}
