import { Mail, Phone } from "@mui/icons-material";
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CloseButton,
  MinusButton,
  SettingsButton,
} from "../../../Components/utils";
import {
  basColumns,
  getColumns,
  stripNumberFromSpaceAndDash,
} from "../../../services/bas";
import {
  boatColumns,
  customerColumns,
  getBoatColumns,
  getCustomerColumns,
  spotColumns,
} from "../../../services/crm";
import safeDisplay from "../../../services/utils/safeDisplay";
import { useStyles } from "../../../styles/styles";
import {
  getSpots,
  postMetric,
  setBoatDetails,
  setDetailsColumns,
  setOwnerDetails,
  setSpotDetails,
} from "../../app";
import CRMDetailsSettings from "./CRMDetails";
import SpotActionsField from "./SpotActionsField";

function PhoneDisplay(rawNum, dispatch) {
  const num = stripNumberFromSpaceAndDash(rawNum);
  return (
    <div>
      <IconButton
        size="small"
        onClick={() =>
          dispatch(postMetric({ type: "call_button", target: num }))
        }
        href={"tel:" + num}
      >
        <Phone />
      </IconButton>
      <span>{num}</span>
    </div>
  );
}
function EmailDisplay(email) {
  return (
    <div>
      <IconButton size="small" href={"mailto:" + email}>
        <Mail />
      </IconButton>
      <span>{email}</span>
    </div>
  );
}

/** Calls appropriate functions for adding interactable icon next to fields named email and phone. */
export function InteractiveFieldDisplay(field, value, dispatch) {
  if (!value) {
    return `${safeDisplay(value)}`;
  }
  switch (field) {
    case "phone":
      return PhoneDisplay(value, dispatch);
    case "email":
      return EmailDisplay(value);
    default:
      return `${safeDisplay(value)}`;
  }
}

export default function DisplayDetails({ onClose, dispatch }) {
  // @ts-ignore
  const basData = useSelector((state) => state.app.import);
  const org = useSelector((state) => state.app.user.org);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const detailsColumns = useSelector((state) => state.app.detailsColumns);
  const allMarks = useSelector((state) => state.app.marks);
  const selectedMarkIds = useSelector((state) => state.app.selectedMarks);
  const [open, setOpen] = useState(true);

  const marks = allMarks.filter((x) => selectedMarkIds.includes(x.id));
  const mark = marks[0];

  useEffect(() => {
    setOpen(true);
  }, [mark]);

  const user = useSelector((state) => state.app.user);
  const spots = useSelector((state) => state.app.spots);

  const boatDetails = useSelector((state) => state.app.boatDetails);
  const ownerDetails = useSelector((state) => state.app.ownerDetails);
  const spotDetails = useSelector((state) => state.app.spotDetails);
  const classes = useStyles();

  useEffect(() => {
    if (org?.defaultBoatDetails.length)
      dispatch(setBoatDetails(org.defaultBoatDetails));
    if (org?.defaultOwnerDetails.length)
      dispatch(setOwnerDetails(org.defaultOwnerDetails));
    if (org?.defaultSpotDetails.length)
      dispatch(setSpotDetails(org.defaultSpotDetails));
  }, []);

  function SpotRows() {
    const dispatch = useDispatch();
    if (org?.usingBAS) {
      const assigned = mark.spot
        ? basData.find((row) => row.spot === mark.spot)
        : null;
      if (assigned) {
        if (assigned.firstname) {
          return (
            <TableContainer>
              <Table>
                <TableBody>
                  {detailsColumns.map((column) => (
                    <TableRow key={"row-for-" + column.field}>
                      <TableCell align="left">{column.title}</TableCell>
                      <TableCell align="right" sx={{ py: 0 }}>
                        {InteractiveFieldDisplay(
                          column.field,
                          assigned[column.field],
                          dispatch
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          );
        }
        const emptySpotDetailColumns = getColumns(
          "area",
          "width",
          "spotComment"
        );
        return (
          <TableContainer>
            <Table>
              <TableBody>
                {emptySpotDetailColumns.map((column) => (
                  <TableRow key={"row-for-" + column.field}>
                    <TableCell align="left">{column.title}</TableCell>
                    <TableCell align="right">
                      {InteractiveFieldDisplay(
                        column.field,
                        assigned[column.field],
                        dispatch
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        );
      }
    }
    const spotId = mark?.spot;
    const spot = spots.find((x) => spotId == x.spotNumber);
    const boat = spot?.boat;
    const owner = boat?.owner;

    if (spot) {
      return (
        <>
          <TableContainer>
            <Table>
              <TableBody>
                {spotDetails
                  .map((x) => spotColumns.find((c) => c.field === x))
                  .map((x) => (
                    <TableRow key={"tr-" + x.field}>
                      <TableCell>{x.title}</TableCell>
                      <TableCell align="right">
                        {spot[x.field] || "-"}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>Båt {!boat && "saknas"}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {boat &&
                  boatDetails.map((field) => (
                    <TableRow key={"tr-" + field}>
                      <TableCell>{getBoatColumns(field)[0].title}</TableCell>
                      <TableCell align="right">{boat[field] || "-"}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>
                    Innehavare {!owner && "saknas"}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {owner &&
                  ownerDetails.map((field) => (
                    <TableRow key={"tr-" + field}>
                      <TableCell>
                        {getCustomerColumns(field)[0].title}
                      </TableCell>
                      <TableCell align="right">
                        {InteractiveFieldDisplay(field, owner[field])}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      );
    }

    return org.hamnvyCRM ? (
      <Typography variant="body1">
        Platsnumret kunde inte hittas bland platser.
      </Typography>
    ) : null;
  }

  function ListColumn({ title, field }) {
    return (
      <div style={{ display: "flex", gap: 4, alignItems: "center" }}>
        {MinusButton(
          () =>
            dispatch(
              setDetailsColumns(detailsColumns.filter((c) => c.field !== field))
            ),
          { size: "small" }
        )}
        <span>{title}</span>
      </div>
    );
  }

  function DetailsSettings() {
    const BasDetailsSettings = () => (
      <Box sx={{ paddingTop: 2 }}>
        <AddBasDetailsField
          fields={detailsColumns}
          onChange={(event, options) => dispatch(setDetailsColumns(options))}
        />
      </Box>
    );

    return (
      settingsOpen && (
        <Box>
          {org.usingBAS && <BasDetailsSettings />}
          {org.hamnvyCRM && (
            <CRMDetailsSettings
              boatFields={boatDetails}
              ownerFields={ownerDetails}
              spotFields={spotDetails}
              onBoatChange={(fields) => dispatch(setBoatDetails(fields))}
              onOwnerChange={(fields) => dispatch(setOwnerDetails(fields))}
              onSpotChange={(fields) => dispatch(setSpotDetails(fields))}
            />
          )}
        </Box>
      )
    );
  }

  return (
    marks.length > 0 &&
    open && (
      <>
        <Card
          id="details"
          className={classes.followScroll}
          style={{ width: "100%" }}
        >
          <CardHeader
            title={`Detaljer${mark?.spot ? `: ${mark.spot}` : ""}`}
            action={
              <>
                {user?.role?.rights.includes("readwrite") &&
                  SettingsButton(() => setSettingsOpen(!settingsOpen))}
                {CloseButton(() => setOpen(false))}
              </>
            }
          />
          <CardContent>
            <SpotActionsField mark={mark} />
            <SpotRows />
            <DetailsSettings />
          </CardContent>
        </Card>
      </>
    )
  );
}

export const AddBasDetailsField = ({ fields, onChange }) => (
  <Autocomplete
    multiple
    size="small"
    limitTags={3}
    value={fields}
    renderInput={(params) => (
      <TextField {...params} fullWidth label="Visad data" variant="outlined" />
    )}
    options={basColumns}
    getOptionLabel={(option) => option.title}
    onChange={(event, option) => onChange(event, option)}
  />
);

export const AddSpotDetailsField = ({ fields, onChange }) => (
  <Autocomplete
    style={{ width: 175 }}
    renderInput={(params) => (
      <TextField {...params} label="Lägg till platsinfo" variant="outlined" />
    )}
    options={spotColumns}
    getOptionLabel={(option) => option.title}
    isOptionEqualToValue={(option) => fields.includes(option.field)}
    getOptionDisabled={(option) => fields.includes(option.field)}
    onChange={(event, option) => onChange(event, option)}
  />
);

export const AddCustomerDetailsField = ({ fields, onChange }) => (
  <Autocomplete
    style={{ width: 175 }}
    renderInput={(params) => (
      <TextField {...params} label="Lägg till ägarinfo" variant="outlined" />
    )}
    options={customerColumns}
    getOptionLabel={(option) => option.title}
    isOptionEqualToValue={(option) => fields.includes(option.field)}
    getOptionDisabled={(option) => fields.includes(option.field)}
    onChange={(event, option) => onChange(event, option)}
  />
);

export const AddBoatDetailsField = ({ fields, onChange }) => (
  <Autocomplete
    style={{ width: 175 }}
    renderInput={(params) => (
      <TextField {...params} label="Lägg till båtinfo" variant="outlined" />
    )}
    options={boatColumns}
    getOptionLabel={(option) => option.title}
    isOptionEqualToValue={(option) => fields.includes(option.field)}
    getOptionDisabled={(option) => fields.includes(option.field)}
    onChange={(event, option) => onChange(event, option)}
  />
);

// Own CRM only
export function SelectSpot({ onSelect }) {
  const dispatch = useDispatch();
  const spots = useSelector((state) => state.app.spots);

  useEffect(() => {
    if (!spots.length) dispatch(getSpots());
  }, []);

  return (
    <Autocomplete
      style={{ width: 175 }}
      renderInput={(params) => (
        <TextField {...params} label="Välj plats" variant="outlined" />
      )}
      options={spots.map((x) => x.spotNumber)}
      onChange={(event, option) => onSelect(option)}
    />
  );
}
