import { Card, CardContent, CardHeader } from "@mui/material";
import { CloseButton } from "../../Components/utils/index";
import SetBasColumns from "../admin/BasColumns";

function BasSettings({ open = true, onClose = null }) {
  return (
    open && (
      <Card style={{ minWidth: "fit-content" }}>
        <CardHeader
          title="Inställningar"
          action={onClose && CloseButton(onClose)}
        />
        <CardContent>
          <p>
            Välj att visa och ordna de kolumner som ni nyttjar och som är
            relevanta i ert arbete.
          </p>
          <SetBasColumns settingsFor="user" />
        </CardContent>
      </Card>
    )
  );
}

export { BasSettings };
