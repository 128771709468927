import { Box, useMediaQuery, useTheme } from "@mui/material";
import { FC } from "react";
import CircleLogo from "../../Components/CircleLogo";
import LoginForm from "./LoginForm";
import LoginMainContainer from "./LoginMainContainer";
import LoginSideContainer from "./LoginSideContainer";

interface Props {}

const LoginPage: FC<Props> = (props) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        p: 4,
      }}
    >
      <Box
        sx={{
          borderRadius: 2,
          overflow: "hidden",
          display: "grid",
          gridTemplateColumns: mobile ? "1fr" : "3fr 2fr",
          boxShadow: "0 4px 10px rgba(0,0,0,0.2)",
          ...(!mobile && {
            maxWidth: "fit-content",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }),
        }}
      >
        <LoginSideContainer>
          <CircleLogo />
        </LoginSideContainer>
        <LoginMainContainer>
          <Box sx={{ p: 4, display: "grid", justifyContent: "center" }}>
            <LoginForm />
          </Box>
        </LoginMainContainer>
      </Box>
    </Box>
  );
};

export default LoginPage;
