import { dataSlice } from "./dataSlice";
export * from './dataSlice';

export const {
  addDataFilter,
  toggleDataFilter,
  updateDataFilter,
  removeDataFilter,
  addImportFilter,
  toggleImportFilter,
  updateImportFilter,
  removeImportFilter,
  setSearchQuery,
  clearSearchQuery,
  setDisplayOnlyRowsOnView,
  clearFilters,
  loadFilterSet
} = dataSlice.actions;
