import { makeStyles } from "@mui/styles";

const {
  Hidden,
  Drawer,
  useTheme,
  Divider,
  useMediaQuery,
} = require("@mui/material");
const React = require("react");

export const drawerWidth = 56;

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  // necessary for content to be below app bar
  toolbar: { minHeight: "56px" },
  drawerPaper: {
    // width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function ResponsiveDrawer(props) {
  const { window, mobileOpen, setMobileOpen, handleDrawerToggle, contents } =
    props;
  const classes = useStyles();
  const theme = useTheme();
  const permanentDrawer = useMediaQuery(theme.breakpoints.up("xl"));

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      {contents}
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <nav className={classes.drawer} aria-label="admin pages">
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden xlDown implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      {permanentDrawer && (
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          sx={{ "& > div": { zIndex: "unset" } }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      )}
    </nav>
  );
}

export default ResponsiveDrawer;
