import { INotification } from "@hamnvy/shared";
import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import ComponentHeading from "../../Components/ComponentHeading";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getNotificationHistory } from "../admin/adminAPI";
import { NotificationRow } from "./NotificationsContainer";

type Props = {};

const SentNotifications = (props: Props) => {
  const notifs = useAppSelector<INotification[]>((s) => s.admin.notifHistory);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (notifs.length === 0) dispatch(getNotificationHistory());
  }, []);

  return (
    <Box sx={{ display: "grid", gap: 1 }}>
      <ComponentHeading title="Skickade notiser" />
      {notifs.length > 0 ? (
        notifs.map((notif) => (
          <NotificationRow key={notif.id} notif={notif} history />
        ))
      ) : (
        <Typography>Inga skickade notiser kunde hittas.</Typography>
      )}
    </Box>
  );
};

export default SentNotifications;
