import { Add } from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { relativeDate } from "../../../services/utils/dates";
import { getNotes, postNote } from "../../app";

export default function Notes({ spot = null }) {
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const allNotes = useSelector((state) => state.app.notes);
  const notes = _.orderBy(
    allNotes.filter((x) => x.spot === spot),
    "createdAt",
    "desc"
  );

  const makeNote = async () => {
    const result = await dispatch(postNote({ spot, message }));
    if (result.meta.requestStatus === "fulfilled") {
      setMessage("");
    }
  };

  useEffect(() => {
    dispatch(getNotes());
  }, []);

  const useStyles = makeStyles((theme) => ({
    table: {
      overflowY: "auto",
      overflowX: "auto",
      maxHeight: 220,
      "& table": { borderCollapse: "separate" }, // fix transparent border in sticky header row
    },
  }));
  const classes = useStyles();

  return (
    spot && (
      <Card>
        <CardHeader title={"Noteringar: " + spot} />
        <CardContent>
          <div style={{ display: "grid", gap: 8 }}>
            <TableContainer className={classes.table}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Datum</TableCell>
                    <TableCell>Meddelande</TableCell>
                    <TableCell align="right">Avsändare</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {notes.length > 0 ? (
                    notes.map((note) => (
                      <TableRow key={note.id}>
                        <TableCell>{relativeDate(note.createdAt)}</TableCell>
                        <TableCell style={{ overflowWrap: "normal" }}>
                          {note.message}
                        </TableCell>
                        <TableCell align="right">
                          {note.user?.name || note.user.username}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3}>
                        Inga noteringar har gjorts ännu
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TextField
              multiline
              fullWidth
              variant="outlined"
              label="Meddelande"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <Button
              onClick={() => makeNote()}
              disabled={!message}
              startIcon={<Add />}
              color="primary"
              variant="contained"
            >
              Notera
            </Button>
          </div>
        </CardContent>
      </Card>
    )
  );
}
