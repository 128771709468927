import { ExpandMore } from "@mui/icons-material";
import { IconButton } from "@mui/material";

export default function DownButton(callback, options = []) {
  return (
    <IconButton title="Flytta ned" onClick={() => callback()} {...options} size="large">
      <ExpandMore />
    </IconButton>
  );
}
