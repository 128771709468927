import { Autocomplete, Box } from "@mui/material";
import { FC } from "react";
import { useAppSelector } from "../../hooks";
import { autocompleteSearchAllBasPropertiesIgnoreCase, autocompleteSearchAllCRMPropertiesIgnoreCase, commonACProps } from "../main/SimpleOverviewSearch";

interface Props {
  options?: string[];
  onChange?: (spot: string) => void;
}
const SearchSpot: FC<Props> = (props) => {
  const { onChange, options } = props;
  const org = useAppSelector((state) => state.app.org);
  // @ts-expect-error
  if (org?.usingBAS) {
    const basImport: { id: string; spot: string; name: string }[] = useAppSelector((s) => s.app.import);
    const filteredOptions = options ? basImport.filter(x => options.includes(x.spot)) : basImport;

    return <Autocomplete
      {...commonACProps}
      options={filteredOptions}
      value={undefined}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.spot + " " + option.name
      }
      filterOptions={autocompleteSearchAllBasPropertiesIgnoreCase}
      onChange={(e, option) => option ? onChange?.(option.spot) : null}
      size="small"
    />
  }
  // @ts-expect-error
  if (org?.hamnvyCRM) {
    const spots = useAppSelector((s) => s.app.spots);
    const selectedSpots = useAppSelector((s) => s.app.selectedSpots);

    return (
      <Autocomplete
        {...commonACProps}
        options={spots}
        value={
          selectedSpots[0]
            // @ts-expect-error
            ? spots.find((x) => x.spotNumber === selectedSpots[0])
            : null
        }
        getOptionLabel={(option) =>
          typeof option === "string"
            ? option
            // @ts-expect-error
            : option.spotNumber +
            // @ts-expect-error
            (option?.boat ? " " + option?.boat?.owner?.name : "")
        }
        filterOptions={autocompleteSearchAllCRMPropertiesIgnoreCase}
        size="small"
        // @ts-expect-error
        onChange={(e, option) => option ? onChange?.(option.spotNumber) : null}
      />
    );
  }
  return null;
};

export default SearchSpot;
