import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SyntaxHighlighter from "react-syntax-highlighter";
import {
  ConfirmDialog,
  CopyButton,
  DeleteButton,
  EyeButton,
} from "../../Components/utils";
import { deleteEndpoint, getEndpoints, updateEndpoint } from "../app";
import { StickyHeadTable } from "../data/Tables/Tables";
import { CreateEndpoint } from "./CreateEndpoint";

export function PublicEndpoints() {
  const dispatch = useDispatch();
  const [removing, setRemoving] = useState(null);
  const endpoints = useSelector((state) => state.app.endpoints);

  // mount effect (empty dependencies)
  useEffect(() => {
    dispatch(getEndpoints());
  }, []);

  const TableHead = () => (
    <>
      <TableCell>Endpoint URL</TableCell>
      <TableCell>Data</TableCell>
      <TableCell>HTML</TableCell>
      <TableCell>Publicerad</TableCell>
      <TableCell></TableCell>
    </>
  );

  const remove = () => {
    dispatch(deleteEndpoint({ index: removing }));
    setRemoving(null);
  };

  const patch = (index, data) => {
    dispatch(updateEndpoint({ index, data }));
  };

  const classes = {
    code: {
      height: 200,
      overflow: "auto",
    },
  };

  const EndpointRows = () =>
    endpoints &&
    endpoints.length > 0 &&
    endpoints.map((e, index) => (
      <TableRow key={e.id}>
        <TableCell>
          <Button href={e.url} target="_blank">
            {e.name}
          </Button>
        </TableCell>
        <TableCell>
          <SyntaxHighlighter
            wrapLines
            language="json"
            customStyle={classes.code}
          >
            {JSON.stringify(e.data, null, 2)}
          </SyntaxHighlighter>
        </TableCell>
        <TableCell>
          <SyntaxHighlighter wrapLines wrapLongLines customStyle={classes.code}>
            {e?.html || ""}
          </SyntaxHighlighter>
        </TableCell>
        <TableCell>
          <Checkbox
            checked={e?.published}
            onChange={(event) => patch(index, { published: !e.published })}
          />
        </TableCell>
        <TableCell padding="none">
          {EyeButton(() => window.open(e.url + ".html", "_blank"), {
            title: "Visa HTML",
            disabled: !e?.published,
          })}
          {CopyButton(() => navigator.clipboard.writeText(e.embedd), {
            title: "Kopiera inbäddningskod",
          })}
          {DeleteButton(() => setRemoving(index))}
        </TableCell>
      </TableRow>
    ));

  const TitleRow = (
    <TableRow>
      <TableCell colSpan={6}>
        <Typography variant="h5">Publika ändpunkter</Typography>
      </TableCell>
    </TableRow>
  );

  return (
    <>
      <ConfirmDialog
        open={removing}
        setOpen={setRemoving}
        title="Radera endpoint?"
        text={`Går inte att ångra`}
        onConfirm={() => remove()}
      />
      <Card style={{ width: 1200 }}>
        <CardHeader title="Publika ändpunkter" />
        <CardContent style={{ display: "grid", gap: 8, width: "fit-content" }}>
          <StickyHeadTable stickyHeaderRow={<TableHead />} maxHeight={400}>
            <EndpointRows />
          </StickyHeadTable>
          <CreateEndpoint />
        </CardContent>
      </Card>
    </>
  );
}
