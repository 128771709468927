import { Chip, DialogContent, Tooltip } from "@mui/material";
import { FC, ReactNode, useState } from "react";
import { DialogWithCloseButton } from "../../../Components/Dialog";

interface Props {
  spotNumber: string;
  getTooltipLabel: (spotNumber: string) => string;
  dialogContent?: ReactNode;
}

const SpotChip: FC<Props> = (props) => {
  const [open, setOpen] = useState<boolean>(false);
  const { spotNumber, getTooltipLabel, dialogContent } = props;

  const dialogContentProps = dialogContent
    ? {
        onClick: () => setOpen(true),
      }
    : {};

  return (
    <>
      <Tooltip title={getTooltipLabel(spotNumber)}>
        <Chip
          label={spotNumber}
          color="secondary"
          sx={{ userSelect: "none", fontWeight: "bold" }}
          {...dialogContentProps}
        />
      </Tooltip>
      {!!dialogContent && (
        <DialogWithCloseButton
          title={"Plats " + spotNumber}
          open={open}
          onClose={() => setOpen(false)}
        >
          <DialogContent><>{dialogContent}</></DialogContent>
        </DialogWithCloseButton>
      )}
    </>
  );
};

export default SpotChip;
