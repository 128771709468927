/**
 * Get new array where given element is swapped with the one above
 * @param {*} el Element to move down one index
 * @param {*} arr Array to act on
 * @returns new array
 */
export const moveDown = (el, arr) => {
    const i = arr.indexOf(el);
    if (i === -1) throw new RangeError("Element not in given array")
    if (i+2 > arr.length) throw new RangeError("Element at the end of given array")
    return [...arr.slice(0, i), arr[i+1], el, ...arr.slice(i+2)]
}

/**
 * Get new array where given element is swapped with the one below
 * @param {*} el Element to move down one index
 * @param {*} arr Array to act on   
 * @returns new array
 */
export const moveUp = (el, arr) => {
    const i = arr.indexOf(el);
    if (i === -1) throw new RangeError("Element not in given array")
    if (i === 0) throw new RangeError("Element at the start of given array")
    return [...arr.slice(0, i-1), el, arr[i-1], ...arr.slice(i+1)]
}