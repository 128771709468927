import { Badge, Button, Container, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import logo from "../../Images/logo.svg";
import { useStyles } from "../../styles/styles";
import { generateFlipSignTodos, getTodos } from "../app";
import Header from "../main/Header";

export default function GuestManagerStartPage() {
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((s) => s.app.user);
  const flipSigns = useSelector((s) => s.app.todos.flipSigns) || [];

  const handleManualFetchTodos = async () => {
    const res = await dispatch(generateFlipSignTodos());
    if (res.meta.requestStatus === "fulfilled") {
      dispatch(getTodos());
    }
  };

  useEffect(() => {
    handleManualFetchTodos();
  }, []);

  return (
    <>
      <Header />
      <Container className={classes.guessManagerHome}>
        <img src={logo} className={classes.bigLogo} />
        <Badge badgeContent={flipSigns.length} color="error">
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => navigate("/todo")}
          >
            Att göra
          </Button>
        </Badge>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/overview")}
        >
          Översikt båtplatser
        </Button>
        <Button
          style={{ minWidth: "fit-content" }}
          variant="contained"
          color="primary"
          onClick={() => navigate("/payments/check")}
        >
          Betalningskontroll
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/spots/available")}
        >
          Lediga platser
        </Button>
        <Typography style={{ position: "fixed", bottom: 0, paddingBottom: 12 }}>
          Inloggad som: {user?.name ? user?.name.split(" ")[0] : user?.username}
        </Typography>
      </Container>
    </>
  );
}
