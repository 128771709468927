import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { SelectBasColumn } from "../data/DataView/BasColumns";
import { SelectFilterMatchMethod } from "../data/filters";

export default function ViewLogic() {
  return (
    <Card>
      <CardHeader title="Vylogik" />
      <CardContent>
        <Typography variant="h6">Ledig plats</Typography>
        <Typography variant="body1">
          Bedöm plats som ledig om någon av nedanstående rader stämmer in på
          platsen:{" "}
        </Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Datafält</TableCell>
                <TableCell>Matchningstyp</TableCell>
                <TableCell>Sökt värde</TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
        <br></br>
        <Typography variant="body2">
          Lägg till ytterligare ett villkor
        </Typography>
        <div
          style={{
            display: "grid",
            gap: 8,
            gridTemplateColumns: "2fr 1fr 2fr",
          }}
        >
          <SelectBasColumn label="Följande bas kolumn" />
          <SelectFilterMatchMethod
            handleChange={(v) => console.log(v)}
            label="Matchningstyp"
          />
        </div>
      </CardContent>
    </Card>
  );
}
