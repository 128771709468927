import { DialogContent, SxProps, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import { FC, ReactElement, useState } from "react";
import { DialogWithCloseButton } from "../../Components/Dialog";

interface Props {
  children: ReactElement;
  sx?: SxProps;
  title: string;
  tooltip?: string;
}

const PreviewImage: FC<Props> = (props) => {
  const { children, sx, title, tooltip } = props;
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <Box
        onClick={() => setOpen(true)}
        sx={{
          height: 100,
          display: "flex",
          cursor: "pointer",
          minWidth: "fit-content",
          userSelect: "none",
          ...sx,
        }}
      >
        <Tooltip title={tooltip ?? ""}>{children}</Tooltip>
      </Box>
      <DialogWithCloseButton
        open={open}
        onClose={() => setOpen(false)}
        title={title}
        maxWidth="xl"
      >
        <DialogContent
          sx={{
            "& > img": {
              maxWidth: "100%",
            },
          }}
        >
          <>{children}</>
        </DialogContent>
      </DialogWithCloseButton>
    </>
  );
};

export default PreviewImage;
