const {
  amber,
  blue,
  blueGrey,
  brown,
  cyan,
  deepOrange,
  deepPurple,
  green,
  grey,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow,
} = require("@mui/material/colors");

// 2014 material design colors that works with white text on top
const colors = {
  red: [700, 800, 900, "A700"],
  pink: [600, 700, 800, 900, "A700"],
  purple: [400, 500, 600, 700, 800, 900, "A700"],
  deepPurple: [400, 500, 600, 700, 800, 900, "A200", "A400", "A700"],
  indigo: [400, 500, 600, 700, 800, 900, "A400", "A700"],
  blue: [700, 800, 900, "A700"],
  lightBlue: [800, 900],
  cyan: [900],
  teal: [700, 800, 900],
  green: [800, 900],
  lightGreen: [900],
  deepOrange: [900, "A700"],
  brown: [400, 500, 600, 700, 800, 900],
  grey: [600, 700, 800, 900],
  blueGrey: [600, 700, 800, 900],
};

export const getColorsWorkingWithWhiteOnTop = () => {
  const result = [];
  colors.red.forEach((c) => result.push(red[c]));
  colors.pink.forEach((c) => result.push(pink[c]));
  colors.deepPurple.forEach((c) => result.push(deepPurple[c]));
  colors.indigo.forEach((c) => result.push(indigo[c]));
  colors.blue.forEach((c) => result.push(blue[c]));
  colors.lightBlue.forEach((c) => result.push(lightBlue[c]));
  colors.cyan.forEach((c) => result.push(cyan[c]));
  colors.teal.forEach((c) => result.push(teal[c]));
  colors.green.forEach((c) => result.push(green[c]));
  colors.lightGreen.forEach((c) => result.push(lightGreen[c]));
  colors.deepOrange.forEach((c) => result.push(deepOrange[c]));
  colors.brown.forEach((c) => result.push(brown[c]));
  colors.grey.forEach((c) => result.push(grey[c]));
  colors.blueGrey.forEach((c) => result.push(blueGrey[c]));
  return result;
};
