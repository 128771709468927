import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { isFulfilled } from "@reduxjs/toolkit";
import { isEmpty, mapValues, omitBy } from "lodash";
import { useSnackbar } from "notistack";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useAppDispatch } from "../../hooks";
import { isValidEmail } from "../../services/utils/validators";
import { PreviewUser } from "../../types";
import { addUser } from "../app";
import EmailField from "./form_fields/EmailField";
import FirstnameField from "./form_fields/FirstnameField";
import LastnameField from "./form_fields/LastnameField";
import PhoneField from "./form_fields/PhoneField";
import UsernameField from "./form_fields/UsernameField";

interface Props {
  onCancel?: () => void;
  onSuccess: () => void;
}

const UserForm: FC<Props> = (props) => {
  const { onCancel, onSuccess } = props;
  const { t } = useTranslation();
  const methods = useForm<{
    user: PreviewUser;
  }>();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const input: PreviewUser = methods.watch("user");

  const onSubmit = async (user: PreviewUser) => {
    // todo additional validation. Valid email?
    const body = mapValues(omitBy(user, isEmpty), (x: string) => x.trim());
    // @ts-expect-error
    const result = await dispatch(addUser(body));
    if (isFulfilled(result)) {
      enqueueSnackbar(
        t("user.add.snack_success", {
          defaultValue:
            "Användaren '{{user}}' skapades och har fått mail med instruktioner.",
          user: body?.username,
        }),
        { variant: "success" }
      );
      onSuccess();
    } else {
      enqueueSnackbar(
        t("user.add.snack_fail", {
          defaultValue: "Användaren '{{user}}' kunde inte skapas.",
          user: body?.username,
        }),
        { variant: "error" }
      );
    }
  };

  return (<>
    <FormProvider {...methods}>
      <form
        id="add-user-to-org-forms"
        onSubmit={methods.handleSubmit(({ user }) => onSubmit(user))}
      >
        <Box sx={{ display: "grid", gap: 1, pt: 1 }}>
          <FirstnameField />
          <LastnameField />
          <UsernameField />
          <EmailField />
          <PhoneField />
          <Box sx={{ display: "flex", justifyContent: "space-between", pt: 2 }}>
            {onCancel ? (
              <Button onClick={onCancel}>
                {t("userForm.cancel", "Avbryt")}
              </Button>
            ) : (
              <Box />
            )}
            <Button
              variant="contained"
              type="submit"
              disabled={!(input?.username && isValidEmail(input?.email))}
            >
              {t("userForm.save", "Spara")}
            </Button>
          </Box>
        </Box>
      </form>
    </FormProvider>
    </>
  );
};

export default UserForm;
