import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../services/api";

// ORGANISATIONS

export const updateOrganisation = createAsyncThunk(
  "updateOrganisation",
  async ({ id, ...data }, { getState }) => {
    const response = await api.patch(`/organisations/${id}`, data);
    return response.data;
  }
);

export const addOrganisation = createAsyncThunk(
  "addOrganisation",
  async (payload) => {
    const response = await api.post(`/organisations`, payload);
    return response.data;
  }
);

export const deleteOrganisation = createAsyncThunk(
  "deleteOrganisation",
  async (payload) => {
    const response = await api.delete(`/organisations/${payload.id}`);
    return response.data;
  }
);

export const getOrganisations = createAsyncThunk(
  "getOrganisations",
  async () => {
    const response = await api.get(`/organisations`);
    return response.data;
  }
);

export const getNotificationHistory = createAsyncThunk(
  "getNotificationHistory",
  async () => {
    const response = await api.get(`/notifications/history`);
    return response.data;
  }
);
