import {
  Card,
  CardContent,
  CardHeader,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getSettingsOverridenByCollections } from "../../services/utils/settings";
import { setOrganisationSetting } from "../app";

export const defaultMarkSettings = [
  {
    title: "Tjocklek ytterkant",
    field: "strokeWidth",
    value: 0,
    valueType: "number",
    min: 0,
    max: 50,
  },
  {
    title: "Tjocklek markerad ytterkant",
    field: "selectedStrokeWidth",
    value: 4,
    valueType: "number",
    min: 0,
    max: 50,
  },
  {
    title: "Opacitet",
    field: "opacity",
    value: 0.7,
    valueType: "number",
    min: 0,
    max: 1,
  },
  {
    title: "Opacitet ytterkant",
    field: "opacityStroke",
    value: 0.7,
    valueType: "number",
    min: 0,
    max: 1,
  },
  {
    title: "Standardstorlek för nya punkter",
    field: "defaultSize",
    value: 15,
    valueType: "number",
    min: 0,
    max: 500,
  },
  {
    title: "Tillgänglig plats tjocklek ytterkant",
    field: "strokeWidthSpotAvailable",
    value: 2,
    valueType: "number",
    min: 0,
    max: 50,
  },
];

const getMarksDefault = (field) =>
  defaultMarkSettings.find((c) => c.field === field);

export default function MarkSettings() {
  const dispatch = useDispatch();
  const orgSettings = useSelector((state) => state.app.orgSettings);
  const markSettings = getSettingsOverridenByCollections(
    "marks",
    defaultMarkSettings,
    orgSettings
  );

  const handleChange = (setting, val) => {
    const { field, title } = setting;
    const valueType = getMarksDefault(setting.field).valueType;
    let value = val;
    if (valueType === "number") value = Number(val);
    dispatch(
      setOrganisationSetting({
        type: "marks",
        settings: [{ field, value, title }],
      })
    );
  };

  const marks = [
    { value: 0, label: 0 },
    { value: 0.2, label: ".2" },
    { value: 0.4, label: ".4" },
    { value: 0.6, label: ".6" },
    { value: 0.8, label: ".8" },
    { value: 1, label: 1 },
  ];
  return (
    <Card>
      <CardHeader title="Platsinställningar" />
      <CardContent>
        <TableContainer>
          <Table>
            <TableBody>
              {markSettings.map((setting) => {
                const defaultSetting = getMarksDefault(setting.field);
                // console.log('defaultSetting: ',defaultSetting) // ok
                const { value } = setting;
                let valueType = setting?.valueType;
                if (!valueType) valueType = defaultSetting.valueType;
                // console.log('valueType', valueType) // ok
                let min = defaultSetting.min;
                let max = defaultSetting.max;
                if (setting?.max) max = setting.max;
                if (setting?.min) min = setting.min;

                const commonProps = { value, min, max, style: { width: 200 } };

                return (
                  <TableRow key={"setting-row-" + setting.field}>
                    <TableCell>{setting.title}</TableCell>
                    <TableCell>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        {valueType === "number" && min === 0 && max === 1 ? (
                          <Slider
                            {...commonProps}
                            step={0.05}
                            marks={marks}
                            valueLabelDisplay="auto"
                            onChange={(e, val) => handleChange(setting, val)}
                          />
                        ) : (
                          <TextField
                            {...commonProps}
                            type="number"
                            variant="outlined"
                            size="small"
                            onChange={(e) =>
                              handleChange(setting, e.target.value)
                            }
                          />
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}
