import { createSlice } from "@reduxjs/toolkit";
import {
  addOrganisation,
  deleteOrganisation,
  getNotificationHistory,
  getOrganisations,
  updateOrganisation,
} from "./adminAPI";

const initialState = {
  organisations: [],
  notifHistory: [],
};

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOrganisations.fulfilled, (state, { payload }) => {
        state.organisations = payload.organisations;
      })
      .addCase(addOrganisation.fulfilled, (state, { payload }) => {
        state.organisations.push(payload.organisation);
      })
      .addCase(deleteOrganisation.fulfilled, (state, { meta }) => {
        // 204 request, tar id från meta
        state.organisations = state.organisations.filter(
          (x) => x.id !== meta.arg.id
        );
      })
      .addCase(updateOrganisation.fulfilled, (state, { payload }) => {
        state.organisations = state.organisations.map((x) =>
          x.id === payload.organisation.id ? payload.organisation : x
        );
      })
      .addCase(getNotificationHistory.fulfilled, (state, { payload }) => {
        state.notifHistory = payload.notifications;
      });
  },
});

// export const { ... } = orgSlice.actions;

export default adminSlice.reducer;
