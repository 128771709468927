import { TableCell, TableCellProps } from "@mui/material";
import { FC } from "react";

const TVal: FC<TableCellProps> = (props) => (
  <TableCell align="right" {...props}>
    {props.children}
  </TableCell>
);

export default TVal;
