import {
  Button,
  Card,
  CardContent,
  CardHeader,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ConfirmDialog, DeleteButton } from "../../Components/utils";
import { deleteMarkField, getMarkFields, patchMark } from "../app";
import { StickyHeadTable } from "../data/Tables/Tables";
import { LockField } from "../map/MapView/LockField";

export function DataFields() {
  return (
    <Card>
      <CardHeader title="Datafält" />
      <CardContent>
        <ListDataFields />
        <FieldsData />
      </CardContent>
    </Card>
  );
}

function ListDataFields() {
  const [removing, setRemove] = useState(null);
  const dispatch = useDispatch();
  const markFields = useSelector((state) => state.app.markFields);

  useEffect(() => {
    dispatch(getMarkFields());
  }, []);

  const DeleteDataFieldDialog = () => (
    <ConfirmDialog
      title="Radera datafält?"
      text="Tillhörande data försvinner också."
      open={removing}
      setOpen={setRemove}
      onConfirm={() => dispatch(deleteMarkField(removing.id))}
    />
  );

  const TableHead = () => (
    <>
      <TableCell></TableCell>
      <TableCell>Internt fältnamn</TableCell>
      <TableCell>Visningsnamn</TableCell>
      <TableCell>Datatyp</TableCell>
      <TableCell>
        <DeleteDataFieldDialog />
      </TableCell>
      {/* förekomster etc... */}
    </>
  );

  const TableRows = () =>
    markFields.map((markfield) => (
      <TableRow key={markfield.id}>
        <TableCell>
          {LockField({ field: markfield, lockGlobally: true })}
        </TableCell>
        <TableCell>{markfield.field}</TableCell>
        <TableCell>{markfield.name}</TableCell>
        <TableCell>{markfield.type}</TableCell>
        <TableCell>{DeleteButton(() => setRemove(markfield))}</TableCell>
      </TableRow>
    ));

  return (
    <StickyHeadTable
      maxHeight={400}
      stickyHeaderRow={<TableHead />}
      size="small"
    >
      <TableRows />
    </StickyHeadTable>
  );
}

function FieldsData() {
  const dispatch = useDispatch();
  const marks = useSelector((state) => state.app.marks);
  const fields = useSelector((state) => state.app.markFields);
  const images = useSelector((state) => state.app.images);
  const data = marks.filter((mark) => mark.data);
  const [openData, setData] = useState(null);

  const HeadColumns = () => (
    <>
      <TableCell>Vy</TableCell>
      <TableCell>Plats</TableCell>
      <TableCell>Data</TableCell>
      <TableCell></TableCell>
    </>
  );

  const DataRows = () =>
    data.map((mark) => {
      const img = images.find((x) => x.id === mark.image);
      return (
        <TableRow key={`data-in-mark-${mark.id}`}>
          <TableCell>{img?.title || img?.filename}</TableCell>
          <TableCell>{mark?.spot || "Ej kopplad"}</TableCell>
          <TableCell>
            {Object.keys(mark.data).map((key) => (
              <Button
                key={`data-field-${key}-${mark.id}`}
                onClick={() => setData({ key, content: mark.data[key] })}
              >
                {fields.find((x) => x.field === key)?.name}
              </Button>
            ))}
          </TableCell>
          <TableCell>
            {DeleteButton(() =>
              dispatch(patchMark({ id: mark.id, data: { data: {} } }))
            )}
          </TableCell>
        </TableRow>
      );
    });

  return (
    <>
      <ConfirmDialog
        title={"Innehåll: " + openData?.key}
        text={String(openData?.content)}
        yes="Radera"
        no="Stäng"
        open={!!openData}
        setOpen={setData}
        onConfirm={() => null}
      />
      <Typography variant="h6">Platser som innehåller data: </Typography>
      <StickyHeadTable size="small" stickyHeaderRow={<HeadColumns />}>
        <DataRows />
      </StickyHeadTable>
    </>
  );
}
