import { Assignment, MinimalMark, PreviewAssignment } from "@hamnvy/shared";
import { Button, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import { FC, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { api } from "../../services/api";
import AssignmentAssigneesField from "./fields/AssignmentAssigneesField";
import AssignmentAttachments from "./fields/AssignmentAttachments";
import AssignmentCategoriesField from "./fields/AssignmentCategoriesField";
import AssignmentDescriptionField from "./fields/AssignmentDescriptionField";
import AssignmentTitleField from "./fields/AssignmentTitleField";
import ImageWithMarks from "./ImageWithMarks";
import PreviewImage from "./PreviewImage";

interface Props {
  disableSpot?: boolean;
  mark?: MinimalMark;
  onCancel: () => void;
  onSuccess: (assignment: Assignment) => void;
}

const WorkAssignmentForm: FC<Props> = (props) => {
  const methods = useForm<{
    assignment: PreviewAssignment;
  }>();
  const { mark, onCancel, onSuccess, disableSpot } = props;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const assignment = methods.watch("assignment");

  const isValid = useMemo(() => {
    return Boolean(assignment?.title);
  }, [assignment?.title]);

  const onSubmit = async (data: PreviewAssignment) => {
    try {
      const payload = {
        ...data,
        assignees: data.assignees.map(({ id }) => id),
        marks: mark ? [mark.id] : [],
        variant: "failure",
        status: { code: "todo" },
        attachments: data.attachments.map(({ summary }) => summary.id),
      };
      const result = await api.post("/assignments", payload);
      enqueueSnackbar(t("failureReport.create_success", "Felanmälan skapad"), {
        variant: "success",
      });
      onSuccess?.(result.data?.assignment);
    } catch (err) {
      enqueueSnackbar(
        t("failureReport.create_error", "Felanmälan kunde inte skapas"),
        { variant: "error" }
      );
    }
  };

  return (<>
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(({ assignment }) =>
          onSubmit(assignment)
        )}
      >
        <Box sx={{ display: "grid", gap: 1 }}>
          {!disableSpot && (
            <Box sx={{ display: "flex", gap: 1, alignItems: "flex-end" }}>
              {mark ? (
                <PreviewImage title="Vald plats:">
                  <ImageWithMarks marks={[mark]} imageId={mark.image} />
                </PreviewImage>
              ) : (
                <Box>Plats ej angiven</Box>
              )}
              <Box sx={{ width: "100%" }}>
                <TextField
                  label={t("workAssignment.spot", { defaultValue: "Plats" })}
                  disabled
                  fullWidth
                  size="small"
                  value={mark ? mark.spot : ""}
                  variant="outlined"
                />
              </Box>
            </Box>
          )}
          <AssignmentTitleField />
          <AssignmentCategoriesField />
          <AssignmentAttachments />
          <AssignmentDescriptionField />
          <AssignmentAssigneesField />
          <Box sx={{ display: "flex", justifyContent: "space-between", pt: 2 }}>
            <Button onClick={onCancel}>
              {t("workAssignment.cancel", "Avbryt")}
            </Button>
            <Button variant="contained" type="submit" disabled={!isValid}>
              {t("workAssignment.save", "Spara")}
            </Button>
          </Box>
        </Box>
      </form>
    </FormProvider>
  </>
  );
};

export default WorkAssignmentForm;
