import { Box } from "@mui/material";
import { FC } from "react";
import SearchSpot from "./SearchSpot";

interface Props {
  onSpotSelect: (spot: string) => void
}
const ComboSpotSelector: FC<Props> = (props) => {
  const { onSpotSelect } = props;
  return <Box>
    <SearchSpot onChange={onSpotSelect} />
  </Box>;
};

export default ComboSpotSelector;
