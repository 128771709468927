import { Check, Close, Edit, SystemUpdateAlt } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DeleteButton } from "../../Components/utils";
import { getObjectWithValuesAccordingToFieldType } from "../../services/utils/fields";
import {
  addBoats,
  deleteBoat,
  getBoats,
  getSpots,
  migrateBoatsFromBAS,
  updateBoat,
} from "../app/appApi";
import { BasUploadsTable } from "../bas/BasUploads";
import { StickyHeadTable } from "../data/Tables/Tables";

export function ManageBoats() {
  const boats = useSelector((state) => state.app.boats);
  const uploads = useSelector((state) => state.app.uploads);
  const spots = useSelector((state) => state.app.spots);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBoats());
    if (spots.length === 0) dispatch(getSpots());
  }, []);

  return (
    <>
      {BoatsTable({ boats, spots })}
      {/* <ImportBoats /> */}
    </>
  );

  function ImportBoats() {
    return (
      <Card>
        <CardHeader title="Importera båtar från BAS" />
        <CardContent>
          <p>Val av fil från importhistorik att hämta in båtar från</p>
          {BasUploadsTable({
            uploads,
            download: false,
            action: (
              <Tooltip title="Importera tillhörande båtar">
                <IconButton onClick={() => dispatch(migrateBoatsFromBAS())} size="large">
                  <SystemUpdateAlt />
                </IconButton>
              </Tooltip>
            ),
          })}
        </CardContent>
      </Card>
    );
  }
}

export const boatFields = [
  { field: "type", title: "Båttyp", type: "string" },
  { field: "brand", title: "Fabrikat", type: "string" },
  { field: "model", title: "Modell", type: "string" },
  { field: "name", title: "Båtnamn", type: "string" },
  { field: "note", title: "Notering", type: "string" },
  // { field: "serial", title: "Serienummer", type: "string" },
  { field: "length", title: "Längd", type: "number" },
  { field: "width", title: "Bredd", type: "number" },
  { field: "depth", title: "Djup", type: "number" },
  { field: "height", title: "Höjd", type: "number" },
  { field: "weight", title: "Vikt", type: "number" },
];

function BoatsTable({ boats, spots = null }) {
  const dispatch = useDispatch();
  const customers = useSelector((state) => state.app.customers);
  const [changes, setChanges] = useState({});
  const [isEditing, setEditing] = useState(null);

  const stopEditing = () => {
    setEditing(null);
    setChanges({});
  };

  const saveChanges = async () => {
    const boatChanges = getObjectWithValuesAccordingToFieldType(
      changes,
      boatFields
    );
    const payload = { id: isEditing, ...boatChanges };
    const result = await dispatch(updateBoat(payload));
    if (result.meta.requestStatus === "fulfilled") {
      stopEditing();
    }
  };

  return (
    <StickyHeadTable
      stickyHeaderRow={
        <>
          {spots && <TableCell>Plats</TableCell>}
          <TableCell>Ägare</TableCell>

          {boatFields.map((b) => (
            <TableCell key={b.field}>{b.title}</TableCell>
          ))}
          <TableCell></TableCell>
        </>
      }
      footer={null}
      emptyText="Inga rader att visa..."
      maxHeight={500}
      size="small"
    >
      {boats.map((b) => (
        <TableRow key={b.id}>
          {spots && (
            <TableCell>
              {spots.find((x) => x?.boat?.id === b?.id)?.spotNumber || "-"}
            </TableCell>
          )}
          <TableCell>
            {isEditing === b.id ? (
              <CustomerField
                customers={customers}
                value={b.owner}
                title="Ägare"
                onSelect={(owner) =>
                  owner
                    ? dispatch(updateBoat({ id: b.id, owner: owner.id }))
                    : dispatch(updateBoat({ id: b.id, owner: null }))
                }
              />
            ) : (
              b.owner?.name || "-"
            )}
          </TableCell>
          {boatFields.map(({ field }) => (
            <TableCell key={"editdisp" + field + b.id}>
              {EditOrDisplayBoatField(
                isEditing,
                b,
                field,
                changes,
                setChanges,
                dispatch
              )}
            </TableCell>
          ))}
          <TableCell key={b.id + "options"}>
            <div>
              {isEditing !== b.id ? (
                <IconButton onClick={() => setEditing(isEditing === b.id ? null : b.id)} size="large">
                  <Edit />
                </IconButton>
              ) : (
                <>
                  <Tooltip title="Spara ändringar">
                    <div>
                      <IconButton disabled={_.isEmpty(changes)} onClick={() => saveChanges()} size="large">
                        <Check />
                      </IconButton>
                    </div>
                  </Tooltip>
                  <Tooltip title="Avbryt">
                    <IconButton onClick={() => stopEditing()} size="large">
                      <Close />
                    </IconButton>
                  </Tooltip>
                  {DeleteButton(() => dispatch(deleteBoat(b.id)))}
                </>
              )}
            </div>
          </TableCell>
        </TableRow>
      ))}
    </StickyHeadTable>
  );
}

export function CreateBoat({ open, onClose }) {
  const dispatch = useDispatch();
  const [newBoat, setNewBoat] = useState({});

  const handleSave = async () => {
    const result = await dispatch(addBoats([newBoat]));
    if (result.meta.requestStatus === "fulfilled") {
      setNewBoat({});
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogTitle>Skapa ny båt</DialogTitle>
      <DialogContent>
        <EditBoat boat={newBoat} setBoat={setNewBoat} />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSave()}
        >
          Spara
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function EditBoat({ boat, setBoat }) {
  const customers = useSelector((state) => state.app.customers);
  return (boat && (<div style={{ display: "grid", gap: 8 }}>
    {CustomerField({
      customers,
      value: boat.owner,
      title: "Ägare",
      onSelect: (customer) =>
        setBoat({ ...boat, owner: customer ? customer.id : null }),
    })}
    <div></div>
    {SelectBoatType({
      boatTypes,
      value: boat.type,
      onSelect: (option) => setBoat({ ...boat, type: option }),
    })}
    <TextField
      variant="outlined"
      label="Fabrikat"
      value={boat.brand || ""}
      onChange={(e) => setBoat({ ...boat, brand: e.target.value })}
    />
    <TextField
      variant="outlined"
      label="Modell"
      value={boat.model || ""}
      onChange={(e) => setBoat({ ...boat, model: e.target.value })}
    />
    <TextField
      size="small"
      variant="outlined"
      label="Båtens namn"
      value={boat.name || ""}
      onChange={(e) => setBoat({ ...boat, name: e.target.value })}
    />
    {/* <TextField size="small" variant="outlined" label="Serienummer" value={serial} onChange={e => setSerial(e.target.value)} /> */}
    <div></div>
    <div
      style={{
        display: "grid",
        gap: 8,
        gridTemplateColumns: "repeat(auto-fill, minmax(100px, 1fr)",
      }}
    >
      <TextField
        size="small"
        type="number"
        variant="outlined"
        label="Längd"
        value={boat.length || ""}
        onChange={(e) => setBoat({ ...boat, length: e.target.value })}
        slotProps={{
          input: { inputProps: { min: 0 } }
        }}
      />
      <TextField
        size="small"
        type="number"
        variant="outlined"
        label="Bredd"
        value={boat.width || ""}
        onChange={(e) => setBoat({ ...boat, width: e.target.value })}
        slotProps={{
          input: { inputProps: { min: 0 } }
        }}
      />
      <TextField
        size="small"
        type="number"
        variant="outlined"
        label="Djup"
        value={boat.depth || ""}
        onChange={(e) => setBoat({ ...boat, depth: e.target.value })}
        slotProps={{
          input: { inputProps: { min: 0 } }
        }}
      />
      <TextField
        size="small"
        type="number"
        variant="outlined"
        label="Höjd"
        value={boat.height || ""}
        onChange={(e) => setBoat({ ...boat, height: e.target.value })}
        slotProps={{
          input: { inputProps: { min: 0 } }
        }}
      />
      <TextField
        size="small"
        type="number"
        variant="outlined"
        label="Vikt"
        value={boat.weight || ""}
        onChange={(e) => setBoat({ ...boat, weight: e.target.value })}
        slotProps={{
          input: { inputProps: { min: 0 } }
        }}
      />
    </div>
    <div></div>
    <TextField
      multiline
      variant="outlined"
      label="Övrig notering"
      value={boat.note || ""}
      onChange={(e) => setBoat({ ...boat, note: e.target.value })}
    />
  </div>));
}

function CustomerField({ customers, value, title, onSelect }) {
  return (
    <Autocomplete
      size="small"
      style={{ minWidth: 200 }}
      options={customers}
      value={
        typeof value == "string"
          ? customers.find((x) => x.id === value)
          : value || null
      }
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option) =>
        (typeof value == "string" ? value : value.id) === option.id
      }
      noOptionsText="Inga kunder hittades"
      renderInput={(params) => (
        <TextField
          {...params}
          label={title || "Välj kund"}
          variant="outlined"
        />
      )}
      onChange={(event, newValue) => onSelect(newValue)}
      selectOnFocus={true}
    />
  );
}

const boatTypes = [
  "Motorbåt",
  "Segelbåt",
  "Fiskebåt",
  "Jolle",
  "Styrpulpet",
  "Snipa",
  "Vattenskoter",
];

function SelectBoatType({ boatTypes, value, onSelect }) {
  return (
    <FormControl
      variant="outlined"
      size="small"
      fullWidth
      style={{ minWidth: 150 }}
    >
      <InputLabel id="boat-type-select-label">Båttyp</InputLabel>
      <Select
        value={value || ""}
        onChange={(e) => onSelect(e.target.value)}
        label="Båttyp"
      >
        {boatTypes.map((val) => (
          <MenuItem key={"option-for-" + val} value={val}>
            {val}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

function EditOrDisplayBoatField(
  isEditingId,
  boat,
  field,
  dirty,
  setDirty,
  dispatch
) {
  const customerField = boatFields.find((x) => x.field === field);
  const label = customerField.title;
  if (isEditingId === boat.id) {
    if (field === "type")
      return SelectBoatType({
        boatTypes,
        value: boat.type,
        onSelect: (type) => dispatch(updateBoat({ id: boat.id, type })),
      });
    else
      return (
        <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
          <TextField
            style={{ minWidth: 100 }}
            variant="outlined"
            size="small"
            label={label}
            defaultValue={boat[field]}
            onChange={(e) => setDirty({ ...dirty, [field]: e.target.value })}
          />
        </div>
      );
  }

  return boat[field] || "-";
}

export function SelectBoat({ boats, value, title = null, onSelect }) {
  return (
    <Autocomplete
      size="small"
      style={{ minWidth: 200 }}
      options={boats}
      value={value}
      isOptionEqualToValue={(b) => b.id === value?.id}
      getOptionLabel={(b) =>
        `${b.brand || ""} ${b.model || ""} (${b.owner?.name || ""})`
      }
      noOptionsText="Inga båtar hittades"
      renderInput={(params) => (
        <TextField {...params} label={title || "Välj båt"} variant="outlined" />
      )}
      onChange={(event, newValue) => onSelect(newValue)}
      selectOnFocus={true}
    />
  );
}
