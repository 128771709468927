import { Container } from "@mui/material";
import UserStats from "../admin/UserStats";
import Header from "../main/Header";
import PageHeader from "../main/PageHeader";
import NotificationPage from "../notifications/NotificationPage";

export default function ActivityPage() {
  return (
    <>
      {/* @ts-ignore */}
      <Header>
        <PageHeader title="Applikationsanvändning" />
      </Header>
      <Container style={{ display: "grid", gap: 16, marginTop: 16 }}>
        <NotificationPage />
        <UserStats />
      </Container>
    </>
  );
}
