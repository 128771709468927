import { TableCell, TableCellProps } from "@mui/material";
import { FC } from "react";

const TKey: FC<TableCellProps> = (props) => (
  <TableCell align="left" {...props}>
    {props.children}
  </TableCell>
);

export default TKey;
