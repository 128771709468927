import { IUser } from "@hamnvy/shared";
import { Add } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  TextField,
  Tooltip,
  useTheme,
} from "@mui/material";
import Popper from "@mui/material/Popper";
import { blueGrey } from "@mui/material/colors";
import { useSnackbar } from "notistack";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PopperContent from "../../../Components/PopperContent";
import SlimChip from "../../../Components/utils/SlimChip";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getUsers } from "../../app";
import { AssignmentContext } from "../AssignmentProvider";

interface Props {
  editable?: boolean;
}

const Assignees: FC<Props> = (props) => {
  const { editable } = props;
  const dispatch = useAppDispatch();
  const users: IUser[] = useAppSelector((s) => s.app.users);
  const { assignment, updateAssignment } = useContext(AssignmentContext);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [assigneeToAdd, setAssigneeToAdd] = useState<IUser | null>(null);

  const handleClick = (event?: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event ? (anchorEl ? null : event.currentTarget) : null);
  };

  useEffect(() => {
    if (users.length === 0) {
      dispatch(getUsers());
    }
  }, []);

  const open = Boolean(anchorEl);
  const id = open ? "assignee-popper" : undefined;

  const assignees = assignment?.assignees ?? [];

  const handleAddAssignee = async () => {
    if (assigneeToAdd) {
      try {
        await updateAssignment({
          assignees: assignees.map((x) => x.id).concat(assigneeToAdd.id),
        });
        setAssigneeToAdd(null);
        handleClick();
      } catch (err) {
        enqueueSnackbar(
          t("assignment.update-property-error", {
            defaultValue: "Fel uppstod. Kunde inte uppdatera egenskap.",
          }),
          { variant: "error" }
        );
      }
    }
  };
  return (
    <>
      {editable && (
        <IconButton
          sx={{ height: "24px", width: "24px" }}
          onClick={handleClick}
        >
          <Add />
        </IconButton>
      )}
      {assignees.map((user) => (
        <SlimChip
          key={user.id}
          color={blueGrey[800]}
          onDelete={
            editable
              ? () =>
                updateAssignment({
                  assignees: assignees
                    .map((x) => x.id)
                    .filter((x) => x !== user.id),
                })
              : undefined
          }
          label={user?.name ?? user.username}
        />
      ))}
      {!!editable && <>
        <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-end">
          <PopperContent
            heading="Lägg till ansvarig"
            handleClick={() => handleClick()}
          >
            <Autocomplete
              size="small"
              value={assigneeToAdd}
              onChange={(e, value) => setAssigneeToAdd(value)}
              options={users}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option?.name ?? option.username}
              getOptionDisabled={(option) =>
                assignees.map((x) => x.id).includes(option.id)
              }
              noOptionsText={t("workAssignment.assignees_empty", {
                defaultValue: "Hittar ingen att tilldela till",
              })}
              renderInput={(params) => (
                <TextField
                  label={t("workAssignment.assignees", {
                    defaultValue: "Användare",
                  })}
                  sx={{ "& > input, div": { background: "#fff" } }}
                  {...params}
                />
              )}
            />
            <Tooltip
              title={
                assigneeToAdd
                  ? "Tilldela vald användare"
                  : "Sök och välj användare ovan först"
              }
            >
              <Box>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  startIcon={<Add />}
                  onClick={handleAddAssignee}
                  disabled={!assigneeToAdd}
                >
                  Lägg till
                </Button>
              </Box>
            </Tooltip>
          </PopperContent>
        </Popper>
      </>
      }
    </>)
}

export default Assignees;
