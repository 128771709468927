import { Add, Close, Save } from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AddButton,
  ConfirmDialog,
  DeleteButton,
  EditButton,
  MinusButton,
} from "../../Components/utils";
import {
  addUserRole,
  deleteUserRole,
  getUserRoles,
  updateUser,
} from "../app/appApi";
import EditUserGroup from "./EditUserGroup";

export const userRights = [
  { id: 0, field: "data", name: "Data i tabellform" },
  { id: 1, field: "readonly", name: "Fullständiga läsrättigheter" },
  { id: 2, field: "readwrite", name: "Fullständiga läs- och skrivrättigheter" },
  { id: 3, field: "selectedPages", name: "Tillgång till utvalda sidor" },
  { id: 4, field: "manageCustomers", name: "Kundhantering" },
  { id: 5, field: "editMarks", name: "Redigera punkter" },
  { id: 6, field: "messages", name: "Visa alla noteringar" },
  { id: 7, field: "availability", name: "Hantering av tillgängliga platser" },
  { id: 8, field: "guestManager", name: "Gästhantering" },
  { id: 9, field: "advancedFunctions", name: "Avancerade funktioner" },
];

export default function UserGroups() {
  const dispatch = useDispatch();
  const [isAdding, setAdding] = useState(false);
  const [isRemoving, setRemoving] = useState(null);
  const [isEditing, setEditing] = useState(null);

  function AddUserGroup() {
    const [name, setName] = useState("");
    const [rights, setRights] = useState([]);

    async function add() {
      const result = await dispatch(addUserRole({ name, rights }));
      if (!result?.error) setAdding(false);
    }

    return (
      <div style={{ display: "grid", gap: 8 }}>
        <Typography variant="body1">Skapa ny användargrupp</Typography>
        <TextField
          key="name-for-usergroup"
          value={name}
          onChange={(e) => setName(e.target.value)}
          size="small"
          variant="outlined"
          label="Namn på användargrupp"
        ></TextField>
        {rights.length > 0 && (
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>Tilldelade rättigheter</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rights.map((right, index) => (
                  <TableRow key={"add-right-" + index}>
                    <TableCell>
                      {userRights.find((x) => x.field === right).name}
                    </TableCell>
                    <TableCell padding="none">
                      {MinusButton(() =>
                        setRights([
                          ...rights.slice(0, index),
                          ...rights.slice(index + 1),
                        ])
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <AddUserRights onSelect={(field) => setRights(rights.concat(field))} />
        <Button
          variant="contained"
          color="secondary"
          startIcon={<Add />}
          onClick={() => add()}
        >
          Skapa
        </Button>
        <Button
          variant="outlined"
          onClick={() => setAdding(false)}
          startIcon={<Close />}
        >
          Stäng
        </Button>
      </div>
    );
  }

  function UserGroupsTable() {
    const roles = useSelector((state) => state.app.roles);
    const users = useSelector((state) => state.app.users);
    const [openPages, setPages] = useState(null);

    /** on mount get user roles */
    useEffect(() => {
      dispatch(getUserRoles());
    }, []);

    const getUsersWithRole = (id) => users.filter((u) => u?.role === id);

    return (
      <>
        <TableContainer>
          <Dialog open={!!openPages} onClose={() => setPages(null)}>
            <DialogTitle>Användargrupp: {openPages?.role?.name}</DialogTitle>
            <DialogContent>
              <Typography variant="body1">Tillgång till sidor:</Typography>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" color="primary" startIcon={<Save />}>
                Spara
              </Button>
              <Button startIcon={<Close />} onClick={() => setPages(null)}>
                Stäng
              </Button>
            </DialogActions>
          </Dialog>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Namn</TableCell>
                <TableCell>Rättigheter</TableCell>
                <TableCell></TableCell>
                <TableCell align="right">Antal användare</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {roles &&
                roles.map((role, index) => {
                  const disabled = getUsersWithRole(role.id).length > 0;
                  return (
                    <TableRow key={"user-group-row-" + role.id}>
                      <TableCell>{role.name}</TableCell>
                      <TableCell>
                        {userRights
                          .filter((x) => role.rights.includes(x.field))
                          .map((x) => x.name)
                          .join(", ")}
                      </TableCell>
                      <TableCell>
                        {role.rights.includes("selectedPages") && (
                          <Tooltip title="Klicka för att visa och redigera sidor">
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={() => setPages({ role })}
                            >
                              X antal sidor
                            </Button>
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {getUsersWithRole(role.id).length}
                      </TableCell>
                      <TableCell padding="none">
                        <div style={{ display: "flex", gap: 4 }}>
                          {EditButton(() => setEditing(role))}
                          {DeleteButton(() => setRemoving({ index }), {
                            disabled,
                            title: disabled
                              ? "Endast möjligt att ta bort användargrupp utan användare"
                              : "Radera användargrupp",
                          })}
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }

  return (
    <>
      <ConfirmDialog
        title="Radera användargrupp?"
        open={!!isRemoving}
        setOpen={setRemoving}
        onConfirm={() => dispatch(deleteUserRole(isRemoving))}
      />
      <Card>
        <CardHeader
          title="Användargrupper"
          action={<>{AddButton(() => setAdding(true))}</>}
        />
        <CardContent style={{ display: "grid", gap: 8 }}>
          <UserGroupsTable />
          {isAdding && <AddUserGroup />}
          {isEditing && (
            <EditUserGroup
              userGroup={isEditing}
              onClose={() => setEditing(null)}
            />
          )}
        </CardContent>
      </Card>
    </>
  );
}

// todo disabled if added already
export function AddUserRights({ onSelect }) {
  const handleChange = (e) => onSelect(e.target.value);

  return (
    <FormControl
      variant="outlined"
      fullWidth
      size="small"
      style={{ marginTop: 8 }}
    >
      <InputLabel id="select-user-right-label">
        Lägg till användarrättighet
      </InputLabel>
      <Select
        id="select-user-right"
        onChange={handleChange}
        labelId="select-user-right-label"
        value=""
      >
        {userRights.map(({ id, field, name }) => (
          <MenuItem key={"user-right-option-for-" + id} value={field}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export function SelectUserGroup({ user, roles, dispatch }) {
  return (
    <FormControl
      variant="outlined"
      size="small"
      style={{ marginTop: 8, minWidth: 200 }}
    >
      <InputLabel id={`select-usergroup-for-${user.id}-label`}>
        Välj användargrupp
      </InputLabel>
      <Select
        defaultValue=""
        id={`select-usergroup-for-${user.id}`}
        onChange={(e) =>
          dispatch(updateUser({ id: user.id, role: e.target.value }))
        }
        labelId="select-user-right-label"
      >
        {roles &&
          roles.map(({ id, name }) => (
            <MenuItem
              key={"usergroup-option-for-" + id + "-user-" + user.id}
              value={id}
            >
              {name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}
