import React, { forwardRef, useEffect, useState } from 'react';
import { api } from '../../services/api';

interface AuthImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
    src: string;
}

const AuthImage = forwardRef<HTMLImageElement, AuthImageProps>(({ src, ...props }, ref) => {
    const [imageSrc, setImageSrc] = useState<string | null>(null);

    useEffect(() => {
        const fetchImage = async () => {
            const response = await api.get(src);
            if (response.status === 200) {
                const url = response.data.src;
                setImageSrc(url);
            } else {
                console.error('Failed to fetch image');
            }
        }


        fetchImage();

        // Clean up the Blob URL when the component unmounts
        return () => {
            if (imageSrc) {
                URL.revokeObjectURL(imageSrc);
            }
        };
    }, [src]);

    return (
        <img src={imageSrc || undefined} ref={ref} {...props} />
    );
});

AuthImage.displayName = 'AuthImage';

export default AuthImage;