import { Button, Card, CardContent, CardHeader } from "@mui/material";
import { useNavigate } from "react-router";
export default function UnauthorizedPage(props) {
  const navigate = useNavigate();
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card>
        <CardHeader title="Behörighet saknas för att besöka angiven adress" />
        <CardContent>
          <Button
            onClick={() => navigate("/")}
            variant="contained"
            color="primary"
          >
            Gå hem
          </Button>
        </CardContent>
      </Card>
    </div>
  );
}
