/**
 * Handles string to number with support of comma-decimals.
 * @param {*} strVal value to convert
 * @param {String} fieldType string of field's type
 * @returns Converted value
 */
export function convertStringValueToFieldType(strVal, fieldType) {
    return fieldType === 'number' ? Number.parseFloat(strVal.replace(',', '.')) : strVal;
}

/**
 * Handles value conversion of multiple fields in an object based on field name
 *  and fields object
 * @param {*} obj original unmodified object
 * @param {*} fields array of objects with {field, type}
 * @returns new object
 */
export function getObjectWithValuesAccordingToFieldType(obj, fields) {
    const newObject = {};
    Object.keys(obj).forEach(field => {
        const value = obj[field];
        const type = fields.find(x => x.field === field).type;
        newObject[field] = convertStringValueToFieldType(value, type);
    })
    return newObject;
}
