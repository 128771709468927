import { Button, FormControlLabel, Switch } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { ConfirmDialog } from "../../../../Components/utils";
import { useStyles } from "../../../../styles/styles";
import { addMode, removeMode, toggleMode } from "../../../app";

export function Modes({
  modes,
  noSelected,
  setSelectedBasRow,
  preview,
  setPreview,
  ifMultipleSelected,
  deselectAll,
}) {
  const dispatch = useDispatch();
  const [openAskBeforeDeselectAll, setAskBeforeDeselectAll] = useState(false);

  const toggle = (mode) => dispatch(toggleMode(mode));
  const add = (mode) => dispatch(addMode(mode));
  const remove = (mode) => dispatch(removeMode(mode));
  const classes = useStyles();

  return (
    <div id="modes" className={classes.toolboxButtons}>
      {ConfirmDialog({
        title: "Avmarkera alla platser?",
        open: openAskBeforeDeselectAll,
        setOpen: setAskBeforeDeselectAll,
        onConfirm: () => {
          deselectAll();
          remove("selectMultiple");
        },
      })}
      <FormControlLabel
        control={
          <Switch
            checked={modes.includes("selectMultiple")}
            disabled={modes.includes("add")}
            onChange={() => {
              const mode = "selectMultiple";
              if (modes.includes(mode)) {
                if (noSelected) remove(mode);
                else setAskBeforeDeselectAll(true);
              } else {
                add(mode);
              }
            }}
            name="selectMultiple"
            color="primary"
          />
        }
        label="Markera flera"
      />
      <Button
        variant={modes.includes("edit") ? "outlined" : "contained"}
        color="primary"
        disabled={modes.includes("add") || modes.includes("move")}
        onClick={() => {
          remove("add");
          toggle("edit");
        }}
      >
        {modes.includes("edit") ? "Avsluta redigeringsläget" : "Redigera"}
      </Button>
      <Button
        variant="contained"
        color="primary"
        disabled={modes.includes("edit") || modes.includes("move")}
        onClick={() => {
          remove("edit");
          toggle("add");
          setSelectedBasRow(null);
          setPreview(!preview);
        }}
      >
        {modes.includes("add") ? "Avsluta tilläggning" : "Lägg till"}
      </Button>
      {modes.includes("edit") && (
        <Button
          variant={modes.includes("move") ? "outlined" : "contained"}
          color="primary"
          disabled={ifMultipleSelected(true)}
          onClick={() => toggle("move")}
        >
          {modes.includes("move") ? "Sluta flytta" : "Flytta"}
        </Button>
      )}
    </div>
  );
}
