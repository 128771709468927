import { MinimalMark } from "@hamnvy/shared";
import { Box, SxProps, useTheme } from "@mui/material";
import { FC } from "react";
import config from "../../config";
import { useAppSelector } from "../../hooks";
import AuthImage from "../map/AuthImage";

interface Props {
  imageId: string;
  marks: MinimalMark[];
  sx?: SxProps;
}

const ImageWithMarks: FC<Props> = (props) => {
  const { imageId, marks, sx } = props;
  const images = useAppSelector((s) => s.app.images);
  // @ts-expect-error
  const image = images.find((x) => x.id === imageId);
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        userSelect: "none",
        borderRadius: 1,
        overflow: "hidden",
        position: "relative",
        maxWidth: "100%",
        height: "auto",
        ...sx,
      }}
    >
      <AuthImage
        // @ts-expect-error
        src={`${config.apiUrl}/images/${image?.filename}`}
        draggable={false}
        style={{ display: "block", width: "100%" }}
      />
      <svg
        className="overlay-svg"
        // @ts-expect-error
        viewBox={`0 0 ${image?.width ?? 0} ${image?.height ?? 0}`}
      >
        {marks
          .filter((x) => x.image === imageId)
          .map((m) => (
            <circle
              key={m.id}
              cx={m.x}
              cy={m.y}
              r={m?.style?.radius ?? 8}
              fill={theme.palette.error.main}
              stroke={theme.palette.warning.main}
              strokeWidth={4}
            />
          ))}
      </svg>
    </Box>
  );
};

export default ImageWithMarks;
