import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { isAfter } from "date-fns";
import { FC, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router";
import LoadingApp from "../features/app/LoadingApp";
import Header from "../features/main/Header";
import PageHeader from "../features/main/PageHeader";
import { api } from "../services/api";
import { PasswordReset } from "../types";
import NotFoundPage from "./404.page";
import ResetPassword from "./ResetPassword";

interface Props {
  signup?: boolean;
}

const Reset: FC<Props> = (props) => {
  const { id } = useParams();
  const { signup = false } = props;
  if (!id) return <NotFoundPage />;
  const [pwr, setPwr] = useState<PasswordReset>();
  const [loading, setLoading] = useState<boolean>(true);
  const { t } = useTranslation();

  useEffect(() => {
    api
      .get(`/reset_password/${id}`)
      .then((res) => {
        setPwr(res.data.pwr);
      })
      .finally(() => setLoading(false));
  }, []);

  const expired = pwr?.expires
    ? isAfter(new Date(), new Date(pwr.expires))
    : false;

  if (loading) return <LoadingApp />;

  return (
    <>
      <Header passive>
        <PageHeader
          title={
            <>{
              signup ?
                t("signup.page_title", "Välj ditt lösenord till Hamnvy")
                :
                t("reset_password.page_title", "Återställ ditt lösenord")
            }</>
          }
        />
      </Header>
      <Box sx={{ px: 3 }}>
        {expired ? (
          <Box>
            <Typography>
              {signup ? t("signup.expired_link", "Länken är inte giltig längre. Vänligen begär en ny av din administratör.")
               : 
                t("reset_password.expired_link", "Återställningslänken är inte giltig längre. Vänligen begär en ny.")
              }
            </Typography>
          </Box>
        ) : !pwr?.unused ? (
          <Box>
            <Typography>
              {signup ? 
                t("signup.used_link", "Ett lösenord har redan valts till kontot. Vänligen använd detta eller begär nytt om det försvunnit.")
               : 
                t("reset_password.used_link", "Återställningslänken är redan använd. Vänligen begär en ny.")
              }
            </Typography>
          </Box>
        ) : (
          <ResetPassword pwr={pwr} />
        )}
      </Box>
    </>
  );
};

export default Reset;
