import { TextField } from "@mui/material";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

const AssignmentDescriptionField: FC<{}> = (props) => {
  const { control } = useFormContext();
  const { t } = useTranslation();
  return (<>
    <Controller
      control={control}
      name="assignment.description"
      defaultValue=""
      render={({ field }) => (
        <TextField
          multiline
          size="small"
          label={t("workAssignment.description", {
            defaultValue: "Beskrivning",
          })}
          {...field}
        />
      )}
    />
  </>
  );
};

export default AssignmentDescriptionField;
