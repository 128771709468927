import { Close } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";

export default function CloseButton(onClose, options = []) {
  return (
    <Tooltip title="Stäng">
      <IconButton onClick={() => onClose()} {...options} size="large">
        <Close />
      </IconButton>
    </Tooltip>
  );
}
