import { Avatar, AvatarProps, Chip, useTheme } from "@mui/material";
import { CSSProperties, FC } from "react";
import { getInitials } from "../../services/utils/user";

interface IBasicUser {
  id: string;
  name?: string;
  color?: string;
  onClick?: () => void;
}

interface UserIconProps extends AvatarProps {
  user: IBasicUser;
  size?: number;
  style?: CSSProperties;
  onClick?: () => void;
}

export const UserIcon: FC<UserIconProps> = ({
  user,
  size,
  style = {},
  ...rest
}) => {
  const theme = useTheme();

  if (!user) return null;

  return (
    <Avatar
      style={{
        backgroundColor: user.color || theme.palette.primary.main,
        color: "#FFF",
        width: size,
        height: size,
        fontSize: size && size / 2,
        ...style,
      }}
      {...rest}
    >
      {getInitials(user)}
    </Avatar>
  );
};

export const UserIconExpandable: FC<{
  user: IBasicUser;
  expanded: boolean;
  onClick?: () => void;
}> = ({ user, expanded, onClick = null }) => {
  const theme = useTheme();

  return expanded ? (
    <Chip
      style={{
        backgroundColor: theme.palette.secondary.main,
        color: "white",
        display: "flex",
        width: "min-content",
      }}
      avatar={<UserIcon user={user} />}
      label={user?.name || user.id.slice(0, 8)}
      onClick={() => onClick && onClick()}
    />
  ) : (
    <UserIcon user={user} onClick={() => onClick && onClick()} />
  );
};
