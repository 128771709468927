import { Check, Close, Edit } from "@mui/icons-material";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DialogHeader } from "../../Components/Dialog";
import { DeleteButton } from "../../Components/utils";
import { getCompactSwePhone } from "../../services/utils/phone";
import {
  addBoats,
  addCustomer,
  deleteCustomer,
  getBoats,
  getCustomers,
  updateBoat,
  updateCustomer,
} from "../app/appApi";
import { StickyHeadTable } from "../data/Tables/Tables";
import { EditBoat } from "./manageBoats";

function ManageCustomers() {
  const dispatch = useDispatch();
  const customers = useSelector((state) => state.app.customers);
  const boats = useSelector((state) => state.app.boats);
  const spots = useSelector((state) => state.app.spots);

  // onMount effect
  useEffect(() => {
    dispatch(getCustomers());
  }, []);

  return <>{CustomersTable({ customers, boats, spots })}</>;
}

const customerFields = [
  { field: "name", title: "Namn" },
  { field: "phone", title: "Telefon" },
  { field: "email", title: "Epost" },
  { field: "address", title: "Adress" },
  { field: "postalCode", title: "Postnummer" },
  { field: "city", title: "Ort" },
];

function CustomersTable({ customers, boats, spots }) {
  const dispatch = useDispatch();
  const [isEditing, setEditing] = useState(null);
  const [changes, setChanges] = useState({});
  const [isEditingBoat, setEditBoat] = useState(null);

  const stopEditing = () => {
    setEditing(null);
    setChanges({});
  };

  const saveChanges = async () => {
    const payload = { id: isEditing, ...changes };
    const result = await dispatch(updateCustomer(payload));
    if (result.meta.requestStatus === "fulfilled") {
      stopEditing();
    }
  };

  const boatSaveDisabled =
    isEditingBoat &&
    _.isEqual(
      isEditingBoat,
      boats.find((x) => x.id === isEditingBoat.id)
    );
  const saveEditedBoat = async () => {
    const boat =
      typeof isEditingBoat.owner === "string"
        ? isEditingBoat
        : _.omit(isEditingBoat, "owner");
    const result = await dispatch(updateBoat(boat));
    if (result.meta.requestStatus === "fulfilled") {
      setEditBoat(null);
    }
  };

  useEffect(() => {
    if (boats.length === 0) {
      dispatch(getBoats());
    }
  }, []);

  return (
    <>
      {isEditingBoat && (
        <Dialog open={!!isEditingBoat} onClose={() => setEditBoat(null)}>
          <DialogHeader onClose={() => setEditBoat(null)}>
            Redigera båt
          </DialogHeader>
          <DialogContent>
            <EditBoat boat={isEditingBoat} setBoat={setEditBoat} />
          </DialogContent>
          <DialogActions>
            <Tooltip
              title={
                boatSaveDisabled
                  ? "Inga ändringar att spara"
                  : "Spara ändringar"
              }
            >
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={boatSaveDisabled}
                  onClick={() => saveEditedBoat()}
                >
                  Spara
                </Button>
              </div>
            </Tooltip>
          </DialogActions>
        </Dialog>
      )}
      <StickyHeadTable
        stickyHeaderRow={
          <>
            <TableCell>Plats</TableCell>
            {customerFields.map((c) => (
              <TableCell key={c.field}>{c.title}</TableCell>
            ))}
            <TableCell>Båtar</TableCell>
            <TableCell></TableCell>
          </>
        }
        footer={null}
        emptyText="Inga rader att visa..."
        maxHeight={500}
        size="small"
      >
        {customers.map((c) => {
          const boatSpots = spots
            .filter((s) => s.boat?.owner?.id === c.id)
            .map((x) => x.spotNumber)
            .filter((x) => x);

          return (
            <TableRow key={c.id}>
              <TableCell>{boatSpots.join(", ") || "-"}</TableCell>
              {customerFields.map(({ field }) => (
                <TableCell key={"editdisp" + field + c.id}>
                  {EditOrDisplayCustomerField(
                    isEditing,
                    c,
                    field,
                    changes,
                    setChanges,
                    dispatch
                  )}
                </TableCell>
              ))}

              <TableCell>
                <div style={{ display: "grid", gap: 4 }}>
                  {boats
                    .filter((x) => x.owner?.id === c.id)
                    .map((boat) => {
                      return isEditing === c.id ? (
                        <Chip
                          key={boat.id + "-withdelete"}
                          color="primary"
                          label={
                            boat.brand ||
                            "Ospecifierad" + " " + (boat?.model || "")
                          }
                          onDelete={() =>
                            isEditing === c.id
                              ? dispatch(
                                  updateBoat({ id: boat.id, owner: null })
                                )
                              : null
                          }
                          onClick={() => setEditBoat(boat)}
                        />
                      ) : (
                        <Chip
                          key={boat.id}
                          color="primary"
                          label={
                            boat.brand ||
                            "Ospecifierad" + " " + (boat?.model || "")
                          }
                          onClick={() => setEditBoat(boat)}
                        />
                      );
                    })}
                </div>
              </TableCell>
              <TableCell>
                <div style={{ display: "flex", gap: 2 }}>
                  {isEditing !== c.id ? (
                    <IconButton
                      onClick={() =>
                        setEditing(isEditing === c.id ? null : c.id)
                      }
                      size="large"
                    >
                      <Edit />
                    </IconButton>
                  ) : (
                    <div style={{ display: "flex", gap: 2 }}>
                      <Tooltip title="Spara ändringar">
                        <div>
                          <IconButton
                            disabled={_.isEmpty(changes)}
                            onClick={() => saveChanges()}
                            size="large"
                          >
                            <Check />
                          </IconButton>
                        </div>
                      </Tooltip>
                      <Tooltip title="Avbryt">
                        <IconButton onClick={() => stopEditing()} size="large">
                          <Close />
                        </IconButton>
                      </Tooltip>
                      {DeleteButton(() => dispatch(deleteCustomer(c.id)), {
                        disabled: isEditing !== c.id,
                      })}
                    </div>
                  )}
                </div>
              </TableCell>
            </TableRow>
          );
        })}
      </StickyHeadTable>
      {!boats.length && (
        // Button to create boats to all customers when no boats exists yet.
        (<div>
          <Button
            variant="contained"
            color="secondary"
            onClick={() =>
              dispatch(addBoats(customers.map((x) => ({ owner: x.id }))))
            }
          >
            Tilldela nya båtar till alla kunder
          </Button>
        </div>)
      )}
    </>
  );
}
function EditOrDisplayCustomerField(
  isEditingId,
  customer,
  field,
  dirty,
  setDirty,
  dispatch
) {
  const customerField = customerFields.find((x) => x.field === field);
  const label = customerField.title;

  const getDisplayField = () => {
    const val = customer[field];
    if (val) {
      if (field === "phone") return getCompactSwePhone(val);
      return customer[field];
    }
    return "-";
  };

  return (
    <>
      {isEditingId === customer.id ? (
        <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
          <TextField
            style={{ minWidth: 120 }}
            variant="outlined"
            size="small"
            label={label}
            defaultValue={customer[field]}
            onChange={(e) => setDirty({ ...dirty, [field]: e.target.value })}
          />
        </div>
      ) : (
        getDisplayField()
      )}
    </>
  );
}

export function CreateCustomer({ open, onClose }) {
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");

  const reset = () => {
    setName("");
    setPhone("");
    setEmail("");
    setAddress("");
    setCity("");
    setPostalCode("");
  };

  const onSubmit = async () => {
    const result = await dispatch(
      addCustomer({ name, phone, email, address, city, postalCode })
    );
    if (result.meta.requestStatus === "fulfilled") {
      onClose();
      reset();
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Skapa ny kund</DialogTitle>
      <DialogContent>
        <div style={{ display: "grid", gap: 8 }}>
          <TextField
            size="small"
            variant="outlined"
            label="Namn"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            size="small"
            variant="outlined"
            label="Telefon"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <TextField
            size="small"
            variant="outlined"
            label="Epost"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            size="small"
            variant="outlined"
            label="Adress"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
          <TextField
            size="small"
            variant="outlined"
            label="Ort"
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
          <TextField
            size="small"
            variant="outlined"
            label="Postnummer"
            value={postalCode}
            onChange={(e) => setPostalCode(e.target.value)}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={!name}
          onClick={() => onSubmit()}
        >
          Spara
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export { ManageCustomers };
