import { Button, ButtonProps } from "@mui/material";
import { Box } from "@mui/system";
import { ChangeEvent, FC, ReactNode } from "react";

interface Props {
  id: string;
  accept: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  children?: ReactNode;
}
const FileUploadButton: FC<Props & Omit<ButtonProps, "onChange">> = (props) => {
  const { id, accept, onChange, children, ...rest } = props;
  return (
    <Box>
      <input id={id} hidden type="file" accept={accept} onChange={onChange} />
      <label htmlFor={id}>
        <Button component="span" {...rest}>
          {children}
        </Button>
      </label>
    </Box>
  );
};

export default FileUploadButton;
