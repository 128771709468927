import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getColor } from "../../../services/colors";
import { isMarkCloseToRuler } from "../../../services/utils/rulers";
import { getMarkSetting } from "../../../services/utils/settings";
import {
  clearSelectionRectangle,
  deleteRuler,
  postMetric,
  ruler,
} from "../../app";
import { XYfromEvent } from "./utils";

function PointsOverlay({
  image,
  handleMarkerClick,
  resizeFactor,
  preview,
  radius,
  marks,
  selectedMarks,
  dispatch,
  modes,
  rulers,
  emptySpots,
}) {
  function handleClick(e) {
    // e.stopPropagation()
    // console.log('event in Overlay')
    if (modes.includes("ruler")) dispatch(ruler(XYfromEvent(e)));
  }

  return (
    <svg
      className="overlay-svg"
      style={{
        cursor:
          preview || modes.includes("move")
            ? `url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewbox='0 0 128 128' width='128' height='128'><circle r='${
                radius * resizeFactor
              }' cx='64' cy='64' fill='yellow'></circle></svg>") 64 64, pointer`
            : "unset",
      }}
      viewBox={`0 0 ${image?.width || "0"} ${image?.height || "0"}`}
      onClick={handleClick}
    >
      <SelectRectangle />
      <Rulers />
      {marks.map((mark, index) =>
        Circle({
          image,
          mark,
          index,
          handleMarkerClick,
          selectedMarks,
          modes,
          rulers,
          emptySpots,
          dispatch,
        })
      )}
    </svg>
  );
}

function Circle({
  image,
  mark,
  index,
  handleMarkerClick,
  selectedMarks,
  modes,
  rulers,
  emptySpots,
  dispatch,
}) {
  const isSelected = (mark) => selectedMarks?.includes(mark.id);
  const fill = () => {
    if (isMarkCloseToRuler(mark, rulers)) return getColor("fillCloseToRuler");
    else if (mark?.spot && emptySpots.includes(mark.spot))
      return getColor("fillSpotAvailable");
    return isSelected(mark)
      ? getColor("selectedFill")
      : getColor("fillSpotOccupied");
  };

  const strokeWidth = () => {
    if (isSelected(mark)) return getMarkSetting("selectedStrokeWidth");
    else if (mark?.spot && emptySpots.includes(mark.spot))
      return getMarkSetting("strokeWidthSpotAvailable");
    return getMarkSetting("strokeWidth");
  };

  const c = (
    <circle
      cx={mark.x}
      cy={mark.y}
      r={mark.style?.radius || getMarkSetting("defaultSize")}
      fill={fill()}
      stroke={isSelected ? getColor("selectedStroke") : getColor("stroke")}
      strokeWidth={strokeWidth()}
      strokeOpacity={getMarkSetting("opacityStroke")}
      fillOpacity={mark.style?.opacity || getMarkSetting("opacity")}
      onClick={(e) => {
        e.stopPropagation();
        dispatch(postMetric({ type: "spot_click", target: mark.id }));
        handleMarkerClick(mark);
      }}
      style={{
        cursor: !modes.includes("move") ? "pointer" : "unset",
        pointerEvents: "fill",
        animation: isSelected(mark) ? "pulse-spot 1s infinite" : "none",
      }}
    />
  );

  const t = image?.spotLabels && (
    <text
      x={mark.x}
      y={mark.y}
      textAnchor="middle"
      stroke="#ffffff"
      strokeWidth="2px"
      dy=".3em"
    >
      {mark?.spot || ""}
    </text>
  );
  return (
    <React.Fragment key={mark.id}>
      {t}
      {c}
    </React.Fragment>
  );
}

function SelectRectangle() {
  const dispatch = useDispatch();
  const select = useSelector((state) => state.app.selectRectangle);
  const x = select.startX;
  const y = select.startY;
  const x1 = select.endX;
  const y1 = select.endY;
  const height = Math.abs(y - y1);
  const width = Math.abs(x - x1);
  const visible = select.display;

  return (
    <rect
      x={Math.min(x, x1)}
      y={Math.min(y, y1)}
      width={visible ? width : 0}
      height={visible ? height : 0}
      strokeWidth="4"
      fill="none"
      stroke="black"
      onClick={() => dispatch(clearSelectionRectangle())}
      style={{ cursor: "pointer" }}
    ></rect>
  );
}

function Rulers() {
  const dispatch = useDispatch();
  const rulers = useSelector((state) => state.app.rulers);
  // const mouse = useSelector(state => state.app.mouse);

  return rulers.map((ruler, index) => {
    return (
      <line
        key={"ruler-" + index}
        x1={ruler.x0}
        y1={ruler.y0}
        x2={ruler.x1 || ruler.x0}
        y2={ruler.y1 || ruler.y0}
        strokeWidth="4"
        fill="none"
        stroke="white"
        // strokeDasharray="8"
        onClick={(e) => {
          e.stopPropagation();
          dispatch(deleteRuler(index));
        }}
        style={{ cursor: "pointer" }}
      ></line>
    );
  });
}

export { PointsOverlay };
