import { Assignment } from "@hamnvy/shared";
import { Typography } from "@mui/material";
import { TypographyProps } from "@mui/system";
import { format } from "date-fns";
import { FC } from "react";
import { useTranslation } from "react-i18next";

const AssignmentCreatedBy: FC<
  {
    assignment: Assignment;
    pre?: string;
    dateFormat?: string;
  } & TypographyProps
> = (props) => {
  const { assignment, pre, dateFormat = "yyyy-MM-dd", ...rest } = props;
  const creator = assignment.createdBy;
  const {t} = useTranslation();
  return (
    <Typography variant="overline" {...rest}>
      {pre && <span style={{ textTransform: "none" }}>{pre} </span>}
      {format(new Date(assignment.createdAt), dateFormat)}
      {creator && (
        <span style={{ textTransform: "none" }}>
          <span> </span>
          <>{t("common.by", "av")}</>
          <span> {creator?.name ?? creator.username}</span>
        </span>
      )}
    </Typography>
  );
};

export default AssignmentCreatedBy;
