import { IUser } from "@hamnvy/shared";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { AppLoading } from "../../Components/utils";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { api } from "../../services/api";
import { useStyles } from "../../styles/styles";
import { clearApiMessage } from "../app";
import { authenticate } from "../app/appApi";
import LoadingApp from "../app/LoadingApp";

const LoginForm: FC = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(true);
  const isLoggedIn: boolean =
    useAppSelector((state) => state.app.loggedIn) ?? false;
  const usr: IUser | null = useAppSelector((state) => state.app.user);
  const apiMessage: string =
    useAppSelector((state) => state.app.apiMessage) ?? "";
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const selectedOrg: any = useAppSelector((state) => state.app.org);

  useEffect(() => {
    // dummy request to force renewal of tokens
    if (localStorage.getItem("logged_in")) {
      api.get("/dummy").catch((err) => null);
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      // @ts-ignore
      if (usr?.isSuperUser) navigate("/admin");
      else {
        // @ts-ignore
        if (usr?.role?.rights?.includes("guestManager")) navigate("/home");
        else navigate("/overview");
      }
    }
  }, [isLoggedIn]);

  useEffect(() => {
    setError("");
  }, [apiMessage]);

  async function login() {
    setLoading(true);
    const result = await dispatch(
      // @ts-ignore
      authenticate({ org: selectedOrg?.id, user, password, remember })
    );
    if (result) {
      // @ts-ignore
      if (result?.error) {
        // @ts-ignore
        if (result.error.message.endsWith("404"))
          setError("Användaren kunde inte hittas.");
        // @ts-ignore
        else if (result.error.message.endsWith("401"))
          setError("Felaktiga inloggningsuppgifter.");
        else setError("Tjänsten ligger tillfälligt nere. Försök igen senare.");
        return;
      }
      setLoading(false);
      setError("");
    }
  }

  return (
    <>
      <Dialog open={!!apiMessage} onClose={() => {
        dispatch(clearApiMessage())
        setLoading(false)
      }}>
        <DialogTitle>{apiMessage}</DialogTitle>
      </Dialog>
      <Box sx={{ "& input": { background: "#fff" }, width: "100%" }}>
        <Typography variant="h5" sx={{ pb: 2 }}>
          Logga in | Hamnvy Admin
        </Typography>
        <form
          id="login"
          onSubmit={(e) => e.preventDefault()}
          style={{
            display: "grid",
            gap: 16,
            minWidth: 200,
            maxWidth: 300,
          }}
        >
          <div />
          <TextField
            variant="outlined"
            label="Användarnamn eller epost"
            value={user}
            onChange={(e) => setUser(e.target.value)}
            error={!!error}
          ></TextField>
          <TextField
            variant="outlined"
            label="Lösenord"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={!!error}
            slotProps={{
              htmlInput: { className: "password" }
            }}
          ></TextField>
          <Button
            type="submit"
            variant={user && password ? "contained" : "outlined"}
            color="primary"
            onClick={() => login()}
            sx={{ display: "flex", "justifyContent": "space-between", "alignItems": "center", gap: 2 }}
          >
            <span>{!error ? "Logga in" : "Försök igen"}</span>
            {isLoading ?
              <CircularProgress size="1rem" color="inherit" /> :
              <div />}
          </Button>
          {error && <Typography variant="body1">{error}</Typography>}
        </form>
      </Box>
    </>
  );
};

export default LoginForm;
