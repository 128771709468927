import { Restore } from "@mui/icons-material";
import {
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { isSameDay, isToday, subDays } from "date-fns";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BarChart from "../../Components/charts/bar/BarChart";
import { getLocalTime } from "../../services/utils/dates";
import theme from "../../styles/theme";
import { getPageVisits, getUsers } from "../app";
import { UserIconExpandable } from "../user/User";

export default function UserStats() {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState([]);
  const [user, setUser] = useState();
  const [page, setPage] = useState();
  const pageVisits = useSelector((s) => s.app.pageVisits) || [];
  const users = useSelector((s) => s.app.users) || [];
  useEffect(() => {
    dispatch(getUsers());
  }, []);

  const pageVisitsPerUser = (page) =>
    pageVisits.reduce(function (res, x) {
      if (!page || x.page === page) {
        res[x.user] = res[x.user] || [];
        res[x.user].push(x);
      }
      return res;
    }, Object.create(null));

  const pageVisitsPerUrl = (userId) =>
    pageVisits.reduce((res, x) => {
      if (!userId || x.user === userId) {
        res[x.page] = res[x.page] || [];
        res[x.page].push(x);
      }
      return res;
    }, Object.create(null));

  const isExpanded = (id) => expanded.includes(id);

  const [from, setFrom] = useState(subDays(new Date(), 7));
  const [to, setTo] = useState(new Date());

  useEffect(() => {
    dispatch(getPageVisits({ to, from }));
  }, [to, from]);

  const resetDates = () => {
    const today = new Date();
    setTo(today);
    setFrom(today);
  };

  return (
    <>
      <Typography variant="h4">Sidbesök</Typography>
      <div
        style={{
          display: "flex",
          gap: 8,
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <DatePicker
          autoOk
          value={from}
          placeholder="Från datum"
          onChange={(date) => setFrom(date)}
          maxDate={to}
          format="yyyy-MM-dd"
          variant="inline"
          slotProps={{ textField: { variant: 'outlined', size: 'small', sx: { width: 150 }} }}
        />
        <span>till</span>
        <DatePicker
          autoOk
          value={to}
          placeholder="Till datum"
          onChange={(date) => setTo(date)}
          minDate={from}
          format="yyyy-MM-dd"
          variant="inline"
          slotProps={{ textField: { variant: 'outlined', size: 'small', sx: { width: 150 }} }}
        />
        <Button
          startIcon={<Restore />}
          disabled={isToday(to) && isSameDay(to, from)}
          onClick={() => resetDates()}
        >
          Återställ
        </Button>
      </div>
      <Divider />
      <Typography variant="h5">Fördelning mellan användare</Typography>
      <div style={{ maxWidth: "500px" }}>
        <BarChart
          labels="visible"
          valueLabels
          type="left-right"
          bars={_.orderBy(
            Object.entries(pageVisitsPerUser(page)).map(([k, v]) => ({
              label: users.find((u) => u.id === k)?.username ?? k,
              value: v.length,
              onClick: () => setUser(user === k ? null : k),
              barStyle: {
                backgroundColor:
                  user === k
                    ? theme.palette.secondary.main
                    : theme.palette.primary.main,
              },
              labelStyle: {
                color: user ? (user === k ? "#000" : "#BBB") : "#000",
              },
              tooltip: user === k ? "Visa alla" : "",
            })),
            "value",
            "desc"
          )}
        />
      </div>
      <Divider />
      <Typography variant="h5">Fördelning mellan sidor</Typography>
      <div style={{ maxWidth: "500px" }}>
        <BarChart
          labels="visible"
          valueLabels
          type="left-right"
          bars={_.orderBy(
            Object.entries(pageVisitsPerUrl(user)).map(([siteUrl, visits]) => ({
              label: siteUrl,
              value: visits.length,
              onClick: () => setPage(page === siteUrl ? null : siteUrl),
              barStyle: {
                backgroundColor:
                  page === siteUrl
                    ? theme.palette.secondary.main
                    : theme.palette.primary.main,
              },
              labelStyle: {
                color: page ? (page === siteUrl ? "#000" : "#BBB") : "#000",
              },
              tooltip: page === siteUrl ? "Visa alla" : "",
            })),
            "value",
            "desc"
          )}
        />
      </div>
      <TableContainer>
        <Table size="small">
          <TableBody>
            {Object.keys(pageVisitsPerUser).map((userId) => {
              const user = users.find((x) => x.id === userId);
              const visits = pageVisitsPerUser[userId];

              return (
                <React.Fragment key={"visits-" + userId}>
                  <TableRow
                    style={{ userSelect: "none", cursor: "pointer" }}
                    onClick={() =>
                      isExpanded(userId)
                        ? setExpanded(expanded.filter((x) => x !== userId))
                        : setExpanded([...expanded, userId])
                    }
                  >
                    <TableCell colSpan={isExpanded(userId) ? 2 : 1}>
                      <UserIconExpandable
                        user={user}
                        expanded={isExpanded(userId)}
                      />
                    </TableCell>
                    {!isExpanded(userId) && (
                      <TableCell align="right">
                        <Typography variant="overline">Senast</Typography>
                        <Typography>
                          {getLocalTime(visits[0].createdAt)}
                        </Typography>
                      </TableCell>
                    )}
                    <TableCell align="right" width={30}>
                      <Typography variant="overline">Besök</Typography>
                      <Typography>{visits.length}</Typography>
                    </TableCell>
                  </TableRow>
                  {isExpanded(userId) &&
                    visits.map((visit) => (
                      <TableRow key={visit.id}>
                        <TableCell width={40}>
                          <Typography>
                            {getLocalTime(visit.createdAt)}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={2}>
                          <Typography>{visit.page}</Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
