import { patchMarks } from "../../features/app";
import store from "../../store";

export function closesPointOnRuler(mark, ruler) {

  function foot(A, B, P) {
    const AB = {
      x: B.x - A.x,
      y: B.y - A.y
    };
    const k = ((P.x - A.x) * AB.x + (P.y - A.y) * AB.y) / (AB.x * AB.x + AB.y * AB.y);
    return {
      x: A.x + k * AB.x,
      y: A.y + k * AB.y
    };
  }

  const x2 = ruler.x1;
  const x1 = ruler.x0;
  const y2 = ruler.y1;
  const y1 = ruler.y0;
  const x = mark.x;
  const y = mark.y;

  const A = { x: x1, y: y1 };
  const B = { x: x2, y: y2 };
  const P = { x, y };

  return foot(A, B, P)
}

export function distanceBetweenMarkAndRuler(mark, ruler) {
  const x2 = ruler.x1;
  const x1 = ruler.x0;
  const y2 = ruler.y1;
  const y1 = ruler.y0;
  const x = mark.x;
  const y = mark.y;

  const denominator = Math.abs((x2 - x1) * (y1 - y) - (x1 - x) * (y2 - y1))
  const numerator = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2)

  const distance = denominator / numerator
  // if (distance < 50)
  //   console.log({ spot: mark?.spot, x: mark.x, y: mark.y, distance, ruler })
  return distance;
}

export function isMarkInlineWithRuler(mark, ruler) {
  const x2 = Math.max(ruler.x1, ruler.x0);
  const x1 = Math.min(ruler.x1, ruler.x0);
  const y2 = Math.max(ruler.y1, ruler.y0);
  const y1 = Math.min(ruler.y1, ruler.y0);
  const x = mark.x;
  const y = mark.y;

  // dominant riktning
  if (x2 - x1 > y2 - y1) return (x > x1 && x < x2)
  return (y > y1 && y < y2)
}


export function isMarkCloseToRuler(mark, rulers) {
  let isClose = null;
  const rulerSensitivity = store.getState().app.rulerSensitivity
  rulers.forEach((ruler) => {
    if (isMarkInlineWithRuler(mark, ruler) && distanceBetweenMarkAndRuler(mark, ruler) <= rulerSensitivity) {
      isClose = ruler
    }
  })
  return isClose;
}

export function snapMarksToCloseRuler(marks, rulers) {
  const newMarks = marks.map(m => {
    const ruler = isMarkCloseToRuler(m, rulers);
    if (ruler) {
      return { id: m.id, ...closesPointOnRuler(m, ruler) }
    }
    return null
  }).filter(x => x)
  store.dispatch(patchMarks({ marks: newMarks }))
}