import { FilePreview } from "@hamnvy/shared";
import {
  Delete,
  Download,
  FileDownload,
  InsertDriveFile,
  Visibility,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  IconButton,
  SxProps,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import { useSnackbar } from "notistack";
import { FC, Fragment, useEffect, useState } from "react";
import { DialogWithCloseButton } from "../../Components/Dialog";
import ActionDialog from "../../Components/utils/ActionDialog";
import { AttachmentApi } from "../../services/attachmentApi";

interface Props {
  isEditing?: boolean;
  attachments: FilePreview[];
  afterFileDeletion?: (attachmentId: string) => void;
  sx?: SxProps;
}

const ImagePreview: FC<{ number?: number, image: FilePreview | null, onClose: () => void }> = ({ number, image, onClose }) => {

  const [viewOriginal, setViewOriginal] = useState(false);

  useEffect(() => {
    setViewOriginal(false);
  }, [image])

  return <DialogWithCloseButton
    title={<Box sx={{ alignItems: "center", display: "flex" }}><span>Bild {number}</span>
      {viewOriginal ? <Button
        sx={{ pl: 2 }}
        color="secondary"
        startIcon={<Download />}
        onClick={() => window.open(image?.src)}
      >
        Ladda ned
      </Button> :
        <Button
          sx={{ pl: 2, textTransform: "none" }}
          color="secondary"
          startIcon={<Visibility />}
          onClick={() => setViewOriginal(true)}
        >
          Orginal {image ? ` (${((image.summary?.size ?? 0) / 1024 / 1024).toFixed(0)} MB)` : null}
        </Button>}
    </Box>}
    open={Boolean(image)}
    onClose={onClose}
  >
    <img src={viewOriginal ? image?.src : (image?.thumbnailUrl ?? image?.src)} />
  </DialogWithCloseButton>


}

const GroupedAttachmentThumbnails: FC<Props> = (props) => {
  const { isEditing, attachments, afterFileDeletion, sx } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [isDeleting, setDeleting] = useState<FilePreview | null>(null);
  const theme = useTheme();
  const groupedAttachments = attachments.reduce(
    (res: { images: FilePreview[]; files: FilePreview[] }, attachment) => {
      if (attachment)
        return attachment?.summary?.contentType?.startsWith("image/")
          ? { ...res, images: [...res.images, attachment] }
          : { ...res, files: [...res.files, attachment] };
      else return res;
    },
    { images: [], files: [] }
  );


  const openDeleteDialog = (target: FilePreview) => {
    setDeleting(target);
  };

  const handleDelete = async () => {
    try {
      if (isDeleting) {
        await AttachmentApi.delete(isDeleting.summary.id);
        afterFileDeletion?.(isDeleting.summary.id);
        setDeleting(null);
        enqueueSnackbar("Bilaga borttagen", { variant: "success" });
      }
    } catch (err) {
      console.log(err);
      enqueueSnackbar("fel uppstod", { variant: "error" });
    }
  };

  const [previewImage, setPreviewImage] = useState<FilePreview | null>(null);

  return (
    <>
      <ImagePreview image={previewImage} onClose={() => setPreviewImage(null)} number={(attachments.filter(x => x.summary.contentType?.startsWith("image")).findIndex(x => previewImage?.summary?.id === x.summary.id) + 1)} />
      {attachments.length > 0 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: 2,
            py: 2,
            ...sx,
          }}
        >
          <ActionDialog
            title="Ta bort bilaga?"
            open={!!isDeleting}
            onClose={() => setDeleting(null)}
            onConfirm={handleDelete}
          />
          {groupedAttachments.images.length > 0 && (
            <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
              {groupedAttachments.images.map((img) => (
                <Fragment key={img.summary.id}>{img.src ?
                  <Card
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "fit-content",
                      backgroundColor: blue[50],
                    }}
                    style={{ cursor: "pointer" }}
                    onClick={() => setPreviewImage(img)}
                  >
                    {img?.thumbnailUrl ?
                      <img
                        src={img?.thumbnailUrl}
                        height="auto"
                        width="100%"
                      /> : img?.src ? <img
                        src={img?.src}
                        height="auto"
                        width="100%"
                      /> : <svg style={{ padding: 8 }} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M13 9h5.5L13 3.5zM6 2h8l6 6v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V4c0-1.11.89-2 2-2m0 18h12v-8l-4 4l-2-2zM8 9a2 2 0 0 0-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2"></path></svg>}
                    {isEditing && (
                      <Box
                        sx={{ display: "flex", alignItems: "center", px: 0.5 }}
                      >
                        <IconButton
                          size="small"
                          onClick={() => openDeleteDialog(img)}
                        >
                          <Delete />
                        </IconButton>
                      </Box>
                    )}
                  </Card> : <Tooltip
                    title="Bild kunde inte laddas.">
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M13.09 20H6l6-6l1.88 1.88c.62-.98 1.48-1.78 2.52-2.28L18 12v1.09c.33-.05.66-.09 1-.09s.67.04 1 .09V8l-6-6H6c-1.11 0-2 .89-2 2v16a2 2 0 0 0 2 2h7.81c-.35-.61-.6-1.28-.72-2M13 3.5L18.5 9H13zM8 9a2 2 0 1 1 0 4c-1.11 0-2-.89-2-2s.9-2 2-2m14.54 7.88L20.41 19l2.13 2.12l-1.42 1.42L19 20.41l-2.12 2.13l-1.41-1.42L17.59 19l-2.12-2.12l1.41-1.41L19 17.59l2.12-2.12z"></path></svg></Tooltip>
                }
                </Fragment>))}
            </Box>
          )}
          {groupedAttachments.files.length > 0 && (
            <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
              {groupedAttachments.files.map((file) => (
                <Fragment
                  key={file.summary.id}
                >
                  {file?.src ?
                    <Card
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "fit-content",
                        backgroundColor: blue[50],
                        gap: 1,
                        px: 1,
                        py: 1,
                        userSelect: "none",
                        cursor: "pointer",
                        ...(!isEditing && {
                          ":hover": {
                            textDecoration: "underline",
                            color: theme.palette.secondary.main,
                          },
                        }),
                      }}
                      {...(!isEditing && { onClick: () => window.open(file.src) })}
                    >
                      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                        <InsertDriveFile fontSize="small" color="primary" />
                        <Typography>{file.summary?.filename}</Typography>
                      </Box>
                      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                        <Tooltip title="Ladda ner">
                          <IconButton
                            size="small"
                            color="secondary"
                            onClick={() => window.open(file.src)}
                          >
                            <FileDownload />
                          </IconButton>
                        </Tooltip>
                        {isEditing && (
                          <IconButton
                            size="small"
                            onClick={() => openDeleteDialog(file)}
                          >
                            <Delete />
                          </IconButton>
                        )}
                      </Box>
                    </Card> : <Tooltip title="Filen kunde inte hittas.">
                      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M21.12 22.54L19 20.41l-2.12 2.13l-1.41-1.42L17.59 19l-2.12-2.12l1.41-1.41L19 17.59l2.12-2.12l1.42 1.41L20.41 19l2.13 2.12zM14 2H6c-1.11 0-2 .89-2 2v16c0 1.11.89 2 2 2h7.81c-.53-.91-.81-1.95-.81-3c0-3.31 2.69-6 6-6c.34 0 .67.03 1 .08V8zm-1 7V3.5L18.5 9z"></path></svg>
                    </Tooltip>}
                </Fragment>
              ))}
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default GroupedAttachmentThumbnails;
