import { createSlice } from "@reduxjs/toolkit";
import { defaultMatchFromType } from "./filters";

export const dataSlice = createSlice({
  name: "data",
  initialState: {
    dataFilters: [],
    importFilters: [],
    searchQuery: "",
    displayOnlyRowsOnView: true,
  },
  reducers: {
    // DATA FILTERS
    addDataFilter: (state, { payload }) => {
      const { field } = payload;
      const filter = { field, active: false, value: null, match: defaultMatchFromType(field.type) };
      state.dataFilters.push(filter);
    },
    toggleDataFilter: (state, { payload }) => {
      // payload = index
      state.dataFilters[payload].active = !state.dataFilters[payload].active;
    },
    updateDataFilter: (state, { payload }) => {
      const { index, ...rest } = payload;
      state.dataFilters[index] = { ...state.dataFilters[index], ...rest };
    },
    removeDataFilter: (state, { payload }) => {
      const { index } = payload;
      state.dataFilters = [...state.dataFilters.slice(0, index), ...state.dataFilters.slice(index + 1)]
    },

    // IMPORT FILTERS
    addImportFilter: (state, { payload }) => {
      const { field } = payload;
      const filter = { field, active: false, value: '', match: defaultMatchFromType(field.type) };
      state.importFilters.push(filter);
    },
    toggleImportFilter: (state, { payload }) => {
      // payload = index
      state.importFilters[payload].active = !state.importFilters[payload].active;
    },
    updateImportFilter: (state, { payload }) => {
      const { index, ...rest } = payload;
      state.importFilters[index] = { ...state.importFilters[index], ...rest };
    },
    removeImportFilter: (state, { payload }) => {
      const { index } = payload;
      state.importFilters.splice(index);
    },
    setSearchQuery: (state, {payload}) => {
      state.searchQuery = payload;
    },
    clearSearchQuery: (state) => {
      state.searchQuery = "";
    },
    setDisplayOnlyRowsOnView: (state, {payload}) => {
      state.displayOnlyRowsOnView = payload;
    },
    clearFilters: (state) => {
      state.dataFilters = []
      state.importFilters = []
    },
    loadFilterSet: (state, { payload }) => {
      state.dataFilters = payload.dataFilters;
      state.importFilters = payload.importFilters;
    }
  },
  extraReducers: (builder) => {

  },
});

export default dataSlice.reducer;
