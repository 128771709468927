import { Container } from "@mui/material";
import Header from "../main/Header";
import { UpdatePassword } from "./UpdatePassword";

export function User() {
  return (
    <>
      <Header />
      <Container>
        <UpdatePassword />
      </Container>
    </>
  );
}
