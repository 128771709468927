import { IUser } from "@hamnvy/shared";
import { Button } from "@mui/material";
import { FC, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import ActionDialog from "../../../Components/utils/ActionDialog";
import { useAppDispatch } from "../../../hooks";
import { updateUser } from "../../app";

interface Props {
  user: IUser;
}

const DisableUserButton: FC<Props> = (props) => {
  const { user } = props;
  const dispatch = useAppDispatch();
  const [dialog, setDialog] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleAction = async () => {
    try {
      setError(false);
      // @ts-expect-error
      await dispatch(updateUser({ id: user.id, disabled: !user.disabled }));
      setDialog(false);
    } catch (err) {
      setError(true);
    }
  };

  return (
    <>
      <ActionDialog
        open={dialog}
        onClose={() => setDialog(false)}
        onConfirm={handleAction}
        error={
          error && <>
            {t("user.update_error", "Kunde inte genomföra ändringen")}.
          </>
        }
        title={
          <>
            {user.disabled ? (
              t("user.enable_title", "Aktivera kontot")
            ) : (
              t("user.disable_title", "Inaktivera kontot"))}
            ?
          </>
        }
      />
      <Button
        color={user.disabled ? "success" : "warning"}
        variant="outlined"
        onClick={() => setDialog(true)}
      >
        {user.disabled ? (
          t("user.enable_button", "Aktivera")
        ) : (
          t("user.disable_button", "Inaktivera")
        )}
      </Button>
    </>
  );
};

export default DisableUserButton;
