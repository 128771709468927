import {
  AccountBox,
  AccountCircle,
  Comment,
  Construction,
  DirectionsBoat,
  Dvr,
  ExitToApp,
  Home,
  Info,
  Payment,
  PlaylistAddCheck,
  Public,
  Room,
  Settings,
  Storage,
  Style,
  SupervisorAccount,
} from "@mui/icons-material";
import {
  Badge,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { userHasRight, userIsAdmin } from "../../../services/utils/userRights";
import theme from "../../../styles/theme";
import { logout } from "../../app";

function MenuItems({ items, onItemClick, text = false }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const signOut = () => {
    navigate("/login");
    dispatch(logout());
  };

  return (
    <List>
      {items.map((item, index) => (
        <Tooltip title={!text ? item.title : undefined}
          key={"menu-link-" + item.path + index}
          placement="right"

        >
          <ListItemButton
            onClick={() => onItemClick(item.path)}
          >
            <ListItemIcon
              sx={{ minWidth: "0", color: location.pathname === item.path ? theme.palette.primary.main : undefined }}
            >{item.icon}</ListItemIcon>
            {text && <ListItemText primary={item.title} sx={{ px: 2 }} />}
          </ListItemButton>
        </Tooltip>
      ))}
      <Tooltip title={!text ? "Logga ut" : undefined}
        placement="right"
      >
        <ListItemButton onClick={() => signOut()}>
          <ListItemIcon
            sx={{ minWidth: "0" }}
          >
            <ExitToApp />
          </ListItemIcon>
          {text && <ListItemText primary="Logga ut" sx={{ px: 2 }} />}
        </ListItemButton>
      </Tooltip>
    </List>
  );
}

function MenuTitle({ children = "" }) {
  return (
    <ListItem>
      <ListItemText primary={children}></ListItemText>
    </ListItem>
  );
}

function AdminSubmenu({ setMobileOpen }) {
  const navigate = useNavigate();

  const adminMenu = [
    { title: "Användare", path: "/admin/users", icon: <AccountCircle /> },
    { title: "Data", path: "/admin/data", icon: <Info /> },
    {
      title: "Organisationer",
      path: "/admin/organisations",
      icon: <SupervisorAccount />,
    },
    // { title: "Vylogik", path: "/admin/logic", icon: <Build /> },
    // { title: "Ändpunkter", path: "/admin/endpoints", icon: <Storage /> },
    { title: "Estetik", path: "/admin/style", icon: <Style /> },
  ];

  return (
    <>
      <Divider />
      <MenuTitle>Administratör</MenuTitle>
      <MenuItems
        items={adminMenu}
        onItemClick={(path) => {
          setMobileOpen(false);
          navigate(path);
        }}
      />
    </>
  );
}

function CRMSubmenu({ setMobileOpen }) {
  const navigate = useNavigate();

  const items = [
    { title: "Översikt", path: "/overview", icon: <Home /> },
    { title: "Information", path: "/crm", icon: <Info /> },
    { title: "Kunder", path: "/crm/customers", icon: <SupervisorAccount /> },
    { title: "Båtar", path: "/crm/boats", icon: <DirectionsBoat /> },
    { title: "Platser", path: "/crm/spots", icon: <Room /> },
    userHasRight("advancedFunctions") && {
      title: "Inställningar",
      path: "/crm/settings",
      icon: <Settings />,
    },
  ];

  return (
    <>
      <MenuItems
        items={items.filter((x) => x)}
        onItemClick={(path) => {
          setMobileOpen(false);
          navigate(path);
        }}
      />
    </>
  );
}

function BASSubmenu({ setMobileOpen, text }) {
  const navigate = useNavigate();
  const todos = useSelector((s) => s.app.todos.flipSigns) || [];

  const items = [
    {
      title: "Att göra",
      path: "/todo",
      icon: (
        <Badge badgeContent={todos.length} color="error">
          <PlaylistAddCheck />
        </Badge>
      ),
    },
    { title: "Översikt", path: "/overview", icon: <Home /> },
    { title: "Gästplatser", path: "/spots/available", icon: <Public /> },
    { title: "Betalningskontroll", path: "/payments/check", icon: <Payment /> },
    userHasRight("messages") && {
      title: "Anteckningar",
      path: "/notes",
      icon: <Comment />,
    },
    userHasRight("readwrite") && {
      title: "BAS data",
      path: "/bas/data",
      icon: <Storage />,
    },
    userHasRight("readwrite") && {
      title: "Aktivitet",
      path: "/activity",
      icon: <Dvr />,
    },
    {
      title: "Felanmälningar",
      path: "/assignments/failure",
      icon: <Construction />,
    },
    userIsAdmin() && {
      title: "Användare",
      path: "/users",
      icon: <AccountBox />,
    },
  ];

  return (
    <>
      <MenuItems
        items={items.filter((x) => x)}
        onItemClick={(path) => {
          setMobileOpen(false);
          navigate(path);
        }}
        text={Boolean(text)}
      />
    </>
  );
}

export { MenuItems, MenuTitle, AdminSubmenu, CRMSubmenu, BASSubmenu };
