import { StorageRounded } from "@mui/icons-material";
import { Button, Container, useMediaQuery, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { lazy, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FloatingFooter from "../../Components/FloatingFooter";
import { userHasRight } from "../../services/utils/userRights";
import { useStyles } from "../../styles/styles";
import {
  deselectAllMarks,
  getAllMarks,
  getBasImport,
  getBasUploads,
  getImages,
  getMarks,
  getSpots,
  postMetric,
  selectImage,
  selectMark,
  selectRows,
} from "../app";
import { getMasterImage } from "../app/appSelectors";
import MapView from "../map/MapView/MapView";
import SearchSpot from "../map/SearchSpot";
import Header from "./Header";
import PageHeader from "./PageHeader";
import { SimpleOverviewSearch } from "./SimpleOverviewSearch";
const BasData = lazy(() => import("../data/DataView/BasData"));

export default function Overview() {
  const classes = useStyles();
  const masterImage = useSelector((state) => getMasterImage(state));
  const image = useSelector((state) => state.app.image);
  const org = useSelector((state) => state.app.org);
  const dispatch = useDispatch();
  const [openData, setOpenData] = useState(false);
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("sm"));

  async function getData() {
    const result = await dispatch(getBasUploads());
    if (result) {
      const uploads = result?.payload?.uploads;
      const indexOfLatestWithoutErrors = uploads && uploads.length > 0 ? uploads.findLastIndex(x => x.errors.length === 0) : -1;
      const latestWithoutErrors = indexOfLatestWithoutErrors !== -1 ? uploads[indexOfLatestWithoutErrors] : null;
      if (latestWithoutErrors) {
        dispatch(getBasImport({ id: latestWithoutErrors.id }));
      }
    }
  }

  // mount effect
  useEffect(() => {
    dispatch(getImages());
    if (org?.hamnvyCRM) dispatch(getSpots());
    if (org?.usingBAS) getData();
    dispatch(getAllMarks());
  }, []);

  useEffect(() => {
    if (masterImage) {
      dispatch(selectImage({ image: masterImage }));
      dispatch(getMarks({ image: masterImage.id }));
    }
  }, [masterImage]);

  return (
    <>
      <Header>
        <PageHeader title={org?.orgShortName ?? "Hamnvy för HamnX"}>
          <SelectVisibleComponents />
        </PageHeader>
      </Header>
      <Container className={classes.root} maxWidth="xl">
        <MapView />
      </Container>
      {!isMobile && userHasRight("data") && org?.usingBAS && (
        <FloatingFooter open={openData} setOpen={setOpenData}>
          <BasData />
        </FloatingFooter>
      )}
    </>
  );

  function SelectVisibleComponents() {
    const org = useSelector((state) => state.app.org);
    const marks = useSelector((state) => state.app.marks);
    const basImport = useSelector((s) => s.app.import);


    const handleSpotSearch = (spot) => {
      dispatch(deselectAllMarks());
      // do not send metric on empty search
      if (spot) {
        dispatch(postMetric({ type: "spot_search", target: spot }));
        if (org?.usingBAS) {
          const row = basImport.find(x => x.spot === spot);
          dispatch(selectRows(row ? [row] : []));
        } else {
          const mark = marks.find((x) => x.spot === spot);
          if (mark) dispatch(selectMark(mark));
        }
      }
    };
    return (
      <Box
        sx={{
          display: "flex",
          gap: 2,
          "& .MuiInputBase-root": { backgroundColor: "white !important" },
          width: "max-content",
        }}
      >
        {image && <SearchSpot onChange={handleSpotSearch} />}
        {!isMobile && image && org?.usingBAS && userHasRight("data") && (
          <Button
            fullWidth
            size="small"
            startIcon={<StorageRounded />}
            onClick={() => setOpenData(!openData)}
            sx={{}}
          >
            {!openData ? "Visa" : "Stäng"} tabell
          </Button>
        )}
      </Box>
    );
  }
}
