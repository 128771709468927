import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
import { isFulfilled } from "@reduxjs/toolkit";
import { useSnackbar } from "notistack";
import { ChangeEvent, FC, useState } from "react";
import { CloseButton } from "../../Components/utils";
import { basFileUpload, updateBasUpload } from "../app";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { api } from "../../services/api";

interface Props {
  open: boolean;
  onClose?: () => void;
}

const BasFileUpload: FC<Props> = ({ open, onClose = null }) => {
  const dispatch = useAppDispatch();
  const [file, setFile] = useState<File | null>(null);
  const [fileBase64, setFileBase64] = useState<string | null>(null);
  const org: any = useAppSelector((state) => state.app.org);
  const { enqueueSnackbar } = useSnackbar();

  async function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const files = event.target.files;
    if (files && files.length > 0) {
      setFile(files[0]);
      const reader = new FileReader();
      // get file content in base64 format
      // and store it in the state below
      reader.onload = function (event) {
        if (event.target && event.target.result) {
          setFileBase64(event.target.result.toString());
        }
      };
      await reader.readAsDataURL(files[0]);
    }
  }

  async function onSubmit() {
    try {
      if (!file || !fileBase64) return;
      const data = new FormData();

      const blob = await fetch(fileBase64).then((res) => res.blob());
      data.append("file", blob, file.name);
      const getPresignedUrlRequest = await api.post("/bas/upload_url")
      const { url, filename } = getPresignedUrlRequest.data;
      if (!url) {
        throw new Error("Could not get presigned URL for upload destination");
      }
      const res = await fetch(url, {
        method: "put",
        body: file,
        headers: {
          "Content-Disposition": "attachment; filename=" + file.name,
        },
      });
      if (!res.ok) {
        throw new Error("Upload failed");
      }

      // @ts-expect-error
      const result = await dispatch(basFileUpload({ filename, contentType: file.type, originalFilename: file.name, length: file.size }));
      if (isFulfilled(result)) {
        enqueueSnackbar("Filuppladdningen lyckades",
          { variant: "success" }
        );
      }
      setFile(null);
      setFileBase64(null);
      if (onClose) onClose();
    } catch (err) {
      alert("fel uppstod");
      console.log(err);
    }
  }

  const accept =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

  return (
    open && (
      <Card id="file-upload">
        <CardHeader
          title="Filuppladdning"
          action={onClose && CloseButton(() => onClose())}
        />
        <CardContent>
          {!fileBase64 && (
            <Typography variant="body1">
              För att importera data från BAS
            </Typography>
          )}
          {file && file?.name && (
            <Typography variant="body1">
              Vald fil: <b>{file.name}</b>
            </Typography>
          )}
        </CardContent>
        <CardActions>
          <input
            id="file-upload-btn"
            type="file"
            accept={accept}
            hidden
            onChange={handleChange}
          />
          <label htmlFor="file-upload-btn">
            <Button
              variant={fileBase64 ? "text" : "contained"}
              color="primary"
              component="span"
            >
              Välj en {fileBase64 && "ny"} fil
            </Button>
          </label>
          <Button
            variant={fileBase64 ? "contained" : "text"}
            color={fileBase64 ? "primary" : undefined}
            type="submit"
            onClick={onSubmit}
            disabled={!fileBase64}
          >
            Ladda upp
          </Button>
        </CardActions>
      </Card>
    )
  );
}

export { BasFileUpload };
