import { Comment } from "@mui/icons-material";
import { Button } from "@mui/material";
import { FC, useState } from "react";
import AssignmentCommentForm from "./AssignmentCommentForm";
import AssignmentComments from "./AssignmentComments";

interface Props {}

const AssignmentCommentary: FC<Props> = (props) => {
  const [isCommenting, setCommenting] = useState<boolean>(false);
  return (
    <>
      <AssignmentComments />
      {/* todo: if commenting is not disabled */}
      {!isCommenting && (
        <Button
          variant="contained"
          color="secondary"
          startIcon={<Comment />}
          onClick={() => setCommenting(true)}
        >
          Kommentera
        </Button>
      )}
      {isCommenting && (
        <AssignmentCommentForm onClose={() => setCommenting(false)} />
      )}
    </>
  );
};

export default AssignmentCommentary;
