import { useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { FC, ReactNode } from "react";

interface Props {
  children: ReactNode;
}

const LoginSideContainer: FC<Props> = (props) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        background: theme.palette.primary.main,
        p: 4,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <>{props.children}</>
    </Box>
  );
};

export default LoginSideContainer;
