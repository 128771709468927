import { BasRow, TimeLimitedGuestSpot } from "@hamnvy/shared";
import {
  DialogContent,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { isFulfilled } from "@reduxjs/toolkit";
import { useSnackbar } from "notistack";
import { FC, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { DialogWithCloseButton } from "../../../Components/Dialog";
import {
  ConfirmDialog,
  DeleteButton,
  EditButton,
} from "../../../Components/utils";
import CommentButton from "../../../Components/utils/CommentButton";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { justDateString } from "../../../services/utils/dates";
import { deleteLimitedGuestSpot } from "../../app";
import { StickyHeadTable } from "../../data/Tables/Tables";
import SpotChip from "../common/SpotChip";
import SpotPreviewContent from "../common/SpotPreviewContent";
import LimitedGuestSpotForm from "../forms/LimitedGuestSpotForm";

interface Props {}

const TimeLimitedSpotsTable: FC<Props> = (props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [isEditing, setEditing] = useState<string | null>(null);
  const [isDeleting, setDeleting] = useState<string | null>(null);
  const [note, setNote] = useState<string | null>(null);

  const spots: TimeLimitedGuestSpot[] = useAppSelector(
    (s) => s.app.guestSpots.limited
  );
  const basImport: BasRow[] = useAppSelector((s) => s.app.import);
  const notMobile = useMediaQuery(theme.breakpoints.up("sm"));
  const fDate = (date: string) =>
    notMobile ? justDateString(date) : justDateString(date).substring(2, 10);

  const handleDelete = async () => {
    // @ts-expect-error
    const result = await dispatch(deleteLimitedGuestSpot(isDeleting));
    if (isFulfilled(result)) {
      enqueueSnackbar(
        t("timeLimitedGuestSpot.delete_success", {
          defaultValue: "Vald tidsbestämd platstillgänglighet borttagen",
        }),
        { variant: "success" }
      );
    }
  };

  return (
    <>
      <ConfirmDialog
        title={t("timeLimitedGuestSpot.delete_confirm_title", {
          defaultValue: "Ta bort vald ledighetspost?",
        })}
        open={isDeleting}
        setOpen={setDeleting}
        onConfirm={handleDelete}
      />
      <DialogWithCloseButton
        title={t("timeLimitedGuestSpot.note_title", {
          defaultValue: "Notering",
        })}
        open={!!note}
        onClose={() => setNote(null)}
      >
        <DialogContent>
          <Typography variant="body1">{note}</Typography>
        </DialogContent>
      </DialogWithCloseButton>
      <DialogWithCloseButton
        title={t("timeLimitedGuestSpot.edit_title", {
          defaultValue: "Redigera tillgänglighet",
        })}
        open={isEditing}
        onClose={() => setEditing(null)}
      >
        <DialogContent>
          <LimitedGuestSpotForm
            published={true}
            data={isEditing ? spots.find(({ id }) => id === isEditing) : null}
            onSuccess={() => setEditing(null)}
          />
        </DialogContent>
      </DialogWithCloseButton>

      {/* @ts-expect-error */}
      <StickyHeadTable
        stickyHeaderRow={
          <>
            <TableCell>
              {t("timeLimitedGuestSpot.spot", "Plats")}
            </TableCell>
            <TableCell>
              {t("timeLimitedGuestSpot.from", "Från")}
            </TableCell>
            <TableCell>
              {t("timeLimitedGuestSpot.to", "Till")}
            </TableCell>
            <TableCell></TableCell>
          </>
        }
        emptyText={t("timeLimitedGuestSpot.empty_text", {
          defaultValue: "Inga fasta platser är lediga vid angivet datum.",
        })}
        size="small"
      >
        {spots.map((s) => (
          <TableRow key={"row-" + s.id}>
            <TableCell padding="none" sx={{ pl: 1, py: 1 }}>
              <SpotChip
                spotNumber={s.spotNumber}
                getTooltipLabel={(spotNumber) =>
                  basImport.find((x) => x.spot === spotNumber)?.name || ""
                }
                dialogContent={<SpotPreviewContent spotNumber={s.spotNumber} />}
              />
            </TableCell>
            <TableCell>{fDate(s.fromDate)}</TableCell>
            <TableCell>{fDate(s.toDate)}</TableCell>
            <TableCell align="right" padding="none">
              <div style={{ display: "flex", justifyContent: "end" }}>
                {s?.note && (
                  <CommentButton
                    title="Notering"
                    onClick={() => setNote(s?.note ?? null)}
                  />
                )}
                {EditButton(() => setEditing(s.id))}
                {notMobile && DeleteButton(() => setDeleting(s.id))}
              </div>
            </TableCell>
          </TableRow>
        ))}
      </StickyHeadTable>
    </>
  );
};

export default TimeLimitedSpotsTable;
