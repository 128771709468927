import store from "../store";

export const defaultColors = [
  { title: "Plats ytterkant", field: 'stroke', value: '#ffffff' },
  { title: "Markerade platser", field: 'selectedFill', value: '#004c8c' },
  { title: "Markerade platser ytterkant", field: 'selectedStroke', value: '#ffffff' },
  { title: "Plats nära linjal", field: 'fillCloseToRuler', value: '#1565c0' },
  { title: "Tillgänglig plats", field: 'fillSpotAvailable', value: '#1565c0' },
  { title: "Tillgänglig plats ytterkant", field: 'strokeSpotAvailable', value: '#ffffff' },
  { title: "Upptagen plats", field: 'fillSpotOccupied', value: '#004c8c' },
];
const orgColors = [{ title: "Plats ytterkant", field: 'stroke', value: '#ffffff' },
{ title: "Markerade platser", field: 'selectedFill', value: '#004c8c' },
{ title: "Markerade platser ytterkant", field: 'selectedStroke', value: '#ffffff' },
{ title: "Plats nära linjal", field: 'fillCloseToRuler', value: '#1565c0' },
{ title: "Tillgänglig plats", field: 'fillSpotAvailable', value: 'green' },
{ title: "Tillgänglig plats ytterkant", field: 'strokeSpotAvailable', value: '#ffffff' },
{ title: "Upptagen plats", field: 'fillSpotOccupied', value: '#004c8c' }];

export const colorPalette = ['#66C7F4', '#FFE66D', '#2CDA9D', '#DF2935', '#004C8C', '#1565C0', '#A8530A', '#F59340', '#A81202', '#f5f5f5'];
export function overrideDefaultColorsWithSettingsCollection(settingsCollection) {
  const colorsFromCollection = settingsCollection.find(x => x.type === 'colors');
  if (colorsFromCollection) {
    return defaultColors.map(def => {
      const colorFromCollection = colorsFromCollection.settings.find(x => x.field === def.field);
      if (colorFromCollection)
        return colorFromCollection;
      return def;
    });
  }
  return orgColors;
}

export const getColor = (field) => {
  const orgSettings = store.getState().app.orgSettings;
  const colors = overrideDefaultColorsWithSettingsCollection(orgSettings)
  return colors.find(c => c.field === field).value;
}

