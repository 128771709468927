import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { FC, ReactNode } from "react";

interface Props {
  title: ReactNode;
  open: boolean;
  text?: string;
  error?: ReactNode;
  yes?: string;
  no?: string;
  onConfirm: () => void;
  onClose: () => void;
}

const ActionDialog: FC<Props> = (props) => {
  const { title, error, text, yes, no, onConfirm, open, onClose } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      open={!!open}
      onClose={handleClose}
      aria-labelledby="action-dialog-title"
      aria-describedby="action-dialog-description"
      closeAfterTransition={false}
    >
      <DialogTitle id="action-dialog-title"><>{title}</></DialogTitle>
      {(text || !!error) && (
        <DialogContent>
          {text && (
            <DialogContentText id="action-dialog-description">
              {text}
            </DialogContentText>
          )}
          {!!error && (
            <DialogContentText id="action-dialog-error" color="red">
              <>{error}</>
            </DialogContentText>
          )}
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {no || "Nej"}
        </Button>
        <Button
          onClick={() => {
            onConfirm();
          }}
          color="primary"
          autoFocus
        >
          {yes || "Ja"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ActionDialog;
