import { Delete } from "@mui/icons-material";
import { Autocomplete, Button, DialogContent, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { isFulfilled } from "@reduxjs/toolkit";
import { format, isValid, parseISO } from "date-fns";
import { useSnackbar } from "notistack";
import { FC, useState } from "react";
import { DialogWithCloseButton } from "../../../Components/Dialog";
import { ConfirmDialog } from "../../../Components/utils";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  deleteLimitedGuestSpot,
  patchLimitedGuestSpot,
  postLimitedGuestSpot,
} from "../../app";

interface Props {
  published?: boolean;
  data?: { [key: string]: any } | null;
  onSuccess: () => void;
  disabledSpot?: boolean;
}

const LimitedGuestSpotForm: FC<Props> = (props) => {
  const {
    published = false,
    data = null,
    onSuccess,
    disabledSpot = false,
  } = props;
  const basImport = useAppSelector((s) => s.app.import);
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [spot, setSpot] = useState(
    (data &&
      (basImport.find(({ spot }) => spot === data?.spotNumber) ||
        data.spotNumber)) ||
    null
  );
  const [fromDate, setFromDate] = useState(data?.fromDate || null);
  const [toDate, setToDate] = useState(data?.toDate || null);
  const [note, setNote] = useState(data?.note || "");
  const [error, setError] = useState(null);
  const [isDeleting, setDeleting] = useState(false);

  const publish = async () => {
    const formData = {
      spotNumber: typeof spot === "string" ? spot : spot.spot,
      fromDate,
      toDate,
      note,
    };
    // console.log(formData);
    let result;
    if (published) {
      Object.keys(formData).forEach((key) => {
        // @ts-expect-error
        if (formData[key] === data[key]) delete formData[key];
      });
        // @ts-expect-error
      const payload = { id: data.id, ...formData };
      // @ts-expect-error
      result = await dispatch(patchLimitedGuestSpot(payload));
      if (result.meta.requestStatus === "fulfilled") {
        onSuccess();
        enqueueSnackbar(`Tillgänglighet för plats ${spot.spot} nu uppdaterad`, {
          variant: "success",
        });
      } else if (result.meta.rejectedWithValue) {
        setError(result.payload);
      }
    } else {
      // @ts-expect-error
      result = await dispatch(postLimitedGuestSpot(formData));
      if (result.meta.requestStatus === "fulfilled") {
        onSuccess();
        enqueueSnackbar(
          `Plats ${formData.spotNumber} nu tillgänglig vald period`,
          { variant: "success" }
        );
      } else if (result.meta.rejectedWithValue) {
        setError(result.payload);
        enqueueSnackbar("Platsen kunde inte registreras som ledig", {
          variant: "error",
        });
      }
    }
  };

  const handleDelete = async () => {
      // @ts-expect-error
    const result = await dispatch(deleteLimitedGuestSpot(isDeleting));
    if (isFulfilled(result)) {
      onSuccess();
    } else {
      // @ts-expect-error
      setError(result.payload);
    }
  };

  return (
    <div style={{ display: "grid", gap: 8, maxWidth: 400 }}>
      <ConfirmDialog
        title="Bekräfta borttagning?"
        open={isDeleting}
        setOpen={setDeleting}
        onConfirm={() => handleDelete()}
      />
      <DialogWithCloseButton
        open={error}
        onClose={() => setError(null)}
        title="Fel uppstod"
      >
        <DialogContent>
          {/* @ts-expect-error */}
          <p>{error?.message}</p>
        </DialogContent>
      </DialogWithCloseButton>
      {published && data?.id && (
        <Button
          color="inherit"
          endIcon={<Delete />}
          style={{ justifySelf: "end" }}
          onClick={() => setDeleting(data.id)}
        >
          Radera
        </Button>
      )}
      <Autocomplete
        style={{ minWidth: 200 }}
        options={basImport}
        size={disabledSpot ? "small" : "medium"}
        value={spot}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.spot + " " + option.name
        }
        noOptionsText="Inga platser hittades"
        renderInput={(params) => (
          <TextField
            {...params}
            label={
              disabledSpot ? "Plats / Innehavare" : "Sök efter plats/innehavare"
            }
            variant="outlined"
          />
        )}
        onChange={(event, option) => setSpot(option)}
        selectOnFocus={true}
        disabled={disabledSpot}
      />
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(160px, 1fr)",
          gap: 8,
        }}
      >
        <DatePicker
          value={fromDate && parseISO(fromDate)}
          label="Från datum"
          onChange={(date) => {
            if (date && isValid(date)) {
              console.log(date);
              setFromDate(format(date, "yyyy-MM-dd"));
            }
          }}
          format="yyyy-MM-dd"
        />
        <DatePicker
          value={toDate && parseISO(toDate)}
          label="Till datum"
          minDate={fromDate ? parseISO(fromDate) : new Date()}
          onChange={(date) => {
            if (date && isValid(date)) {
              setToDate(format(date, "yyyy-MM-dd"));
            }
          }}
          format="yyyy-MM-dd"
        />
      </div>
      <TextField
        multiline
        type="text"
        variant="outlined"
        label="Anteckning"
        value={note}
        onChange={(e) => setNote(e.target.value)}
      />
      <Button
        disabled={!spot || !fromDate || !toDate}
        variant="contained"
        color="primary"
        onClick={() => publish()}
      >
        {published ? "Spara" : "Registrera"}
      </Button>
    </div>
  );
};

export default LimitedGuestSpotForm;
