import { IUsergroup } from "@hamnvy/shared";
import { Autocomplete, TextField } from "@mui/material";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getUserRoles } from "../../app";

type Props = { value: IUsergroup[]; onChange: (values: IUsergroup[]) => void };

const UsergroupsField = (props: Props) => {
  const dispatch = useAppDispatch();
  const usergroups = useAppSelector<IUsergroup[]>((s) => s.app.roles);

  useEffect(() => {
    if (!usergroups.length) dispatch(getUserRoles());
  }, []);

  return (
    <Autocomplete
      size="small"
      multiple
      fullWidth
      options={usergroups}
      getOptionLabel={(option) => option.name}
      renderInput={(props) => <TextField {...props} label="Användargrupp" />}
      value={props.value}
      onChange={(e, values) => props.onChange(values || [])}
    />
  );
};

export default UsergroupsField;
