import { FilePreview } from "@hamnvy/shared";
import { Close } from "@mui/icons-material";
import { Button, Paper, TextField, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import ImageAndFileUpload from "../../Components/utils/ImageAndFileUpload";
import { getLocalUrlForUploadedFile } from "../../services/utils/files";
import { AssignmentContext } from "./AssignmentProvider";
import GroupedAttachmentThumbnails from "./GroupedAttachmentThumbnails";

interface Props {
  onClose: () => void;
}

const AssignmentCommentForm: FC<Props> = (props) => {
  const { t } = useTranslation();
  const { onClose } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { commentAssignment } = useContext(AssignmentContext);
  const [text, setText] = useState<string>("");
  const [attachments, setAttachments] = useState<FilePreview[]>([]);

  const validForm = Boolean(text);

  const reset = () => {
    setText("");
    setAttachments([]);
  };

  const handleFileDeletion = (fileId: string) => {
    setAttachments(attachments.filter((a) => a.summary.id !== fileId));
  };

  const onUpload = (file: File, upload: FilePreview) => {
    setAttachments(
      attachments.concat({
        ...upload,
        src: getLocalUrlForUploadedFile(file),
      })
    );
  };

  const handleCommit = async () => {
    try {
      if (validForm) {
        await commentAssignment({
          text,
          attachments,
        });
        enqueueSnackbar("Kommentar tillagd", { variant: "success" });
        onClose();
        reset();
      }
    } catch (err) {
      enqueueSnackbar("Kunde inte kommentera", { variant: "error" });
    }
  };

  return (
    <Paper sx={{ display: "flex", flexDirection: "column", gap: 1, p: 2 }}>
      <Box
        sx={{
          display: "flex",
          gap: 1,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">Kommentera</Typography>
        <Box
          sx={{
            display: "flex",
            gap: 0.5,
            alignItems: "center",
            cursor: "pointer",
            color: `${grey[700]} !important`,
          }}
          onClick={onClose}
        >
          <Typography variant="body2">Stäng</Typography>
          <Close />
        </Box>
      </Box>
      <TextField
        multiline
        fullWidth
        placeholder="Skriv här..."
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <GroupedAttachmentThumbnails
        attachments={attachments}
        afterFileDeletion={handleFileDeletion}
        isEditing
      />
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 2,
          alignItems: "center",
          justifyContent: "end",
        }}
      >
        <ImageAndFileUpload
          id="comment"
          type="assignments"
          onUpload={onUpload}
          label={t("attachment.attach", { defaultValue: "Bifoga" })}
        />
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button
            variant="contained"
            disabled={!validForm}
            onClick={handleCommit}
          >
            Publicera
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};
export default AssignmentCommentForm;
