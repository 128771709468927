import { Button, CircularProgress, Container, Typography } from "@mui/material";
import { useStyles } from "../../styles/styles";

export default function LoadingApp() {
  const classes = useStyles();
  return (
    <Container className={classes.center}>
      <div style={{ display: "flex", gap: 8 }}>
        <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
          <CircularProgress size="2rem" />
          <Typography variant="body1">Laddar applikationen... </Typography>
        </div>
        <Button>Avbryt</Button>
      </div>
    </Container>
  );
}
