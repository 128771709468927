import { FlipSignTodo, MinimalMark } from "@hamnvy/shared";
import { LocationOn } from "@mui/icons-material";
import {
  Card,
  CardHeader,
  Checkbox,
  Container,
  DialogContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DialogWithCloseButton } from "../../Components/Dialog";
import config from "../../config";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { api } from "../../services/api";
import { doneFlipSignTodo, getImages, getTodos } from "../app";
import ImageWithMarks from "../assignment/ImageWithMarks";
import Header from "../main/Header";

const WorkerTodo: FC<{}> = (props) => {
  const flipSigns: FlipSignTodo[] =
    useAppSelector((s) => s.app.todos.flipSigns) || [];
  const images = useAppSelector((s) => s.app.images);
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [spotMarks, setSpotMarks] = useState<MinimalMark[]>([]);

  const handleClick = (id: string) => {
    // @ts-expect-error
    dispatch(doneFlipSignTodo(id));
  };

  const notFoundSnack = (): void => {
    enqueueSnackbar(
      t("flipSignTodos.spot_marks_not_found", {
        defaultValue: "Platsen kunde inte hittas på någon hamnvy",
      }),
      { variant: "error" }
    );
  };

  const handleLocationClick = (spot: string): void => {
    api
      .get(config.apiUrl + "/marks?spot=" + spot)
      .then(({ data }) => {
        const marks: MinimalMark[] = data?.marks ?? [];
        if (marks.length === 0) notFoundSnack();
        setSpotMarks(marks);
      })
      .catch(() => {
        notFoundSnack();
      });
  };

  useEffect(() => {
    if (!images.length) dispatch(getImages());
    if (!flipSigns.length) dispatch(getTodos());
  }, []);

  return (
    <>
      <DialogWithCloseButton
        open={spotMarks.length > 0}
        onClose={() => setSpotMarks([])}
        title={"Platsen finns här:"}
      >
        <DialogContent>
          <Box sx={{ display: "grid", gap: 2, overflow: "auto" }}>
            {spotMarks.map((m) => (
              <ImageWithMarks key={m.id} imageId={m.image} marks={[m]} />
            ))}
          </Box>
        </DialogContent>
      </DialogWithCloseButton>

      <Header />
      <Container style={{ padding: 16 }}>
        <Card style={{ maxWidth: 400, userSelect: "none" }}>
          <CardHeader title="Skyltar att vända" />
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{/* check */}</TableCell>
                  <TableCell align="left">Plats</TableCell>
                  <TableCell align="right">Ändra till</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {flipSigns.map(({ id, spot, availableForGuests, doneAt }) => (
                  <TableRow
                    key={"flip-" + id}
                    onClick={() => handleClick(id)}
                    style={{
                      userSelect: "none",
                      cursor: "pointer",
                      backgroundColor: doneAt ? "#DEDEDE" : "unset",
                    }}
                  >
                    <TableCell padding="none">
                      <Tooltip title="Markera avklarad">
                        <div>
                          <Checkbox
                            onChange={(e) => {
                              e.stopPropagation();
                              handleClick(id);
                            }}
                            checked={!!doneAt}
                            // disabled={!!doneAt}
                          />
                        </div>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <div
                        style={{
                          display: "flex",
                          gap: 8,
                          alignItems: "center",
                        }}
                      >
                        <Tooltip title="Visa var">
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleLocationClick(spot);
                            }}
                          >
                            <LocationOn />
                          </IconButton>
                        </Tooltip>
                        <Typography
                          color={doneAt ? "textSecondary" : "textPrimary"}
                        >
                          <b>{spot}</b>
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          gap: 8,
                        }}
                      >
                        <Typography variant="body2" color="textSecondary">
                          {availableForGuests ? "Grön" : "Röd"}
                        </Typography>
                        <div
                          style={{
                            height: 15,
                            width: 20,
                            backgroundColor: availableForGuests
                              ? "#4caf50"
                              : "#c62828",
                            border: "1px solid rgb(224, 224, 224)",
                            borderRadius: 2,
                          }}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
                {flipSigns.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={3}>
                      <Typography>Inga skyltar att vända just nu...</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Container>
    </>
  );
};

export default WorkerTodo;
