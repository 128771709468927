import { persistUserLogout } from "../../services/persist";
import { initialState } from "./appSlice";

const reducers = {
  selectImage: (state, action) => {
    state.image = action.payload.image;
  },
  useLatestBasImport: (state) => {
    state.import = state.imports.pop();
  },
  selectMark: (state, { payload }) => {
    state.selectedMarks.push(payload.id);
    const mark = state.marks.find((x) => x.id === payload.id);
    if (mark?.spot) {
      if (state.selectedSpots.indexOf(mark.spot) === -1)
        state.selectedSpots.push(mark.spot);
      if (state.selectedRows.findIndex((x) => x.spot === mark.spot) === -1) {
        const row = state.import.find((x) => x.spot === mark.spot);
        if (row) state.selectedRows.push(row);
      }
    }
    const imgId = mark.image;
    if (imgId !== state.image.id) {
      const image = state.images.find(x => x.id === imgId);
      if (image) state.image = image;
    }
  },
  selectMarks: (state, { payload }) => {
    payload.ids.forEach((id) =>
      reducers.selectMark(state, { payload: { id } })
    );
  },
  deselectMark: (state, { payload }) => {
    state.selectedMarks.splice(state.selectedMarks.indexOf(payload.id));
    const mark = state.marks.find((x) => x.id === payload.id);
    if (mark.spot) {
      state.selectedSpots = state.selectedSpots.filter((x) => x === mark.spot);
      state.selectedRows = state.selectedRows.filter(
        (x) => x.spot !== mark.spot
      );
    }
  },
  resetMarkSelection: (state) => {
    state.selectedMarks = [];
    state.selectedSpots = [];
    state.selectedRows = [];
  },
  selectAllMarks: (state) => {
    const ms = state.marks.filter((m) => m.image === state.image.id);
    state.selectedMarks = ms.map((x) => x.id);
    state.selectedSpots = [
      ...new Set(ms.filter((x) => x.spot).map((x) => x.spot)),
    ];
    state.selectedRows = state.selectedSpots.map((spot) =>
      state.import.find((x) => x.spot === spot)
    );
  },
  deselectAllMarks: (state) => {
    state.selectedMarks = [];
    state.selectedSpots = [];
    state.selectedRows = [];
  },
  deselectRows: (state, { payload }) => {
    const filteredRows = payload;
    // console.log(filteredRows)
    const marks = state.selectedMarks.map((id) =>
      state.marks.find((x) => x.id === id)
    );

    state.selectedMarks = marks
      .filter(
        (mark) => filteredRows.findIndex((r) => r.spot === mark.spot) === -1
      )
      .map((x) => x.id);
    state.selectedSpots = state.selectedSpots.filter(
      (spot) => filteredRows.findIndex((r) => r.spot === spot) === -1
    );
    state.selectedRows = state.selectedRows.filter(
      (x) => filteredRows.findIndex((r) => r.id === x.id) === -1
    );
  },
  selectRows: (state, { payload }) => {
    const filteredRows = payload;
    const marks = state.selectedMarks.map((id) =>
      state.marks.find((x) => x.id === id)
    );
    const newMarks = filteredRows
      .map((r) => state.marks.find((m) => m.spot === r.spot))
      .filter((x) => x)

    state.selectedMarks = marks
      .filter((m) => filteredRows.findIndex((x) => m.spot === x.spot) === -1)
      .concat(
        newMarks
      )
      .map((m) => m.id);

    if (!newMarks.find(m => m.image === state.image.id)) {
      const imgId = newMarks?.[0]?.image;
      if (imgId) {
        const image = state.images.find(x => x.id === imgId);
        if (image) state.image = image;
      }
    }

    state.selectedSpots = [
      ...new Set(marks.filter((x) => x.spot).map((x) => x.spot)),
    ];
    state.selectedRows = state.selectedRows
      .filter((x) => filteredRows.findIndex((r) => r.id === x.id) === -1)
      .concat(filteredRows);
  },
  addMode: ({ modes }, { payload }) => {
    if (!modes.includes(payload)) modes.push(payload);
  },
  removeMode: (state, { payload }) => {
    state.modes = state.modes.filter((x) => x !== payload);
  },
  toggleMode: ({ modes }, { payload }) => {
    const index = modes.indexOf(payload);
    if (index === -1) modes.push(payload);
    else modes.splice(index);
  },
  toggleRow: (state, { payload }) => {
    const index = state.selectedRows.findIndex((x) => x.id === payload.id);
    // console.log({index})
    if (index === -1) {
      state.selectedRows.push(payload);
      // console.log({payload})
      /** Markera tillhörande marks utan dubbletter */
      const marks = state.marks
        .filter(
          (x) =>
            x.spot === payload.spot &&
            state.selectedMarks.findIndex((mark) => mark.id === x.id) === -1
        )
        .map((x) => x.id);
      // console.log(JSON.stringify(marks, null, 2))
      state.selectedMarks = state.selectedMarks.concat(marks);
      state.selectedSpots = [
        ...new Set(state.selectedSpots.concat(marks.map((x) => x.spot))),
      ];
    } else {
      /** dvs rad redan vald */
      state.selectedRows.splice(index);
      state.selectedSpots = state.selectedSpots.filter(
        (spot) => spot !== payload.spot
      );
      state.selectedMarks = state.selectedMarks.filter(
        (markId) =>
          state.marks.find((x) => x.id === markId)?.spot !== payload.spot
      );
    }
  },
  moveSelectedMarks: (state, { payload }) => {
    state.marks = state.marks.map((mark) => {
      const updated = payload.find((x) => x.id === mark.id);
      if (updated) {
        return { ...mark, x: updated.x, y: updated.y };
      }
      return mark;
    });
  },
  setOriginalsFromMarkSelection: (state) => {
    state.markOriginals = state.selectedMarks.map((id) =>
      state.marks.find((x) => x.id === id)
    );
  },
  beginSelectionRectangle: (state, { payload }) => {
    state.selectRectangle.startX = payload.x;
    state.selectRectangle.startY = payload.y;
  },
  endSelectionRectangle: (state, { payload }) => {
    state.selectRectangle.endX = payload.x;
    state.selectRectangle.endY = payload.y;
    state.selectRectangle.display = true;
    const xMin = Math.min(
      state.selectRectangle.startX,
      state.selectRectangle.endX
    );
    const xMax = Math.max(
      state.selectRectangle.startX,
      state.selectRectangle.endX
    );
    const yMin = Math.min(
      state.selectRectangle.startY,
      state.selectRectangle.endY
    );
    const yMax = Math.max(
      state.selectRectangle.startY,
      state.selectRectangle.endY
    );
    state.selectedMarks = state.marks
      .filter((m) => m.x <= xMax && m.x >= xMin && m.y <= yMax && m.y >= yMin)
      .map((x) => x.id);
  },
  clearSelectionRectangle: (state) => {
    state.selectRectangle = initialState.selectRectangle;
  },
  beginRuler: (state, { payload }) => {
    const ruler = { x0: payload.x, y0: payload.y };
    state.rulers.push(ruler);
  },
  endRuler: (state, { payload }) => {
    const ruler = {
      ...state.rulers[state.rulers.length - 1],
      x1: payload.x,
      y1: payload.y,
    };
    state.rulers[state.rulers.length - 1] = ruler;
  },
  deleteRuler: (state, { payload }) => {
    /** payload = index */
    state.rulers.splice(payload);
  },
  // setMouse: (state, {payload}) => {
  //   state.mouse = payload
  // },
  ruler: (state, { payload }) => {
    if (state.rulers.length > 0) {
      const addData = { x1: payload.x, y1: payload.y };
      state.rulers[0] = { ...state.rulers[0], ...addData };
    } else {
      const ruler = { x0: payload.x, y0: payload.y };
      state.rulers.push(ruler);
    }
  },
  setRulerSensitivity: (state, { payload }) => {
    state.rulerSensitivity = payload;
  },

  // user
  logout: (state) => {
    // todo reset whole state to initial
    state.loggedIn = false;
    state.image = null;
    persistUserLogout();
  },
  forceLogout: (state) => {
    state.loggedIn = false;
    state.image = null;
    persistUserLogout();
  },
  gotAccessFromRefreshToken: (state, { payload }) => {
    const { access, user, images, settings, org } = payload;
    state.access = access;
    state.loggedIn = true;
    state.settings = settings;
    state.images = images;
    state.user = user;
    state.org = org;
  },
  setDetailsColumns: (state, { payload }) => {
    state.detailsColumns = payload;
  },
  clearApiMessage: (state) => {
    state.apiMessage = "";
  },
  selectOrganisation: (state, { payload }) => {
    state.org = payload;
  },
  setBoatDetails: (state, { payload }) => {
    state.boatDetails = payload;
  },
  setOwnerDetails: (state, { payload }) => {
    state.ownerDetails = payload;
  },
  setSpotDetails: (state, { payload }) => {
    state.spotDetails = payload;
  },
  spotToNewMark: (state, { payload }) => {
    state.spotToNewMark = payload;
  },
};

export default reducers;
