import { KeyboardArrowLeft } from "@mui/icons-material";
import { Button, ButtonProps } from "@mui/material";
import { FC } from "react";

interface Props {
  label: string;
}

const BackButton: FC<Props & ButtonProps> = (props) => {
  const { label, sx, ...rest } = props;
  return (
    <Button
      startIcon={<KeyboardArrowLeft />}
      sx={{
        textTransform: "none",
        "& .MuiButton-startIcon": { mr: 0.5 },
        ...sx,
      }}
      {...rest}
    >
      {label}
    </Button>
  );
};

export default BackButton;
