import { useMediaQuery } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router";
import { useLocation, useNavigate } from "react-router";
import App from "../App";
import ActivityPage from "../features/activity/ActivityPage";
import Admin from "../features/admin/Admin";
import { drawerWidth } from "../features/admin/menu/drawer";
import { getOrganisationSettings, postPageVisit } from "../features/app";
import LoadingApp from "../features/app/LoadingApp";
import AssignmentProvider from "../features/assignment/AssignmentProvider";
import FailurePage from "../features/assignment/failure/FailurePage";
import SingleFailure from "../features/assignment/failure/SingleFailure";
import BasDataPage from "../features/bas/BasDataPage";
import CRMRoutes from "../features/crm/CRMRoutes";
import CustomersAdminPage from "../features/crm/CustomersPage";
import ManageSpots from "../features/crm/manageSpots";
import GuestManagerStartPage from "../features/guests/GuestManagerStartPage";
import PaymentCheck from "../features/guests/PaymentCheck";
import SpotsAvailable from "../features/guests/SpotsAvailable";
import WorkerTodo from "../features/guests/WorkerTodo";
import LoginPage from "../features/login/LoginPage";
import MessagesPage from "../features/messages/Messages";
import { User } from "../features/user/User.component";
import UserDetailsPage from "../features/users/UserDetailsPage";
import UsersPage from "../features/users/UsersPage";
import { userHasRight, userIsSuperUser } from "../services/utils/userRights";
import NotFoundPage from "./404.page";
import ConditionalRoute from "./conditional.route";
import ProtectedRoute from "./protected.route";
import Reset from "./Reset";
import UnauthorizedPage from "./unauthorized.page";

export default function AppRoutes() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.app.loggedIn);
  const theme = useTheme();
  const permanentDrawer = useMediaQuery(theme.breakpoints.up("xl"));

  useEffect(() => {
    if (
      !(
        location.pathname.startsWith("/reset") ||
        location.pathname.startsWith("/signup")
      )
    ) {
      if (isLoggedIn) {
        dispatch(getOrganisationSettings());
        const latestPath = localStorage.getItem("last_path");
        if (latestPath) navigate(latestPath);
      } else {
        navigate("/login");
      }
    }
  }, [isLoggedIn]);

  useEffect(() => {
    const path = location.pathname;
    if (!path.includes("/login", "/reset", "/signup")) {
      dispatch(postPageVisit(path));
      localStorage.setItem("last_path", path);
    }
  }, [location.pathname]);

  return (
    <Box
      sx={{
        ...(permanentDrawer && {
          "& > div": { marginLeft: `${drawerWidth}px` },
        }),
      }}
    >
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/overview" element={<App />} />
        <Route path="/users" element={<UsersPage />} />
        <Route path="/users/:id" element={<UserDetailsPage />} />
        <Route path="/home" element={<GuestManagerStartPage />} />
        <Route path="/todo" element={<WorkerTodo />} />
        <Route
          path="/assignments/failure"
          element={
            <AssignmentProvider>
              <FailurePage />
            </AssignmentProvider>
          }
        />
        <Route
          path="/assignments/failure/:id"
          element={
            <AssignmentProvider>
              <SingleFailure />
            </AssignmentProvider>
          }
        />
        <Route path="/payments/check" element={<PaymentCheck />} />
        <Route path="/spots/available" element={<SpotsAvailable />} />
        <Route
          path="/customers"
          element={
            <ProtectedRoute>
              <CustomersAdminPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/crm/*"
          element={
            <ProtectedRoute>
              <CRMRoutes />
            </ProtectedRoute>
          }
        />
        <Route
          path="/spots"
          element={
            <ProtectedRoute>
              <ManageSpots />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user"
          element={
            <ProtectedRoute>
              <User />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/*"
          element={
            <ConditionalRoute condition={() => userIsSuperUser()}>
              <Admin />
            </ConditionalRoute>
          }
        />
        <Route path="/loading" element={LoadingApp} />
        <Route
          path="/bas/data"
          element={
            <ConditionalRoute condition={() => userHasRight("readwrite")}>
              <BasDataPage />
            </ConditionalRoute>
          }
        />
        <Route
          path="/activity"
          element={
            <ConditionalRoute condition={() => userHasRight("readwrite")}>
              <ActivityPage />
            </ConditionalRoute>
          }
        />
        <Route
          path="/notes"
          element={
            <ConditionalRoute condition={() => userHasRight("messages")}>
              <MessagesPage />
            </ConditionalRoute>
          }
        />
        <Route path="/unauthorized" element={<UnauthorizedPage />} />
        <Route path="/logout" element={<UnauthorizedPage />} />
        <Route path="/reset/:id" element={<Reset />} />
        <Route path="/signup/:id" element={<Reset signup />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Box>
  );
}
