import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useState } from "react";
import theme from "../styles/theme";

const style = {
  position: "sticky",
  bottom: 0,
  zIndex: 100,
  marginTop: theme.spacing(2),
  marginBottom: 0,
  background: "#FFFFFF",
};
export default function FloatingFooter({
  initialHeight = 300,
  open,
  setOpen = null,
  children,
}) {
  const [initialPos, setInitialPos] = useState(null);
  const [initialSize, setInitialSize] = useState(null);

  const initial = (e) => {
    let resizable = document.getElementById("Resizable");

    setInitialPos(e.clientY);
    setInitialSize(resizable.offsetHeight);
    // setLastHeight(resizable.offsetHeight);
    // console.log('initialSize: ' + initialSize)
    // console.log(initialPos)
  };

  const resize = (e) => {
    // console.log(e)
    let resizable = document.getElementById("Resizable");
    const diff = initialPos - e.clientY;
    // console.log(diff)
    const newHeight = parseInt(initialSize) + parseInt(diff);
    const newHeightStr = `${newHeight}px`;
    // console.log(newHeight)
    if (Math.abs(resizable.offsetHeight - newHeight) < 50)
      resizable.style.height = newHeightStr;
  };

  return (
    <div style={style}>
      {(open || setOpen) && (
        <div
          style={{
            height: theme.spacing(4),
            background: "#f5f5f5",
            width: "100%",
            margin: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: open ? "row-resize" : "unset",
            borderBottom: "1px solid #CCCCCC",
            borderTop: "1px solid #CCCCCC",
          }}
          draggable={open && true}
          onDragStart={open ? initial : undefined}
          onDrag={open ? resize : undefined}
        >
          {setOpen && (
            <IconButton onClick={() => setOpen(!open)} size="small">
              {open ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
            </IconButton>
          )}
        </div>
      )}
      {open && (
        <div
          id="Resizable"
          style={{ height: initialHeight, maxHeight: "80vh", overflow: "auto" }}
        >
          {children}
        </div>
      )}
    </div>
  );
}
