import { HistoricalNotification } from "@hamnvy/shared/dist/models/customer-client/notification";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

type Props = {
  notif: HistoricalNotification;
};

const CompactNotificationStats = ({ notif }: Props) => {
  return (
    <Table size="small" padding="none">
      <TableHead>
        <TableRow>
          <TableCell>Mottagare</TableCell>
          <TableCell>Sett</TableCell>
          <TableCell>Öppnat</TableCell>
          <TableCell>Läst</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>{notif.users.length}</TableCell>
          <TableCell>{Object.keys(notif.events).length}</TableCell>
          <TableCell>
            {
              Object.values(notif.events).filter((x) => x.expansions.length)
                .length
            }
          </TableCell>
          <TableCell>
            {Object.values(notif.events).filter((x) => x?.dismissed).length}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default CompactNotificationStats;
