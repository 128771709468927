import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrganisations } from "../admin/adminAPI";
import { selectOrganisation } from "../app";

function SelectOrg({
  placeholder = "Välj din organisation",
  value = null,
  onSelect = null,
}) {
  const dispatch = useDispatch();
  const organisation = useSelector((state) => state.app.org);
  const organisations = useSelector((state) => state.admin.organisations);

  useEffect(() => {
    if (!organisations.length) dispatch(getOrganisations());
  }, []);

  return (
    <Autocomplete
      size="small"
      style={{ minWidth: 200 }}
      options={organisations}
      value={value || organisation?.id || null}
      getOptionLabel={(option) => option.orgShortName || ""}
      isOptionEqualToValue={(option, value) =>
        value ? option.id == value : null
      }
      noOptionsText="Inga organisationer kunde hittas"
      renderInput={(params) => (
        <TextField {...params} label={placeholder} variant="outlined" />
      )}
      onChange={(event, org) =>
        onSelect ? onSelect(org) : dispatch(selectOrganisation(org))
      }
      selectOnFocus={true}
    />
  );
}

export default SelectOrg;
