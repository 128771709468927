import { IUser } from "@hamnvy/shared";
import { Table, TableBody, TableContainer, TableRow } from "@mui/material";
import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import TKey from "./TKey";
import TVal from "./TVal";

interface Props {
  user: IUser;
}

const ViewOnlyUserDetails: FC<Props> = (props) => {
  const { user } = props;
  const { t } = useTranslation();
  return (
    <TableContainer>
      <Table>
        <TableBody>
          <TableRow>
            <TKey>
              {t("user.username", "Användarnamn")}
            </TKey>
            <TVal>{user.username}</TVal>
          </TableRow>
          <TableRow>
            <TKey>
              {t("user.name", "Namn")}
            </TKey>
            <TVal>{user?.name ?? "-"}</TVal>
          </TableRow>
          <TableRow>
            <TKey>
              {t("user.firstname", "Förnamn")}
            </TKey>
            <TVal>{user?.firstname ?? "-"}</TVal>
          </TableRow>
          <TableRow>
            <TKey>
              {t("user.lastname", "Efternamn")}
            </TKey>
            <TVal>{user?.lastname ?? "-"}</TVal>
          </TableRow>
          <TableRow>
            <TKey>
              {t("user.email", "Epostadress")}
            </TKey>
            <TVal>{user?.email ?? "-"}</TVal>
          </TableRow>
          <TableRow>
            <TKey>
              {t("user.phone", "Telefon")}
            </TKey>
            <TVal>{user?.phone ?? "-"}</TVal>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ViewOnlyUserDetails;
