import { Container } from "@mui/material";
import { userHasRight } from "../../services/utils/userRights";
import Header from "../main/Header";
import { BasSettings } from "./BasSettings";
import { BasUploads } from "./BasUploads";

export default function BasDataPage() {
  return (
    <>
      <Header />
      <Container style={{ display: "grid", gap: 8, marginTop: 16 }}>
        <BasUploads />
        {userHasRight("advancedFunctions") && <BasSettings />}
      </Container>
    </>
  );
}
