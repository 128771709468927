import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { generateFlipSignTodos, getTodos } from "./features/app";
import Overview from "./features/main/Overview";
import { userHasRight } from "./services/utils/userRights";
import "./styles/App.css";

function App() {
  const dispatch = useDispatch();

  const handleManualFetchTodos = async () => {
    const res = await dispatch(generateFlipSignTodos());
    if (res.meta.requestStatus === "fulfilled") {
      dispatch(getTodos());
    }
  };

  useEffect(() => {
    // dont generate and fetch twice if guestmanager
    if (!userHasRight("guestManager")) handleManualFetchTodos();
  }, []);

  return (
    <>
      <Overview />
    </>
  );
}

export default App;
