import { Typography, TypographyProps } from "@mui/material";

type Props = {
  title: string;
};

const ComponentHeading = (props: Props & TypographyProps) => {
  const { title, sx = {}, ...rest } = props;
  return (
    <Typography variant="h5" {...rest} sx={{ paddingBlock: 1, ...sx }}>
      {title}
    </Typography>
  );
};

export default ComponentHeading;
