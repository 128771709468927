import { Close, Save } from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AddButton,
  CloseButton,
  ConfirmDialog,
  MenuButton,
} from "../../../Components/utils";
import config from "../../../config";
import {
  deleteImage,
  getImages,
  getMarks,
  selectImage,
  updateImage,
} from "../../app";
import { ImageUpload } from "./ImageUpload";
import AuthImage from "../AuthImage";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    gap: theme.spacing(2),
    maxWidth: 150 * 3 + 2 * theme.spacing(2),
  },
  content: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around", // to center
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    maxHeight: 450,
  },
  gridListTile: {
    cursor: "pointer",
    width: 150,
    height: 150,
  },
  image: {
    borderRadius: 8,
  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)",
  },
}));

function ImageGrid({ onClose = null }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.app.user);
  const userOrg = useSelector((state) => state.app.org);
  const image = useSelector((state) => state.app.image);
  const imageFiles = useSelector((state) => state.app.images);
  const images = imageFiles.filter((AuthImage) => userOrg?.id === AuthImage?.org);
  const [isUploading, setUploading] = useState(false);
  const [isRemoving, setRemoveDialog] = useState(false);
  const [isSettingName, openSetName] = useState(false);
  const [newName, setNewName] = useState("");

  useEffect(() => {
    if (!images) fetch();
  }, []);

  const fetch = () => dispatch(getImages());

  const classes = useStyles();

  async function removeImage() {
    dispatch(deleteImage({ id: image.id }));
  }

  const select = (image) => {
    dispatch(selectImage({ image }));
    dispatch(getMarks({ image: image.id }));
  };

  const isSelected = (AuthImage) => AuthImage.filename === image?.filename;

  const RemoveDialog = () =>
    // @ts-ignore
    ConfirmDialog({
      title: `Radera vald hamnvy?`,
      text: `${image?.title || ""}`,
      onConfirm: () => removeImage(),
      open: isRemoving,
      setOpen: setRemoveDialog,
    });

  // @ts-ignore
  const setImageName = () => {
    dispatch(updateImage({ id: image.id, data: { title: newName } }));
    setNewName("");
    openSetName(false);
  };

  const closeNameDialog = () => {
    openSetName(false);
    setNewName("");
  };

  return (
    <>
      <Dialog open={isSettingName} onClose={() => closeNameDialog()}>
        <DialogTitle>Namge aktuell vy</DialogTitle>
        <DialogContent>
          <TextField
            value={newName || image?.title || ""}
            onChange={(e) => setNewName(e.target.value)}
            variant="outlined"
            size="small"
            label="Namn"
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!newName}
            startIcon={<Save />}
            variant="contained"
            color="primary"
            onClick={() => setImageName()}
          >
            Spara
          </Button>
          <Button startIcon={<Close />} onClick={() => closeNameDialog()}>
            Avbryt
          </Button>
        </DialogActions>
      </Dialog>
      <div className={classes.root}>
        <RemoveDialog />
        <Card>
          <CardHeader
            title="Hamnvyer"
            action={
              user?.role?.rights.includes("readwrite") ? (
                <div style={{ display: "flex" }}>
                  {MenuButton({
                    options: [
                      {
                        title: "Radera vy",
                        onSelect: () => setRemoveDialog(true),
                        disabled: !image,
                      },
                      {
                        title: "Gör till huvudvy",
                        onSelect: () =>
                          dispatch(
                            updateImage({
                              id: image.id,
                              data: { master: true },
                            })
                          ),
                        disabled: !image || image?.master,
                      },
                      {
                        title: "Namnge vy",
                        onSelect: () => openSetName(true),
                        disabled: !image,
                      },
                      {
                        title: `${
                          image?.spotLabels ? "Dölj" : "Visa"
                        } platsnummer`,
                        onSelect: () =>
                          dispatch(
                            updateImage({
                              id: image.id,
                              data: { spotLabels: !image?.spotLabels },
                            })
                          ),
                        disabled: !image,
                      },
                      {
                        title: `${
                          image?.paycheck ? "Dölj" : "Visa"
                        } i betalningskontroll`,
                        onSelect: () =>
                          dispatch(
                            updateImage({
                              id: image.id,
                              data: { paycheck: !image?.paycheck },
                            })
                          ),
                        disabled: !image,
                      },
                    ],
                  })}
                  {AddButton(() => setUploading(true))}
                  {/* {RefreshButton(() => fetch())} */}
                  {onClose && CloseButton(() => onClose())}
                </div>
              ) : (
                <div>{onClose && CloseButton(() => onClose())}</div>
              )
            }
            style={{ zIndex: 1000 }}
          />
          <CardContent className={classes.content}>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                gap: 4,
              }}
            >
              {images.map((image) => (
                <Tooltip
                  key={image.filename}
                  title={
                    (image?.title || "") +
                    ": " +
                    image?.marks.length +
                    " platser"
                  }
                >
                  <AuthImage
                    onClick={() => select(image)}
                    className={classes.gridListTile}
                    src={`${config.apiUrl}/images/${image.filename}`}
                    alt=""
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Tooltip>
              ))}
            </div>
            {images.length === 0 && (
              <Typography variant="body1">
                Inga bilder uppladdade, ännu...
              </Typography>
            )}
          </CardContent>
        </Card>
        <ImageUpload open={isUploading} onClose={() => setUploading(false)} />
      </div>
    </>
  );
}
export { ImageGrid };
