import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router";
import Header from "../main/Header";
import NotificationPage from "../notifications/NotificationPage";
import { UpdatePassword } from "../user/UpdatePassword";
import SetBasColumns from "./BasColumns";
import ColorSettings from "./ColorSettings";
import { DataFields } from "./DataFields";
import SetDetailsColumns from "./DetailsColumns";
import { LoginHistory } from "./LoginHistory";
import ManageOrganisations from "./manageOrganisations";
import { AddUser, ListUsers } from "./ManageUsers";
import MarkSettings from "./MarkSettings";
import { PublicEndpoints } from "./PublicEndpoints";
import UserGroups from "./UserGroups";
import UserStats from "./UserStats";
import ViewLogic from "./ViewLogic";

export default function Admin() {
  const users = useSelector((state) => state.app.users);

  return (
    <>
      <Header />
      <Box
        sx={{
          marginTop: 4,
          paddingInline: 2,
          display: "grid",
          gap: 2,
        }}
      >
        <Routes>
          <Route
            path="/"
            element={
              <>
                <UserStats />
                <NotificationPage />
              </>
            }
          />
          <Route
            path="/users"
            element={
              <>
                <ListUsers users={users} adminCol />
                <UserGroups />
                <AddUser />
                <LoginHistory />
                <UpdatePassword />
              </>
            }
          />
          <Route path="/endpoints" element={<PublicEndpoints />} />

          <Route path="/logic" element={<ViewLogic />} />
          <Route path="/organisations/*" element={<ManageOrganisations />} />
          <Route
            path="/data"
            element={
              <>
                <DataFields />
                <SetBasColumns />
                <SetDetailsColumns settingsFor="organisation" />
              </>
            }
          />
          <Route
            path="/style"
            element={
              <>
                <ColorSettings settingsFor="organisation" />
                <MarkSettings />
              </>
            }
          />
        </Routes>
      </Box>
    </>
  );
}
