import { Close } from "@mui/icons-material";
import { Dialog, DialogTitle, IconButton } from "@mui/material";
import { withStyles } from "@mui/styles";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: 4,
    paddingLeft: 24,
    userSelect: "none",
  },
  closeButton: {
    color: theme.palette.grey[500],
  },
});

export const DialogHeader = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle className={classes.root} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large"
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

export function DialogWithCloseButton({
  title,
  children,
  open,
  onClose,
  ...props
}) {
  return (
    <Dialog open={!!open} onClose={() => onClose()} closeAfterTransition={false} {...props}>
      {!!open && (
        <>
          <DialogHeader onClose={() => onClose()}>{title}</DialogHeader>
          {children}
        </>
      )}
    </Dialog>
  );
}
