import { IUser } from "@hamnvy/shared";
import { Button } from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import ActionDialog from "../../../Components/utils/ActionDialog";
import { useAppDispatch } from "../../../hooks";
import { updateUser } from "../../app";

interface Props {
  user: IUser;
}

const DeleteUserButton: FC<Props> = (props) => {
  const { user } = props;
  const dispatch = useAppDispatch();
  const [dialog, setDialog] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleAction = async () => {
    try {
      setError(false);
      // @ts-expect-error
      await dispatch(updateUser({ id: user.id, deleted: new Date() }));
      setDialog(false);
      enqueueSnackbar(
        t("user.deleted_snack", {
          defaultValue: "Användaren borttagen.",
        }),
        { variant: "success" }
      );
      navigate("/users");
    } catch (err) {
      setError(true);
    }
  };

  return (
    <>
      <ActionDialog
        open={dialog}
        onClose={() => setDialog(false)}
        onConfirm={handleAction}
        error={
          error && <>{t("user.update_error", "Kunde inte genomföra ändringen")}.</>
        }
        title={
          <>
            {t("user.delete_title", "Ta bort kontot")}?
          </>
        }
      />
      <Button color="error" variant="outlined" onClick={() => setDialog(true)}>
        {t("user.delete_button", "Radera")}
      </Button>
    </>
  );
};

export default DeleteUserButton;
