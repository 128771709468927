import { ExpandLess } from "@mui/icons-material";
import { IconButton } from "@mui/material";

export default function UpButton(callback, options = []) {
  return (
    <IconButton title="Flytta upp" onClick={() => callback()} {...options} size="large">
      <ExpandLess />
    </IconButton>
  );
}
