import { Comment } from "@mui/icons-material";
import { Divider, Paper, Typography, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Box } from "@mui/system";
import { format } from "date-fns";
import { FC, useContext } from "react";
import { AssignmentContext } from "./AssignmentProvider";
import GroupedAttachmentThumbnails from "./GroupedAttachmentThumbnails";

interface Props {}

const AssignmentComments: FC<Props> = (props) => {
  const { comments } = useContext(AssignmentContext);
  const theme = useTheme();
  return (
    <>
      {comments.map((comment) => (
        <Paper
          key={comment.id}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            p: 2,
            mb: 2,
          }}
        >
          <Box
            sx={{ display: "grid", gap: 1, gridTemplateColumns: "auto 1fr" }}
          >
            <Comment
              color="primary"
              sx={{ fontSize: "33px", color: grey[500] }}
            />
            <Box>
              <Typography
                component="p"
                variant="overline"
                sx={{ textTransform: "none", lineHeight: 1, pb: 0.5 }}
              >
                Kommentar av{" "}
                <b>{comment.createdBy?.name ?? comment.createdBy.username}</b>
              </Typography>
              <Divider />
              <Typography
                component="p"
                variant="overline"
                sx={{ textTransform: "none", lineHeight: 1, pt: 0.5 }}
              >
                {format(new Date(comment.createdAt), "yyyy-MM-dd HH:mm")}
              </Typography>
            </Box>
          </Box>
          <Typography>{comment.text}</Typography>
          <GroupedAttachmentThumbnails
            attachments={comment?.attachments ?? []}
            sx={{ pb: 0, pt: 1 }}
          />
        </Paper>
      ))}
    </>
  );
};
export default AssignmentComments;
