import { Card, List, ListItem, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { orderBy } from "lodash";
import { FC, useContext, useState } from "react";
import { useNavigate } from "react-router";
import theme from "../../../styles/theme";
import { AssignmentContext } from "../AssignmentProvider";
import AssignmentSummary from "../AssignmentSummary";
import { assignmentStatuses, StatusChip } from "../fields/AssignmentStatusChip";

interface Props { }

const ListAssignmentPreview: FC<Props> = (props) => {
  const [statuses, setStatuses] = useState<string[]>(["todo", "in_progress", "waiting"]);
  const { assignments } = useContext(AssignmentContext);
  const navigate = useNavigate();
  const filtered = assignments.filter((x) =>
    statuses.length > 0 ? statuses.includes(x.status.code) : true
  );

  return (
    <Card sx={{ maxWidth: 600, flexGrow: 1 }}>
      <Box sx={{ p: 1 }}>
        <Box>
          <Box
            sx={{
              display: "flex",
              rowGap: 0.5,
              columnGap: 1,
              flexWrap: "wrap",
            }}
          >
            <Box sx={{ display: "flex", gap: 0.5, flexWrap: "wrap" }}>
              {assignmentStatuses.map((s) => (
                <StatusChip
                  key={s.code}
                  status={s}
                  onClick={() =>
                    setStatuses(
                      statuses.includes(s.code)
                        ? statuses.filter((x) => x !== s.code)
                        : statuses.concat(s.code)
                    )
                  }
                  sx={{
                    border: `2px dashed rgba(0,0,0,0)`,
                    ...(statuses.length > 0 &&
                      !statuses.includes(s.code) && {
                      background: "#fff",
                      border: `2px dashed ${s.color}`,
                      color: theme.palette.text.primary,
                      ":hover": { color: "#fff", background: s.color },
                    }),
                  }}
                />
              ))}
            </Box>
          </Box>
          <List>
            {filtered.length > 0 ? (
              orderBy(filtered, "createdAt", "desc").map((x) => (
                <AssignmentSummary
                  key={x.id}
                  assignment={x}
                  onClick={() => navigate(`/assignments/failure/${x.id}`)}
                />
              ))
            ) : (
              <ListItem>Inga felanmälningar att visa.</ListItem>
            )}
          </List>
        </Box>
        <Box>
          <Typography variant="body2">
            {filtered.length} av {assignments.length} visas
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};

export default ListAssignmentPreview;
