import { Assignment } from "@hamnvy/shared";
import { ListItem, ListItemButton, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";
import { Box } from "@mui/system";
import { FC } from "react";
import SlimChip from "../../Components/utils/SlimChip";
import AssignmentCreatedBy from "./AssignmentCreatedBy";
import { assignmentStatuses } from "./fields/AssignmentStatusChip";

interface Props {
  assignment: Assignment;
  onClick?: () => void;
}

const AssignmentSummary: FC<Props> = (props) => {
  const { assignment, onClick } = props;
  const spotNumber = assignment.marks[0]?.spot;
  const color =
    assignmentStatuses.find((x) => x.code === assignment.status.code)?.color ??
    blue[50];

  return (
    <ListItem disablePadding onClick={onClick}>
      <ListItemButton
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: 0.5,
          "& > p": { lineHeight: 1 },
          "& > span": { lineHeight: 1 },
          borderLeft: `4px solid ${color}`,
          py: 0.5,
          my: 1,
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            gap: 1,
            justifyContent: "space-between",
          }}
        >
          <Typography>{assignment.title}</Typography>
          <Box>{spotNumber && <SlimChip label={spotNumber} />}</Box>
        </Box>
        <AssignmentCreatedBy assignment={assignment} />
      </ListItemButton>
    </ListItem>
  );
};

export default AssignmentSummary;
