import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { basColumns } from "../../services/bas";

export const filterMethods = [
  { value: "includes", text: "Innehåller", types: ["string", "text"] },
  { value: "includesNot", text: "Innehåller inte", types: ["string", "text"] },
  {
    value: "noValue",
    text: "Saknar värde",
    types: ["string", "text", "number"],
  },
  {
    value: "exact",
    text: "Exakt lika med",
    types: ["string", "text", "number", "boolean"],
  },
  { value: "less", text: "Mindre än", types: ["number"] },
  { value: "lessOrEqual", text: "Mindre än eller lika med", types: ["number"] },
  { value: "greater", text: "Större än", types: ["number"] },
  {
    value: "greaterOrEqual",
    text: "Större än eller lika med",
    types: ["number"],
  },
];

/** Used if searchQuery, tests all data in row ignoring case */
export function filterRowBySearchQuery(row, searchQuery) {
  if (searchQuery.length > 0) {
    const commaSeparatedRow = basColumns.map((col) => row[col.field]).join(",");
    const tests = searchQuery.split(",").map((searchTerm) => {
      const regTest = new RegExp(searchTerm.trim(), "gi");
      return regTest.test(commaSeparatedRow);
    });
    return tests.every((test) => test);
  } else {
    return true;
  }
}

function compareUsingMatchMethod(target, value, match) {
  // console.log('compares using ', target, value, match)
  switch (match) {
    case "includes":
      return new RegExp(value.trim(), "gi").test(target);
    case "includesNot":
      return !new RegExp(value.trim(), "gi").test(target);
    case "noValue":
      return !target;
    case "exact":
      return target == value;
    case "less":
      return target < value;
    case "lessOrEqual":
      return target <= value;
    case "greater":
      return target > value;
    case "greaterOrEqual":
      return target >= value;
    default:
      break;
  }
  return false;
}

export function filterRowByImportFilters(row, filters) {
  if (filters.length > 0) {
    const result = filters.map((filter, index) => {
      const value = filters[index].value;
      const prop = filter.field.field;
      return compareUsingMatchMethod(row[prop], value, filter.match);
    });
    return result.every((x) => x);
  } else {
    return true;
  }
}

export function filterRowByDataFilters(row, filters, rowsWithFieldsAndData) {
  if (filters.length > 0) {
    const rowWithData = rowsWithFieldsAndData.find(
      (x) => x.row.spot === row.spot
    );
    if (!rowWithData) return false;
    const hasValues = filters.map((filter, index) => {
      const value = filters[index].value || false;
      const target = rowWithData.data[filter.field.field];
      return compareUsingMatchMethod(target, value, filter.match);
    });
    return hasValues.every((x) => x);
  } else {
    return true;
  }
}

/** value === matchtype */
export function SelectFilterMatchMethod({
  value = "",
  type = null,
  handleChange,
  ...rest
}) {
  // console.log({value, type})
  /** relevant to field type */
  const displayOptions = type
    ? filterMethods.filter((x) => x.types.includes(type))
    : filterMethods;
  return (
    <FormControl variant="outlined" size="small">
      {rest?.label && (
        <InputLabel id="match-type-select-label">{rest.label}</InputLabel>
      )}
      <Select
        value={value}
        onChange={handleChange}
        {...{ labelId: rest?.label ? "match-type-select-label" : null }}
      >
        {displayOptions.map(({ value, text }) => (
          <MenuItem key={"option-for-" + value} value={value}>
            {text}
          </MenuItem>
        ))}
        {/* <MenuItem value="between">Mellan</MenuItem> */}
      </Select>
    </FormControl>
  );
}

export function defaultMatchFromType(type) {
  return ["boolean", "number"].includes(type) ? "exact" : "includes";
}
