import { Close } from "@mui/icons-material";
import { Box, Paper, Typography } from "@mui/material";
import { blue, grey } from "@mui/material/colors";
import { FC, ReactNode } from "react";

interface Props {
  heading: string;
  children: ReactNode;
  handleClick: () => void;
}

const PopperContent: FC<Props> = (props) => {
  const { children, heading, handleClick } = props;
  return (
    <Paper
      sx={{
        p: 1,
        background: blue[50],
        display: "flex",
        gap: 1,
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 2,
          justifyContent: "space-between",
          alignItems: "center",
          userSelect: "none",
        }}
      >
        <Typography fontWeight="bold">{heading}</Typography>
        <Box
          sx={{
            display: "flex",
            gap: 0.5,
            alignItems: "center",
            cursor: "pointer",
            color: `${grey[700]} !important`,
          }}
          onClick={handleClick}
        >
          <Typography variant="body2">Stäng</Typography>
          <Close />
        </Box>
      </Box>
      <>
      {children}
      </>
    </Paper>
  );
};
export default PopperContent;
