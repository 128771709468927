import * as React from 'react';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { sv } from "date-fns/locale";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import LoadingApp from "./features/app/LoadingApp";
import "./i18n";
import AppRoutes from "./routes/AppRoutes";
import { httpService } from "./services/api";
import store from "./store";
import "./styles/index.css";
import theme from "./styles/theme";
import { BrowserRouter } from 'react-router';
httpService.setupInterceptor(store);

const container = document.getElementById("root");
const root = createRoot(container);


root.render(<>
  <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={sv}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
            <BrowserRouter>
          <Suspense fallback={<LoadingApp />}>
              <AppRoutes />
          </Suspense>
            </BrowserRouter>
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
    </LocalizationProvider>
  </Provider>
</>
);