import { FilePreview } from "@hamnvy/shared";
import { AttachFile, CameraAlt } from "@mui/icons-material";
import { Box, CircularProgress, SxProps, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { ChangeEvent, FC, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import FileUploadButton from "../../features/assignment/fields/FileUploadButton";
import { getSingleFile, uploadFileToS3 } from "../../services/utils/files";

interface Props {
  id: string;
  type: string;
  onUpload: (localFile: File, upload: FilePreview) => void;
  label?: string;
  sx?: SxProps;
}

const ImageAndFileUpload: FC<Props> = (props) => {
  const { onUpload, type, label, id, sx } = props;
  const [isUploading, setUploading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = getSingleFile(e);
    if (file) {
      try {
        setUploading(true);
        const original = await uploadFileToS3(file, type);
        if (original) {
          setUploading(false);
          onUpload(file, original);
          enqueueSnackbar(
            t("attachment.upload-success", {
              defaultValue: "Bilaga uppladdad",
            }),
            { variant: "success" }
          );
        }
      } catch (err) {
        setUploading(false);
        enqueueSnackbar(
          t("attachment.upload-success", {
            defaultValue: "Något gick fel, bilagan kunde inte laddas upp.",
          }),
          {
            variant: "error",
          }
        );
      }
    }
  };

  return (
    <Box>
      {isUploading && (
        <Box sx={{ display: "flex", alignItems: "center", py: 1, gap: 1 }}>
          <Typography>
            {t("attachment.uploading", "Laddar upp")}...
          </Typography>
          <CircularProgress color="primary" size="1rem" />
        </Box>
      )}
      <Box
        sx={{
          py: 1,
          display: "flex",
          gap: 1,
          alignItems: "center",
          ...sx,
        }}
      >
        <Typography sx={{ pr: 1 }}>
          {label ?? t("attachment.add", "Lägg till")}:
        </Typography>
        <FileUploadButton
          id={`${id}-attachment-image`}
          accept="image/*"
          onChange={handleFileChange}
          variant="outlined"
          startIcon={<CameraAlt />}
        >
          {t("attachment.image", "Bild")}
        </FileUploadButton>
        <FileUploadButton
          id={`${id}-attachment-general`}
          accept="*"
          onChange={handleFileChange}
          startIcon={<AttachFile />}
          sx={{ "& > .MuiButton-startIcon": { mr: 0.5 } }}
        >
          {t("attachment.file", "Fil")}
        </FileUploadButton>
      </Box>
    </Box>
  );
};

export default ImageAndFileUpload;
