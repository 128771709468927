const persistUserLogin = (remember) => {
  localStorage.setItem("logged_in", true);
  localStorage.setItem("remember_user", remember);
};

const persistUserLogout = () => {
  localStorage.removeItem("logged_in");
  localStorage.removeItem("remember_user");
  localStorage.removeItem("last_path");
};

export { persistUserLogin, persistUserLogout };
