import { MinimalMark } from "@hamnvy/shared";
import { CalendarMonth, Construction, PlaylistAdd } from "@mui/icons-material";
import {
  Autocomplete,
  DialogContent,
  Icon,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { ReactNode, useState } from "react";
import { DialogWithCloseButton } from "../../../Components/Dialog";
import WorkAssignmentForm from "../../assignment/WorkAssignmentForm";
import LimitedGuestSpotForm from "../../guests/forms/LimitedGuestSpotForm";

interface ActionComponentProps {
  onClose: () => void;
  mark: MinimalMark;
}

interface Action {
  id: string;
  label: string;
  dialogTitle?: string; // label used if not existing
  requirePermission?: string[];
  component?: (props: ActionComponentProps) => ReactNode;
  icon?: ReactNode;
  disabledReason?: string;
}

const spotActions: Action[] = [
  {
    id: "availability",
    label: "Registrera som ledig", // "spotActions.registerAvailable"
    dialogTitle: "Markera tillfälligt ledig",
    component: ({ onClose, mark }) => (
      <LimitedGuestSpotForm
        onSuccess={() => onClose()}
        // @ts-ignore
        data={{ spotNumber: mark.spot }}
        disabledSpot
      />
    ),
    icon: <CalendarMonth />,
  },
  {
    id: "error_report",
    label: "Felanmäl", // i18n.t("spotActions.errorReport", "Felanmäl"),
    icon: <Construction />,
    // disabledReason: "Under utveckling",
    component: ({ onClose, mark }) => (
      <WorkAssignmentForm mark={mark} onCancel={onClose} onSuccess={onClose} />
    ),
  },
  {
    id: "work_to_do",
    label: "Skapa arbetsuppgift", // i18n.t("spotActions.workTodo", "Skapa arbetsuppgift"),
    icon: <PlaylistAdd />,
    disabledReason: "Ännu inte implementerad",
  },
];
type Props = {
  mark: MinimalMark;
};

const SpotActionsField = (props: Props) => {
  const { mark } = props;
  const [title, setTitle] = useState<string>("");
  const [actionComponent, setActionComponent] = useState<ReactNode>(null);

  const onClose = (): void => setActionComponent(null);

  return (
    <>
      <DialogWithCloseButton
        title={title}
        open={!!actionComponent}
        onClose={onClose}
      >
        <DialogContent>
          <Box paddingTop={1}><>{actionComponent}</></Box>
        </DialogContent>
      </DialogWithCloseButton>
      <Autocomplete
        value={null}
        options={spotActions}
        getOptionLabel={(option) => option.label}
        renderOption={(params, option) => (
          <MenuItem {...params} key={option.id}>
            <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
              <Icon
                color="primary"
                sx={{ display: "flex", alignItems: "center" }}
              >
                {!!option?.icon && <>{option?.icon}</>}
              </Icon>
              <Typography>{option.label}</Typography>
            </Box>
          </MenuItem>
        )}
        noOptionsText="Inga åtgärder hittades."
        size="small"
        onChange={(e, option) => {
          setActionComponent(
            option?.component ? option.component({ onClose, mark }) : null
          );
          setTitle(option?.dialogTitle ?? option?.label ?? "");
        }}
        renderInput={(params) => <TextField {...params} label="Åtgärder" />}
        getOptionDisabled={(option) => !!option?.disabledReason}
      />
    </>
  );
};

export default SpotActionsField;
